import { isNullOrUndefinedOrEmpty } from "./filter-value";
import { isValidSSN } from "./validation-helpers";

export const requiredFields = (
	loanApplicationData,
	propertyInfo,
	grossRent,
	homeownersInsurance,
	propertyTaxes,
	loanOriginatorEmail,
	loanProcessorEmail,
	setErrorText
) => {
	console.log("10 loan applicationData:", loanApplicationData);

	for (let index = 0; index < loanApplicationData.length; index++) {
		const borrowerData = loanApplicationData[index];

		console.log("15 index:", index);
		console.log("15 borrower data:", borrowerData);

		// number % 2 !== 0 odd
		// number % 2 === 0 even

		if (index % 2 === 0) {
			// index is odd - borrower
			if (
				borrowerData?.firstName === undefined ||
				borrowerData?.firstName === "" ||
				borrowerData?.lastName === undefined ||
				borrowerData?.lastName === "" ||
				borrowerData?.birthDate === null ||
				borrowerData?.birthDate === undefined ||
				borrowerData?.birthDate === "" ||
				borrowerData?.taxIdentificationIdentifier === null ||
				borrowerData?.taxIdentificationIdentifier === undefined ||
				borrowerData?.taxIdentificationIdentifier === "" ||
				// !isValidSSN(borrowerData?.taxIdentificationIdentifier) ||
				borrowerData?.maritalStatusType === null ||
				borrowerData?.maritalStatusType === undefined ||
				borrowerData?.maritalStatusType === "" ||
				borrowerData?.urla2020CitizenshipResidencyType === undefined ||
				borrowerData?.urla2020CitizenshipResidencyType === "" ||
				((borrowerData?.urla2020CitizenshipResidencyType ===
					"NonPermanentResidentAlien" ||
					borrowerData?.urla2020CitizenshipResidencyType ===
						"Foreign National") &&
					(borrowerData?.urla2020CountryOfCitizenship === undefined ||
						borrowerData?.urla2020CountryOfCitizenship === "")) ||
				// either one of these has value
				((borrowerData?.homePhoneNumber === undefined ||
					borrowerData?.homePhoneNumber === "") &&
					(borrowerData?.workPhoneNumber === undefined ||
						borrowerData?.workPhoneNumber === "") &&
					(borrowerData?.mobilePhone === undefined ||
						borrowerData?.mobilePhone === "")) ||
				borrowerData?.emailAddressText === undefined ||
				borrowerData?.emailAddressText === "" ||
				propertyInfo?.addressLineText === undefined ||
				propertyInfo?.addressLineText === "" ||
				propertyInfo?.city === undefined ||
				propertyInfo?.city === "" ||
				propertyInfo?.state === undefined ||
				propertyInfo?.state === "" ||
				propertyInfo?.postalCode === undefined ||
				propertyInfo?.postalCode === "" ||
				propertyInfo?.financedNumberOfUnits === undefined ||
				propertyInfo?.financedNumberOfUnits === "" ||
				propertyInfo?.financedNumberOfUnits === "0" ||
				propertyInfo?.financedNumberOfUnits === 0 ||
				isNullOrUndefinedOrEmpty(grossRent) ||
				isNullOrUndefinedOrEmpty(homeownersInsurance) ||
				Number(homeownersInsurance) === 0 ||
				isNullOrUndefinedOrEmpty(propertyTaxes) ||
				Number(propertyTaxes) === 0 ||
				loanOriginatorEmail === undefined ||
				loanOriginatorEmail === "" ||
				loanProcessorEmail === undefined ||
				loanProcessorEmail === ""
			) {
				console.log("75 required fields missing error at index:", index);
				setErrorText(
					"Please provide a value for all fields highlighted above."
				);
				return false;
			}
		} else {
			// index is even - co-borrower
			console.log("75 borrower data:", index, borrowerData);

			if (
				borrowerData?.firstName !== null &&
				borrowerData?.firstName !== undefined &&
				borrowerData?.firstName !== ""
			) {
				if (
					borrowerData?.firstName === undefined ||
					borrowerData?.firstName === "" ||
					borrowerData?.lastName === undefined ||
					borrowerData?.lastName === "" ||
					borrowerData?.birthDate === null ||
					borrowerData?.birthDate === undefined ||
					borrowerData?.birthDate === "" ||
					borrowerData?.taxIdentificationIdentifier === null ||
					borrowerData?.taxIdentificationIdentifier === undefined ||
					borrowerData?.taxIdentificationIdentifier === "" ||
					// !isValidSSN(borrowerData?.taxIdentificationIdentifier) ||
					borrowerData?.maritalStatusType === null ||
					borrowerData?.maritalStatusType === undefined ||
					borrowerData?.maritalStatusType === "" ||
					borrowerData?.urla2020CitizenshipResidencyType === undefined ||
					borrowerData?.urla2020CitizenshipResidencyType === "" ||
					((borrowerData?.urla2020CitizenshipResidencyType ===
						"NonPermanentResidentAlien" ||
						borrowerData?.urla2020CitizenshipResidencyType ===
							"Foreign National") &&
						(borrowerData?.urla2020CountryOfCitizenship === undefined ||
							borrowerData?.urla2020CountryOfCitizenship === "")) ||
					// either one of these has value
					((borrowerData?.homePhoneNumber === undefined ||
						borrowerData?.homePhoneNumber === "") &&
						(borrowerData?.workPhoneNumber === undefined ||
							borrowerData?.workPhoneNumber === "") &&
						(borrowerData?.mobilePhone === undefined ||
							borrowerData?.mobilePhone === "")) ||
					borrowerData?.emailAddressText === undefined ||
					borrowerData?.emailAddressText === "" ||
					propertyInfo?.addressLineText === undefined ||
					propertyInfo?.addressLineText === "" ||
					propertyInfo?.city === undefined ||
					propertyInfo?.city === "" ||
					propertyInfo?.state === undefined ||
					propertyInfo?.state === "" ||
					propertyInfo?.postalCode === undefined ||
					propertyInfo?.postalCode === "" ||
					propertyInfo?.financedNumberOfUnits === undefined ||
					propertyInfo?.financedNumberOfUnits === "" ||
					propertyInfo?.financedNumberOfUnits === "0" ||
					propertyInfo?.financedNumberOfUnits === 0 ||
					isNullOrUndefinedOrEmpty(grossRent) ||
					isNullOrUndefinedOrEmpty(homeownersInsurance) ||
					Number(homeownersInsurance) === 0 ||
					isNullOrUndefinedOrEmpty(propertyTaxes) ||
					Number(propertyTaxes) === 0 ||
					loanOriginatorEmail === undefined ||
					loanOriginatorEmail === "" ||
					loanProcessorEmail === undefined ||
					loanProcessorEmail === ""
				) {
					console.log("140 required fields missing error at index:", index);
					setErrorText(
						"Please provide a value for all fields highlighted above."
					);
					return false;
				}
			}
		}
	}
	return true;
};

export const requiredFieldsForCreditReissue = (
	loanApplicationData,
	setErrorText
) => {
	console.log("10 loan applicationData:", loanApplicationData);

	for (let index = 0; index < loanApplicationData.length; index++) {
		const borrowerData = loanApplicationData[index];

		console.log("15 index:", index);
		console.log("15 borrower data:", borrowerData);

		// number % 2 !== 0 odd
		// number % 2 === 0 even

		if (index % 2 === 0) {
			// index is odd - borrower
			if (
				borrowerData?.firstName === undefined ||
				borrowerData?.firstName === "" ||
				borrowerData?.lastName === undefined ||
				borrowerData?.lastName === "" ||
				borrowerData?.birthDate === null ||
				borrowerData?.birthDate === undefined ||
				borrowerData?.birthDate === "" ||
				borrowerData?.taxIdentificationIdentifier === null ||
				borrowerData?.taxIdentificationIdentifier === undefined ||
				borrowerData?.taxIdentificationIdentifier === "" ||
				borrowerData?.maritalStatusType === null ||
				borrowerData?.maritalStatusType === undefined ||
				borrowerData?.maritalStatusType === "" ||
				borrowerData?.urla2020CitizenshipResidencyType === undefined ||
				borrowerData?.urla2020CitizenshipResidencyType === "" ||
				((borrowerData?.urla2020CitizenshipResidencyType ===
					"NonPermanentResidentAlien" ||
					borrowerData?.urla2020CitizenshipResidencyType ===
						"Foreign National") &&
					(borrowerData?.urla2020CountryOfCitizenship === undefined ||
						borrowerData?.urla2020CountryOfCitizenship === "")) ||
				// either one of these has value
				((borrowerData?.homePhoneNumber === undefined ||
					borrowerData?.homePhoneNumber === "") &&
					(borrowerData?.workPhoneNumber === undefined ||
						borrowerData?.workPhoneNumber === "") &&
					(borrowerData?.mobilePhone === undefined ||
						borrowerData?.mobilePhone === "")) ||
				borrowerData?.emailAddressText === undefined ||
				borrowerData?.emailAddressText === ""
			) {
				console.log("75 required fields missing error at index:", index);
				setErrorText(
					"Please provide a value for all fields highlighted above."
				);
				return false;
			}
		} else {
			// index is even - co-borrower
			console.log("75 borrower data:", index, borrowerData);

			if (
				borrowerData?.firstName !== null &&
				borrowerData?.firstName !== undefined &&
				borrowerData?.firstName !== ""
			) {
				if (
					borrowerData?.firstName === undefined ||
					borrowerData?.firstName === "" ||
					borrowerData?.lastName === undefined ||
					borrowerData?.lastName === "" ||
					borrowerData?.birthDate === null ||
					borrowerData?.birthDate === undefined ||
					borrowerData?.birthDate === "" ||
					borrowerData?.taxIdentificationIdentifier === null ||
					borrowerData?.taxIdentificationIdentifier === undefined ||
					borrowerData?.taxIdentificationIdentifier === "" ||
					borrowerData?.maritalStatusType === null ||
					borrowerData?.maritalStatusType === undefined ||
					borrowerData?.maritalStatusType === "" ||
					borrowerData?.urla2020CitizenshipResidencyType === undefined ||
					borrowerData?.urla2020CitizenshipResidencyType === "" ||
					((borrowerData?.urla2020CitizenshipResidencyType ===
						"NonPermanentResidentAlien" ||
						borrowerData?.urla2020CitizenshipResidencyType ===
							"Foreign National") &&
						(borrowerData?.urla2020CountryOfCitizenship === undefined ||
							borrowerData?.urla2020CountryOfCitizenship === "")) ||
					// either one of these has value
					((borrowerData?.homePhoneNumber === undefined ||
						borrowerData?.homePhoneNumber === "") &&
						(borrowerData?.workPhoneNumber === undefined ||
							borrowerData?.workPhoneNumber === "") &&
						(borrowerData?.mobilePhone === undefined ||
							borrowerData?.mobilePhone === "")) ||
					borrowerData?.emailAddressText === undefined ||
					borrowerData?.emailAddressText === "" 
				) {
					console.log("140 required fields missing error at index:", index);
					setErrorText(
						"Please provide a value for all fields highlighted above."
					);
					return false;
				}
			}
		}
	}
	return true;
};
