import { apiSlice } from "../../../app/api/apiSlice";

export const usersApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getUnfilteredMortgageCount: builder.mutation({
			query: (credentials) => ({
				url: "/api/v1/admin/loan/pipeline_modified/milestone/",
				method: "GET",
			}),
		}),
	}),
});

export const { useGetUnfilteredMortgageCountMutation } = usersApiSlice;
