import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// mui components
import { Box, Stack } from "@mui/material";

// mui icons
import CloseIcon from "@mui/icons-material/Close";

// custom components
import TertiaryButton from "../../buttons/tertiary-button";
import CustomDivider from "../../custom-divider";
import Heading3 from "../../typography/heading-03";
import Heading4 from "../../typography/heading-04";
import Body1 from "../../typography/body-01";

// helper functions
import { filterValue } from "../../../utils/filter-value";

// reducer slice
import { selectLockRequestLog } from "../../../features/lock-request-log/lockRequestLogSlice";

// api slices
import { useFieldMutation } from "../../../features/field-values/fieldValueApiSlice";

// custom styles
import styles from "./index.module.scss";

const FileContactCard = ({
	handleTabChange,
	handleOpenLoadingDataModal,
	handleCloseLoadingDataModal,
	setLoadingDataText,
	handleOpenErrorModal,
	setErrorModalText,
}) => {
	const params = useParams();

	const [field] = useFieldMutation();

	const lockRequestLogData = useSelector(selectLockRequestLog);
	console.log("50 lockRequestLogData:", lockRequestLogData);

	const [fieldData, setFieldData] = useState([]);

	const handleErrorModal = (data) => {
		console.log("60 data:", data);

		handleCloseLoadingDataModal();
		handleOpenErrorModal();
		setErrorModalText("There was an error getting important dates");
	};

	const getFieldValues = async () => {
		console.log("75 loan id:", params?.id);
		handleOpenLoadingDataModal();

		try {
			const getValues = await field({
				loan_guid: params?.id,
				fields:
					"LoanTeamMember.Email.Setup,LoanTeamMember.Fax.Setup,LoanTeamMember.Name.Setup,LoanTeamMember.Phone.Setup,LoanTeamMember.Title.Setup,LoanTeamMember.UserID.Setup,LoanTeamMember.Email.Underwriter,LoanTeamMember.Fax.Underwriter,LoanTeamMember.Name.Underwriter,LoanTeamMember.Phone.Underwriter,LoanTeamMember.Title.Underwriter,LoanTeamMember.UserID.Underwriter,LoanTeamMember.Email.Closer,LoanTeamMember.Fax.Closer,LoanTeamMember.Name.Closer,LoanTeamMember.Phone.Closer,LoanTeamMember.Title.Closer,LoanTeamMember.UserID.Closer,LoanTeamMember.Email.Funder,LoanTeamMember.Fax.Funder,LoanTeamMember.Name.Funder,LoanTeamMember.Phone.Funder,LoanTeamMember.Title.Funder,LoanTeamMember.UserID.Funder,LoanTeamMember.Email.Quality Control,LoanTeamMember.Fax.Quality Control,LoanTeamMember.Name.Quality Control,LoanTeamMember.Phone.Quality Control,LoanTeamMember.Title.Quality Control,LoanTeamMember.UserID.Quality Control,LoanTeamMember.Email.TPO Account Exec,LoanTeamMember.Fax.TPO Account Exec,LoanTeamMember.Name.TPO Account Exec,LoanTeamMember.Phone.TPO Account Exec,LoanTeamMember.Title.TPO Account Exec,LoanTeamMember.UserID.TPO Account Exec,LoanTeamMember.Email.TPO Alternate AE,LoanTeamMember.Fax.TPO Alternate AE,LoanTeamMember.Name.TPO Alternate AE,LoanTeamMember.Phone.TPO Alternate AE,LoanTeamMember.Title.TPO Alternate AE,LoanTeamMember.UserID.TPO Alternate AE,LoanTeamMember.Email.Disclosure Tech,LoanTeamMember.Fax.Disclosure Tech,LoanTeamMember.Name.Disclosure Tech,LoanTeamMember.Phone.Disclosure Tech,LoanTeamMember.Title.Disclosure Tech,LoanTeamMember.UserID.Disclosure Tech,LoanTeamMember.Email.TPO Account Manager,LoanTeamMember.Fax.TPO Account Manager,LoanTeamMember.Name.TPO Account Manager,LoanTeamMember.Phone.TPO Account Manager,LoanTeamMember.Title.TPO Account Manager,LoanTeamMember.UserID.TPO Account Manager",
			}).unwrap();

			console.log("75 all fields:", getValues?.response);

			if (getValues?.response?.length > 0) {
				setFieldData(getValues?.response);
				handleCloseLoadingDataModal();
				setLoadingDataText("Setting up the lock info");
			}
		} catch (err) {
			console.log("270 error:", err);
			handleErrorModal(err);
		}
	};

	useEffect(() => {
		getFieldValues();
	}, []);

	return (
		<Stack direction="column" className={styles.stackContainer}>
			<Stack
				direction="row"
				alignItems="center"
				justifyContent="space-between"
				className={styles.topContainer}
			>
				<Heading3
					text="File Contact"
					extraClass={styles.tabTitle}
					fontType="semibold"
				/>

				<Stack
					direction="row"
					alignItems="center"
					justifyContent="flex-end"
					className={styles.topBtnContainer}
				>
					<TertiaryButton
						text="Close this view"
						startIcon={<CloseIcon />}
						onClick={(e) => handleTabChange(e, false)}
					/>
				</Stack>
			</Stack>

			<Stack direction="row" className={styles.bottomContainer}>
				<Stack direction="column" className={styles.infoContainer}>
					<Heading4 text="Setup" fontType="semibold" />

					{/* email */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="Email" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={
									filterValue(fieldData, "LoanTeamMember.Email.Setup") || "//"
								}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					{/* fax */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="Fax" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={
									filterValue(fieldData, "LoanTeamMember.Fax.Setup") || "//"
								}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					{/* name */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="Name" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={
									filterValue(fieldData, "LoanTeamMember.Name.Setup") || "//"
								}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					{/* phone */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="Phone" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={
									filterValue(fieldData, "LoanTeamMember.Phone.Setup") || "//"
								}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					{/* title */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="Title" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={
									filterValue(fieldData, "LoanTeamMember.Title.Setup") || "//"
								}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					{/* userId */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="UserID" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={
									filterValue(fieldData, "LoanTeamMember.UserID.Setup") || "//"
								}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					<CustomDivider extraClass={styles.sectionHeaderLine} />
					<Heading4 text="Underwriter" fontType="semibold" />

					{/* email */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="Email" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={
									filterValue(fieldData, "LoanTeamMember.Email.Underwriter") ||
									"//"
								}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					{/* fax */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="Fax" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={
									filterValue(fieldData, "LoanTeamMember.Fax.Underwriter") ||
									"//"
								}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					{/* name */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="Name" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={
									filterValue(fieldData, "LoanTeamMember.Name.Underwriter") ||
									"//"
								}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					{/* phone */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="Phone" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={
									filterValue(fieldData, "LoanTeamMember.Phone.Underwriter") ||
									"//"
								}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					{/* title */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="Title" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={
									filterValue(fieldData, "LoanTeamMember.Title.Underwriter") ||
									"//"
								}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					{/* userId */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="UserID" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={
									filterValue(fieldData, "LoanTeamMember.UserID.Underwriter") ||
									"//"
								}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					<CustomDivider extraClass={styles.sectionHeaderLine} />
					<Heading4 text="Closer" fontType="semibold" />

					{/* email */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="Email" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={
									filterValue(fieldData, "LoanTeamMember.Email.Closer") || "//"
								}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					{/* fax */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="Fax" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={
									filterValue(fieldData, "LoanTeamMember.Fax.Closer") || "//"
								}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					{/* name */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="Name" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={
									filterValue(fieldData, "LoanTeamMember.Name.Closer") || "//"
								}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					{/* phone */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="Phone" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={
									filterValue(fieldData, "LoanTeamMember.Phone.Closer") || "//"
								}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					{/* title */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="Title" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={
									filterValue(fieldData, "LoanTeamMember.Title.Closer") || "//"
								}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					{/* userId */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="UserID" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={
									filterValue(fieldData, "LoanTeamMember.UserID.Closer") || "//"
								}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					<CustomDivider extraClass={styles.sectionHeaderLine} />
					<Heading4 text="Funder" fontType="semibold" />

					{/* email */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="Email" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={
									filterValue(fieldData, "LoanTeamMember.Email.Funder") || "//"
								}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					{/* fax */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="Fax" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={
									filterValue(fieldData, "LoanTeamMember.Fax.Funder") || "//"
								}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					{/* name */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="Name" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={
									filterValue(fieldData, "LoanTeamMember.Name.Funder") || "//"
								}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					{/* phone */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="Phone" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={
									filterValue(fieldData, "LoanTeamMember.Phone.Funder") || "//"
								}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					{/* title */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="Title" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={
									filterValue(fieldData, "LoanTeamMember.Title.Funder") || "//"
								}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					{/* userId */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="UserID" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={
									filterValue(fieldData, "LoanTeamMember.UserID.Funder") || "//"
								}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					<CustomDivider extraClass={styles.sectionHeaderLine} />
					<Heading4 text="Quality Control" fontType="semibold" />

					{/* email */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="Email" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={
									filterValue(
										fieldData,
										"LoanTeamMember.Email.Quality Control"
									) || "//"
								}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					{/* fax */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="Fax" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={
									filterValue(
										fieldData,
										"LoanTeamMember.Fax.Quality Control"
									) || "//"
								}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					{/* name */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="Name" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={
									filterValue(
										fieldData,
										"LoanTeamMember.Name.Quality Control"
									) || "//"
								}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					{/* phone */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="Phone" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={
									filterValue(
										fieldData,
										"LoanTeamMember.Phone.Quality Control"
									) || "//"
								}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					{/* title */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="Title" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={
									filterValue(
										fieldData,
										"LoanTeamMember.Title.Quality Control"
									) || "//"
								}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					{/* userId */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="UserID" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={
									filterValue(
										fieldData,
										"LoanTeamMember.UserID.Quality Control"
									) || "//"
								}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>
				</Stack>

				<Stack direction="column" className={styles.infoContainer}>
					<Heading4 text="TPO Account Exec" fontType="semibold" />

					{/* email */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="Email" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={
									filterValue(
										fieldData,
										"LoanTeamMember.Email.TPO Account Exec"
									) || "//"
								}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					{/* fax */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="Fax" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={
									filterValue(
										fieldData,
										"LoanTeamMember.Fax.TPO Account Exec"
									) || "//"
								}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					{/* name */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="Name" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={
									filterValue(
										fieldData,
										"LoanTeamMember.Name.TPO Account Exec"
									) || "//"
								}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					{/* phone */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="Phone" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={
									filterValue(
										fieldData,
										"LoanTeamMember.Phone.TPO Account Exec"
									) || "//"
								}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					{/* title */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="Title" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={
									filterValue(
										fieldData,
										"LoanTeamMember.Title.TPO Account Exec"
									) || "//"
								}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					{/* userId */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="UserID" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={
									filterValue(
										fieldData,
										"LoanTeamMember.UserID.TPO Account Exec"
									) || "//"
								}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					<CustomDivider extraClass={styles.sectionHeaderLine} />
					<Heading4 text="TPO Alternate AE" fontType="semibold" />

					{/* email */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="Email" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={
									filterValue(
										fieldData,
										"LoanTeamMember.Email.TPO Alternate AE"
									) || "//"
								}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					{/* fax */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="Fax" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={
									filterValue(
										fieldData,
										"LoanTeamMember.Fax.TPO Alternate AE"
									) || "//"
								}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					{/* name */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="Name" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={
									filterValue(
										fieldData,
										"LoanTeamMember.Name.TPO Alternate AE"
									) || "//"
								}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					{/* phone */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="Phone" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={
									filterValue(
										fieldData,
										"LoanTeamMember.Phone.TPO Alternate AE"
									) || "//"
								}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					{/* title */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="Title" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={
									filterValue(
										fieldData,
										"LoanTeamMember.Title.TPO Alternate AE"
									) || "//"
								}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					{/* userId */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="UserID" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={
									filterValue(
										fieldData,
										"LoanTeamMember.UserID.TPO Alternate AE"
									) || "//"
								}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					<CustomDivider extraClass={styles.sectionHeaderLine} />
					<Heading4 text="Disclosure Tech" fontType="semibold" />

					{/* email */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="Email" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={
									filterValue(
										fieldData,
										"LoanTeamMember.Email.Disclosure Tech"
									) || "//"
								}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					{/* fax */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="Fax" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={
									filterValue(
										fieldData,
										"LoanTeamMember.Fax.Disclosure Tech"
									) || "//"
								}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					{/* name */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="Name" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={
									filterValue(
										fieldData,
										"LoanTeamMember.Name.Disclosure Tech"
									) || "//"
								}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					{/* phone */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="Phone" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={
									filterValue(
										fieldData,
										"LoanTeamMember.Phone.Disclosure Tech"
									) || "//"
								}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					{/* title */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="Title" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={
									filterValue(
										fieldData,
										"LoanTeamMember.Title.Disclosure Tech"
									) || "//"
								}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					{/* userId */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="UserID" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={
									filterValue(
										fieldData,
										"LoanTeamMember.UserID.Disclosure Tech"
									) || "//"
								}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					<CustomDivider extraClass={styles.sectionHeaderLine} />
					<Heading4 text="TPO Account Manager" fontType="semibold" />

					{/* email */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="Email" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={
									filterValue(
										fieldData,
										"LoanTeamMember.Email.TPO Account Manager"
									) || "//"
								}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					{/* fax */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="Fax" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={
									filterValue(
										fieldData,
										"LoanTeamMember.Fax.TPO Account Manager"
									) || "//"
								}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					{/* name */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="Name" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={
									filterValue(
										fieldData,
										"LoanTeamMember.Name.TPO Account Manager"
									) || "//"
								}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					{/* phone */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="Phone" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={
									filterValue(
										fieldData,
										"LoanTeamMember.Phone.TPO Account Manager"
									) || "//"
								}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					{/* title */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="Title" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={
									filterValue(
										fieldData,
										"LoanTeamMember.Title.TPO Account Manager"
									) || "//"
								}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					{/* userId */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="UserID" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={
									filterValue(
										fieldData,
										"LoanTeamMember.UserID.TPO Account Manager"
									) || "//"
								}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>
				</Stack>
			</Stack>
		</Stack>
	);
};

export default FileContactCard;
