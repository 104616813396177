import {
	Box,
	TableCell,
	TableHead,
	TableRow,
	TableSortLabel,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";

import { sortAmount, sortDate } from "../../../utils/sort-methods";

import Heading5 from "../../typography/heading-05";

import styles from "./index.module.scss";

const TableHeadIneligibleProduct = (props) => {
	const { data, order, orderBy, onRequestSort, rowData, setSortedData } = props;

	const createSortHandler = (property) => (event) => {
		let fieldId;

		console.log("25 property:", property);
		console.log("25 row data:", rowData);

		if (property === "number") {
			fieldId = "fields[Loan.LoanNumber]";
		} else if (property === "expiration-date") {
			fieldId = "fields[Fields.762]";
		} else if (property === "days-to-expiry") {
			fieldId = "fields[Fields.432]";
		} else if (property === "program") {
			fieldId = "fields[Fields.1401]";
		} else if (property === "name") {
			fieldId = "fields[Loan.BorrowerName]";
		} else if (property === "folder") {
			fieldId = "fields[Loan.LoanFolder]";
		} else if (property === "status") {
			fieldId = "fields[Fields.Log.MS.CurrentMilestone]";
		} else if (property === "start-date") {
			fieldId = "fields[Fields.2025]";
		} else if (property === "address") {
			fieldId = "fields[Fields.URLA.X73]";
		} else if (property === "label") {
			fieldId = "title";
		} else if (property === "upload-date") {
			fieldId = "createdDate";
		}
		// TODO add if statements for condition tracking and change request tables

		let sortedData;

		console.log(
			"52 property includes:",
			property?.toLowerCase().includes("date")
		);

		if (property?.toLowerCase().includes("date")) {
			console.log("50 sort date", rowData, fieldId);
			sortedData = sortDate(rowData, order, fieldId);
		} else {
			console.log("50 sort text");
			sortedData = sortAmount(rowData, order, fieldId);
		}

		setSortedData(sortedData);
		onRequestSort(event, property);
	};

	return (
		<TableHead>
			<TableRow>
				{data.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={headCell.numeric ? "right" : "left"}
						padding={headCell.disablePadding ? "none" : "normal"}
						sortDirection={orderBy === headCell.id ? order : false}
						style={{ minWidth: headCell.minWidth }}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : "asc"}
							onClick={createSortHandler(headCell.id)}
						>
							<Heading5
								text={headCell.label}
								extraClass={styles.thText}
								fontType="semibold"
							/>
							{orderBy === headCell.id ? (
								<Box component="span" sx={visuallyHidden}>
									{order === "desc" ? "sorted descending" : "sorted ascending"}
								</Box>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
};

export default TableHeadIneligibleProduct;
