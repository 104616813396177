import React, { useEffect, useState } from "react";

// mui components
import { Box, Stack, Tab, Tabs } from "@mui/material";

// custom components
import TabPanel from "../../tab-panel";
import TemporaryDrawer from "../../temporary-drawer";
import RequiredError from "../../required-error";
import ViewLoanInfoCard from "../../../pages/view-loan/components/info-card";
import AssetsInformation from "../../loan-form-card/assets-information";
import OtherAssetsInformation from "../../loan-form-card/other-assets-information";
import LiabilitesInformation from "../../loan-form-card/liabilities-information";
import OtherLiabilitesInformation from "../../loan-form-card/other-liabilities-information";
import RealEstate from "../../loan-form-card/real-estate";
import PrimaryButton from "../../buttons/primary-button";
import SecondaryButton from "../../buttons/secondary-button";
import Heading6 from "../../typography/heading-06";

// api slice
import { useGetBorrowerPairMutation } from "../../../features/get-borrower-pair/getBorrowerPairApiSlice";
import { useUpdateBorrowerPairMutation } from "../../../features/update-borrower-pair/updateBorrowerPairApiSlice";

// mui icons
import { ArrowBackOutlined, ArrowForwardOutlined } from "@mui/icons-material";

// custom modal
import LoaderModal from "../../modal/loader-modal";
import ErrorModal from "../../modal/error-modal";
import LiabilityLinkModal from "../../modal/link-liability-modal";

// helper utils
import { validateAssetsInformation } from "../../../utils/required-field-helper/asset-section-required-helper";

// custom styles
import styles from "./index.module.scss";

export const LoanAssetsApplication = ({
	setLoanApplicationStage,
	loanGuid,
	currentStage,
	viewLoan,
}) => {
	console.log("45 loan id:", loanGuid);

	const [getBorrowerPair, { isLoading: loadingBorrowerPairData }] =
		useGetBorrowerPairMutation();

	const [updateBorrowerPair, { isLoading: updatingBorrowerPair }] =
		useUpdateBorrowerPairMutation();

	const [borrowerResponseData, setBorrowerResponseData] = useState([]);
	const [borrowerPairData, setBorrowerPairData] = useState([]);

	const [tabValue, setTabValue] = useState(0);

	const [error, setError] = useState("");
	const [isSubmitted, setIsSubmitted] = useState(false);

	const [borrowerAsset, setBorrowerAsset] = useState([]);
	const [borrowerOtherAssets, setBorrowerOtherAssets] = useState([]);
	const [borrowerLiability, setBorrowerLiability] = useState([]);
	const [borrowerOtherLiability, setBorrowerOtherLiability] = useState([]);
	const [borrowerRealEstate, setBorrowerRealEstate] = useState([]);

	const [selectedRealEstate, setSelectedRealEstate] = useState(null);
	const [selectedRealEstateData, setSelectedRealEstateData] = useState(null);
	const [liabilityModalVisible, setLiabilityModalVisible] = useState(false);

	const handleOpenLiabilityModal = () => {
		setLiabilityModalVisible(true);
	};

	const handleCloseLiabilityModal = () => {
		setLiabilityModalVisible(false);
		setSelectedRealEstate(null);
		setSelectedRealEstateData(null);
	};

	const [drawerType, setDrawerType] = useState("");
	const [drawerOpen, setDrawerOpen] = useState({});

	const handleOpenDrawer = () => {
		setDrawerOpen({
			right: true,
		});
	};

	const handleCloseDrawer = () => {
		setDrawerOpen({
			right: false,
		});
	};

	const toggleDrawer = (event, drawerType) => {
		if (drawerType !== undefined && drawerType !== "") {
			setDrawerType(drawerType);
			handleOpenDrawer();
		} else {
			setDrawerType("");
			handleCloseDrawer();
		}
	};

	const getAllBorrowerPair = async () => {
		const borrowerPairData = await getBorrowerPair({
			loanGuid,
		}).unwrap();

		console.log("90 borrower data:", borrowerPairData?.response);

		let assetArr = [];
		let otherAssetsArr = [];
		let liabilityArr = [];
		let otherLiabilityArr = [];
		let realEstateArr = [];

		for (let index = 0; index < borrowerPairData?.response?.length; index++) {
			console.log("100 res data:", borrowerPairData?.response[index]);
			if (borrowerPairData?.response[index]?.assets?.length > 0) {
				assetArr.push(borrowerPairData?.response[index]?.assets);
			} else {
				assetArr.push([]);
			}

			if (borrowerPairData?.response[index]?.otherAssets?.length > 0) {
				otherAssetsArr.push(borrowerPairData?.response[index]?.otherAssets);
			} else {
				otherAssetsArr.push([]);
			}

			if (borrowerPairData?.response[index]?.liabilities?.length > 0) {
				liabilityArr.push(borrowerPairData?.response[index]?.liabilities);
			} else {
				liabilityArr.push([]);
			}

			if (borrowerPairData?.response[index]?.otherLiabilities?.length > 0) {
				otherLiabilityArr.push(
					borrowerPairData?.response[index]?.otherLiabilities
				);
			} else {
				otherLiabilityArr.push([]);
			}

			if (borrowerPairData?.response[index]?.reoProperties?.length > 0) {
				realEstateArr.push(borrowerPairData?.response[index]?.reoProperties);
			} else {
				realEstateArr.push([]);
			}
		}

		setBorrowerAsset(assetArr);
		setBorrowerOtherAssets(otherAssetsArr);
		setBorrowerLiability(liabilityArr);
		setBorrowerOtherLiability(otherLiabilityArr);
		setBorrowerRealEstate(realEstateArr);
		setBorrowerResponseData(borrowerPairData?.response);

		let pairArr = [];

		borrowerPairData?.response?.map((item) =>
			pairArr.push(item.borrower, item.coborrower)
		);

		console.log("135 pair arr:", pairArr);

		setBorrowerPairData(pairArr);
	};

	useEffect(() => {
		console.log("160 loan Guid:", loanGuid);
		if (loanGuid !== null && loanGuid !== undefined && loanGuid !== "") {
			getAllBorrowerPair();
		}
	}, [loanGuid]);

	console.log("200 asset arr:", borrowerAsset);
	console.log("200 other assets arr:", borrowerOtherAssets);
	console.log("200 liability arr:", borrowerLiability);
	console.log("200 other liability arr:", borrowerOtherLiability);
	console.log("200 real estate arr:", borrowerRealEstate);

	const updateBorrowerPairFunc = async (index) => {
		console.log("345 index:", index);
		console.log("345 res data:", borrowerResponseData[index]);

		let applications = [
			{
				assets: borrowerAsset[index]?.length > 0 ? borrowerAsset[index] : [],
				otherAssets:
					borrowerOtherAssets[index]?.length > 0
						? borrowerOtherAssets[index]
						: [],
				liabilities:
					borrowerLiability[index]?.length > 0 ? borrowerLiability[index] : [],
				otherLiabilities:
					borrowerOtherLiability[index]?.length > 0
						? borrowerOtherLiability[index]
						: [],
				reoProperties:
					borrowerRealEstate[index]?.length > 0
						? borrowerRealEstate[index]
						: [],
			},
		];
		const updateLoanBroker = await updateBorrowerPair({
			loanGuid,
			loanData: {
				id: borrowerResponseData[index].borrowerPairId,
				applicationId: borrowerResponseData[index].borrowerPairId,
				applicationIndex: index,
				...applications[0],
			},
			borrowerPairId: borrowerResponseData[index].borrowerPairId,
		}).unwrap();

		console.log("90 update broker loan:", updateLoanBroker);

		return updateLoanBroker;
	};

	const handlePrev = () => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});
		setLoanApplicationStage(currentStage - 1);
	};

	const handleNext = async () => {
		console.log("450 pair data:", borrowerPairData);
		console.log("450 asset data:", borrowerAsset);
		console.log("450 other assets data:", borrowerOtherAssets);
		console.log("450 liability data:", borrowerLiability);
		console.log("450 other liability data:", borrowerOtherLiability);
		console.log("450 real estate data:", borrowerRealEstate);

		if (
			validateAssetsInformation(
				borrowerResponseData,
				borrowerLiability,
				borrowerRealEstate
			)
		) {
			setError("Please provide a value for all fields highlighted above.");
			setIsSubmitted(true);
			return;
		}

		try {
			console.log("700 loan id:", loanGuid);
			console.log("700 borrowerResponseData:", borrowerResponseData);
			console.log("700 borrowerPairData:", borrowerPairData);

			for (let index = 0; index < borrowerResponseData.length; index++) {
				const element = borrowerResponseData[index];
				console.log("390 el:", element);

				let updateResponse = await updateBorrowerPairFunc(index);

				console.log("393 updateResponse:", updateResponse);

				if (
					index === borrowerResponseData.length - 1 &&
					updateResponse.message.includes("success")
				) {
					console.log("395 update borrower pair");
					window.scrollTo({
						top: 0,
						left: 0,
						behavior: "smooth",
					});
					setLoanApplicationStage(currentStage + 1);
				}
			}
		} catch (err) {
			console.log("95 err:", err);
			setError(err?.data?.details);
		}
	};

	const handleNextView = () => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});

		setLoanApplicationStage(currentStage + 1);
	};

	function a11yProps(index) {
		return {
			id: `simple-tab-${index}`,
			"aria-controls": `simple-tabpanel-${index}`,
		};
	}

	const handleChangeTab = (event, newValue) => {
		setTabValue(newValue);
	};

	console.log("525 borrower pair:", borrowerPairData);

	const [errorText, setErrorText] = useState("");
	const [errorModalVisible, setErrorModalVisible] = useState(false);

	const handleOpenErrorModal = () => {
		setErrorModalVisible(true);
	};

	const handleCloseErrorModal = () => {
		setErrorModalVisible(false);
	};

	const renderTabs = () => {
		let arr = [];
		for (let i = 1; i <= borrowerPairData.length / 2; i++) {
			arr.push(
				<Tab
					className={styles.tabBtnContainer}
					label={
						<Stack direction="row" className={styles.tabLabelContainer}>
							<Heading6
								text={`Borrower Pair ${i}`}
								fontType="semibold"
								extraClass={
									tabValue === i - 1 ? styles.activeTabText : styles.tabText
								}
							/>
						</Stack>
					}
					{...a11yProps(i - 1)}
				/>
			);
		}
		return arr;
	};

	const informationViewElements = (assetLiabilityREIndex, i) => {
		return (
			<Stack>
				{viewLoan && (
					<ViewLoanInfoCard
						borrower={borrowerPairData[0]}
						coBorrower={borrowerPairData[1]}
					/>
				)}

				{/* assets */}
				{borrowerAsset
					?.slice(assetLiabilityREIndex, assetLiabilityREIndex + 1)
					?.map((asset, index) => (
						<AssetsInformation
							key={index}
							assetIndex={index + assetLiabilityREIndex}
							assetData={asset}
							data={borrowerAsset}
							setData={setBorrowerAsset}
							disabled={viewLoan}
						/>
					))}

				{/* other assets */}
				{borrowerOtherAssets
					?.slice(assetLiabilityREIndex, assetLiabilityREIndex + 1)
					?.map((otherAsset, index) => (
						<OtherAssetsInformation
							key={index}
							assetIndex={index + assetLiabilityREIndex}
							assetData={otherAsset}
							data={borrowerOtherAssets}
							setData={setBorrowerOtherAssets}
							disabled={viewLoan}
						/>
					))}

				{/* liabilities */}
				{borrowerLiability
					?.slice(assetLiabilityREIndex, assetLiabilityREIndex + 1)
					?.map((liability, index) => (
						<LiabilitesInformation
							key={index}
							liabilityIndex={index + assetLiabilityREIndex}
							liabilityData={liability}
							data={borrowerLiability}
							setData={setBorrowerLiability}
							disabled={viewLoan}
							isSubmitted={isSubmitted}
						/>
					))}

				{/* other liabilities */}
				{borrowerOtherLiability
					?.slice(assetLiabilityREIndex, assetLiabilityREIndex + 1)
					?.map((otherLiability, index) => (
						<OtherLiabilitesInformation
							key={index}
							liabilityIndex={index + assetLiabilityREIndex}
							liabilityData={otherLiability}
							data={borrowerOtherLiability}
							setData={setBorrowerOtherLiability}
							disabled={viewLoan}
						/>
					))}

				{/* real estate */}
				{borrowerRealEstate
					?.slice(assetLiabilityREIndex, assetLiabilityREIndex + 1)
					?.map((estate, index) => (
						<RealEstate
							key={index}
							estateIndex={index + assetLiabilityREIndex}
							estateData={estate}
							data={borrowerRealEstate}
							setData={setBorrowerRealEstate}
							selectedTab={tabValue}
							liabilityData={borrowerLiability}
							liabilityCount={borrowerLiability?.length}
							handleOpenLiabilityModal={handleOpenLiabilityModal}
							setSelectedRealEstate={setSelectedRealEstate}
							setSelectedRealEstateData={setSelectedRealEstateData}
							handleOpenErrorModal={handleOpenErrorModal}
							toggleDrawer={toggleDrawer}
							setErrorText={setErrorText}
							disabled={viewLoan}
							isSubmitted={isSubmitted}
						/>
					))}
			</Stack>
		);
	};

	const renderInformation = () => {
		let arr = [];
		for (let i = 1; i <= borrowerPairData.length / 2; i++) {
			// i is initialized to 1 instead of 0 so that we can it helps in thinking about borrower pair number.
			let assetLiabilityREIndex = i - 1;

			if (borrowerPairData.length <= 2) {
				arr.push(
					<Box className={styles.tabPanelContainer}>
						{informationViewElements(assetLiabilityREIndex, i)}
					</Box>
				);
			} else {
				arr.push(
					<TabPanel
						value={tabValue}
						index={i - 1}
						className={tabValue === i - 1 ? styles.tabPanelContainer : ""}
					>
						{informationViewElements(assetLiabilityREIndex, i)}
					</TabPanel>
				);
			}
		}
		return arr;
	};

	return (
		<Stack direction="column" className={styles.stackContainer}>
			<Stack className={styles.bodyContainer}>
				{/* tabs section */}
				{borrowerPairData?.length > 2 && (
					<Tabs
						value={tabValue}
						onChange={handleChangeTab}
						aria-label="basic tabs example"
						TabIndicatorProps={{
							style: {
								backgroundColor: "#76D99E",
							},
						}}
					>
						{renderTabs()}
					</Tabs>
				)}

				{renderInformation()}
			</Stack>

			{/* loan flow error */}
			{error !== "" && (
				<RequiredError
					headerText={error.includes("Loan is locked") ? "" : "Missing fields"}
					text={
						error.includes("Loan is locked")
							? "This loan is currently being edited by Logan Finance. Once Logan Finance exits the loan, you will be able to navigate the loan in the system and the data displayed will be updated as needed."
							: error.includes("Something went wrong")
							? "Updates Not Received by LOS. Please Try Again."
							: error.includes("provide a value")
							? "Please provide a value for all fields highlighted above."
							: error
					}
				/>
			)}

			<Stack direction="row" className={styles.footerContainer}>
				<SecondaryButton
					text="Previous"
					startIcon={<ArrowBackOutlined className={styles.prevIcon} />}
					onClick={handlePrev}
				/>
				<PrimaryButton
					text={viewLoan ? "Continue" : "Save and Continue"}
					endIcon={<ArrowForwardOutlined className={styles.nextIcon} />}
					onClick={() => (viewLoan ? handleNextView() : handleNext())}
				/>
			</Stack>

			<TemporaryDrawer
				// drawer props
				drawerOpen={drawerOpen}
				drawerDirection="right"
				drawerType={drawerType}
				toggleDrawer={toggleDrawer}
				handleCloseDrawer={handleCloseDrawer}
				// linked liabilities props
				selectedTab={tabValue}
				liabilitiesData={borrowerLiability}
				selectedRealEstate={selectedRealEstate}
				selectedRealEstateData={selectedRealEstateData}
				realEstateData={borrowerRealEstate}
				setRealEstateData={setBorrowerRealEstate}
			/>

			{/* modals */}
			<LoaderModal open={loadingBorrowerPairData || updatingBorrowerPair} />

			<LiabilityLinkModal
				open={liabilityModalVisible}
				handleClose={handleCloseLiabilityModal}
				selectedRealEstate={selectedRealEstate}
				liabilitiesData={borrowerLiability}
				setLiabilitiesData={setBorrowerLiability}
				selectedTab={tabValue}
				confirmText="Submit"
				closeText="Cancel"
			/>

			<ErrorModal
				open={errorModalVisible}
				text={errorText}
				handleClose={handleCloseErrorModal}
			/>
		</Stack>
	);
};

export default LoanAssetsApplication;
