import React, { useEffect, useState } from "react";

// mui components
import { Box, IconButton, Stack } from "@mui/material";

// custom components
import CustomTable from "../../custom-table";
import Heading5 from "../../typography/heading-05";
import Body3 from "../../typography/body-03";

// mui icons
import { AddOutlined } from "@mui/icons-material";

// custom styles
import styles from "./index.module.scss";

const RealEstate = ({
	estateIndex,
	estateData,
	data,
	setData,
	isSubmitted,
	selectedTab,
	liabilityData,
	liabilityCount,
	handleOpenLiabilityModal,
	setSelectedRealEstate,
	setSelectedRealEstateData,
	handleOpenErrorModal,
	toggleDrawer,
	setErrorText,
	disabled,
}) => {
	const [estateRows, setEstateRows] = useState([]);

	const estateInfoColumns = [
		{ id: "edit", label: "", minWidth: 48 },
		{ id: "index", label: "", minWidth: 48 },
		{ id: "owner", label: "Owner", minWidth: 160 },
		{ id: "status", label: "Status", minWidth: 160 },
		{ id: "property-address", label: "Property Address", minWidth: 160 },
		{ id: "property-value", label: "Property Value", minWidth: 160 },
		{
			id: "monthly-expenses",
			label: "Monthly Insurance, Taxes, HOA",
			minWidth: 160,
		},
		// { id: "monthly-payment", label: "Monthly Payment", minWidth: 160 },
		// { id: "lien-amount", label: "Lien Amount", minWidth: 160 },
		// { id: "lien-holder", label: "Lien Holder", minWidth: 160 },
		{
			id: "monthly-rent",
			label: "Current or Estimated Monthly Rent",
			minWidth: 160,
		},
		{ id: "subject-property", label: "Subject Property", minWidth: 160 },
		{ id: "property-use", label: "Property Use", minWidth: 160 },
		{ id: "link-liabilities", label: "Liabilities", minWidth: 160 },
		{ id: "linked-liabilities", label: "", minWidth: 200 },
	];

	useEffect(() => {
		if (estateData !== undefined) {
			setEstateRows(estateData);
		}
	}, [estateData]);

	const handleDeleteRow = (reoId) => {
		// filter liabilities to check if real estate is linked to it
		let filteredLiabilityData = liabilityData[selectedTab]?.filter(
			(liability) => liability?.reoId === reoId
		);

		// if real estate does not have linked liabilities, delete it
		if (filteredLiabilityData?.length === 0) {
			setData(
				data.map((data, index) => {
					if (estateIndex === index) {
						return estateData?.filter((row) => row?.reoId !== reoId);
					} else {
						return data;
					}
				})
			);
		} else {
			// if real estate does has linked liabilities, show error modal
			setErrorText("Real estate has linked liabilities. Unlink to proceed.");
			handleOpenErrorModal();
		}
	};

	// const handleUpdateRow = (rowIndex, field, value) => {
	// 	setEstateRows(() =>
	// 		estateRows?.map((obj, i) => {
	// 			if (i === rowIndex) {
	// 				return { ...obj, [field]: value };
	// 			}

	// 			return obj;
	// 		})
	// 	);

	// 	setData(
	// 		data.map((data, index) => {
	// 			if (estateIndex === index) {
	// 				let updatedData = estateData?.map((obj, i) => {
	// 					if (i === rowIndex) {
	// 						return { ...obj, [field]: value };
	// 					}
	// 					return obj;
	// 				});
	// 				return updatedData;
	// 			} else {
	// 				return data;
	// 			}
	// 		})
	// 	);
	// };

	const handleReOwned = (reoId) => {
		console.log("60 clicked:", reoId);
		setSelectedRealEstate(reoId);
		handleOpenLiabilityModal();
	};

	const handleViewLinked = (e, reoId, reoData) => {
		console.log("140 view linked liabilities", reoId, reoData);
		setSelectedRealEstate(reoId);
		setSelectedRealEstateData(reoData);
		toggleDrawer(e, "linked-liabilities");
	};

	const handleUpdateRow = (e, reoId, reoData) => {
		console.log("140 edit real estate:", reoId, reoData);
		setSelectedRealEstate(reoId);
		setSelectedRealEstateData(reoData);
		toggleDrawer(e, "add-real-estate");
	};

	return (
		<Box className={styles.cardContainer}>
			<Stack direction="column" className={styles.cardHeaderContainer}>
				<Heading5 text="Real Estate Owned" fontType="semibold" />

				<Body3 text="Provide details about the real estate owned" />
			</Stack>
			<Stack direction="column" className={styles.formSectionContainer}>
				<Stack direction="row" className={styles.inputSectionContainer}>
					{/* real estate owned information table  */}
					<CustomTable
						tableType="real-estate-information"
						columnData={estateInfoColumns}
						rowData={estateRows}
						handleRemoveRow={handleDeleteRow}
						handleUpdate={handleUpdateRow}
						disableRow={disabled}
						liabilityCount={liabilityCount}
						handleReOwned={handleReOwned}
						handleViewLinked={handleViewLinked}
						isSubmitted={isSubmitted}
						// handleEditRealEstate={handleEditRealEstate}
					/>

					<Box className={styles.addIconContainer}>
						<IconButton
							id="add-real-estate-btn"
							className={styles.addIconBtn}
							onClick={(event) => {
								toggleDrawer(event, "add-real-estate");
								setSelectedRealEstateData(null);
							}}
							disabled={disabled || estateRows?.length >= 25}
						>
							<AddOutlined className={styles.addIcon} />
						</IconButton>
					</Box>
				</Stack>
			</Stack>
		</Box>
	);
};

export default RealEstate;
