import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// mui components
import { Box, Stack, Tab, Tabs } from "@mui/material";

// custom components
import TabPanel from "../../tab-panel";
import RequiredError from "../../required-error";
import ContactInformation from "../../loan-form-card/contact-information";
import ViewLoanInfoCard from "../../../pages/view-loan/components/info-card";
import PrimaryButton from "../../buttons/primary-button";
import SecondaryButton from "../../buttons/secondary-button";
import Heading6 from "../../typography/heading-06";

// api slice
import { useGetBorrowerPairMutation } from "../../../features/get-borrower-pair/getBorrowerPairApiSlice";
import { useUpdateBorrowerPairMutation } from "../../../features/update-borrower-pair/updateBorrowerPairApiSlice";
import { useFieldMutation } from "../../../features/field-values/fieldValueApiSlice";

// reducer slice
import { selectSameAsCurrentAddress } from "../../../features/same-as-current-address/sameAsCurrentAddressSlice";

// mui icons
import { ArrowBackOutlined, ArrowForwardOutlined } from "@mui/icons-material";

// custom modal
import LoaderModal from "../../modal/loader-modal";

// helper utils
import { validateContactInformation } from "../../../utils/required-field-helper/contact-info-required-helper";
import { validateCreditReissueInformation } from "../../../utils/required-field-helper/credit-reissue-validation-helper";

// custom styles
import styles from "./index.module.scss";
import { useUpdateFieldsMutation } from "../../../features/update-fields/updateFieldsApiSlice";
import { CREDIT_REISSUE_CONSTANTS } from "../../../utils/credit-reissue-constants";

export const LoanAddressApplication = ({
	setLoanApplicationStage,
	loanGuid,
	currentStage,
	viewLoan,
}) => {
	console.log("45 loan id:", loanGuid);

	const [getBorrowerPair, { isLoading: loadingBorrowerPairData }] =
		useGetBorrowerPairMutation();

	const [updateBorrowerPair, { isLoading: updatingBorrowerPair }] =
		useUpdateBorrowerPairMutation();

	const [field, { isLoading: isFetchingFields }] = useFieldMutation();

	const [updateFields, { isLoading: isUpdatingFields }] =
		useUpdateFieldsMutation();

	const [borrowerResponseData, setBorrowerResponseData] = useState([]);
	const [borrowerPairData, setBorrowerPairData] = useState([]);

	const [borrowerResidences, setBorrowerResidences] = useState([]);
	const [sameAddress, setSameAddress] = useState([]);
	const [creditReissueData, setCreditReissueData] = useState("");

	const [tabValue, setTabValue] = useState(0);

	const sameAddressValue = useSelector(selectSameAsCurrentAddress);
	console.log("75 same address reducer:", sameAddressValue);

	const [totalHousingPayment, setTotalHousingPayment] = useState("");

	const modelArr = [
		{
			id: "Residence/0",
			applicantType: "Borrower",
			mailingAddressIndicator: false,
			residencyType: "Current",
		},
		{
			id: "Residence/1",
			applicantType: "Borrower",
			mailingAddressIndicator: false,
			residencyType: "Prior",
		},
		{
			id: "Residence/2",
			applicantType: "Borrower",
			mailingAddressIndicator: true,
		},
		{
			id: "Residence/3",
			applicantType: "CoBorrower",
			mailingAddressIndicator: false,
			residencyType: "Current",
		},
		{
			id: "Residence/4",
			applicantType: "CoBorrower",
			mailingAddressIndicator: false,
			residencyType: "Prior",
		},
		{
			id: "Residence/5",
			applicantType: "CoBorrower",
			mailingAddressIndicator: true,
		},
	];

	const findMissingObjects = (data) => {
		const missingObjects = [];

		console.log("100 model arr:", modelArr);
		console.log("100 data arr:", data);

		for (const modelObj of modelArr) {
			const found = data.some(
				(dataObj) =>
					dataObj.applicantType === modelObj.applicantType &&
					(dataObj.residencyType || undefined) === modelObj.residencyType &&
					(dataObj.mailingAddressIndicator || false) ===
						modelObj.mailingAddressIndicator
			);

			if (!found) {
				missingObjects.push(modelObj);
			}
		}

		return missingObjects;
	};

	const reorderResidencesArray = (
		arr,
		borrowerMailingAddress,
		coborrowerMailingAddress,
		borrowerPairIndex
	) => {
		console.log("100 arr:", arr);

		const missingObjectsArr = findMissingObjects(arr);
		console.log("100 missing object arr:", missingObjectsArr);

		const mergedArray = [...arr, ...missingObjectsArr];

		console.log("130 merged array:", mergedArray);

		// Separate the objects into two groups: borrowers and co-borrowers
		const borrowers = mergedArray
			.filter((obj) => obj.applicantType === "Borrower")
			.sort((a, b) => {
				const numA = parseInt(a.id.split("Residence/")[1]);
				const numB = parseInt(b.id.split("Residence/")[1]);
				return numA - numB;
			});

		const coBorrowers = mergedArray
			.filter((obj) => obj.applicantType === "CoBorrower")
			.sort((a, b) => {
				const numA = parseInt(a.id.split("Residence/")[1]);
				const numB = parseInt(b.id.split("Residence/")[1]);
				return numA - numB;
			});

		console.log("125 borrowers:", borrowers);
		console.log("125 Co Borrowers:", coBorrowers);

		// Sort the residency objects (the first two objects) by residencyType
		// const residencyObjects = [borrowers[0], coBorrowers[0]].filter(Boolean);

		// residencyObjects.sort((a, b) => {
		// 	const aIndex = a.residencyType === "Current" ? 0 : 1;
		// 	const bIndex = b.residencyType === "Current" ? 0 : 1;
		// 	return aIndex - bIndex;
		// });

		// // Sort the prior objects (the first two objects) by priorType
		// const priorObjects = [borrowers[1], coBorrowers[1]].filter(Boolean);

		// priorObjects.sort((a, b) => {
		// 	const aIndex = a.priorType === "Prior" ? 0 : 1;
		// 	const bIndex = b.priorType === "Prior" ? 0 : 1;
		// 	return aIndex - bIndex;
		// });

		// // Sort the mailing objects (the next two objects) by mailingAddressIndicator
		// const mailingObjects = [borrowers[2], coBorrowers[2]].filter(Boolean);

		// mailingObjects.sort((a, b) => {
		// 	const aIndex = a.mailingAddressIndicator ? 1 : 0;
		// 	const bIndex = b.mailingAddressIndicator ? 1 : 0;
		// 	return aIndex - bIndex;
		// });

		// console.log("60 residency:", residencyObjects);
		// console.log("60 prior:", priorObjects);
		// console.log("60 mailing:", mailingObjects);

		console.log("150 borrower mailing address:", borrowerMailingAddress);
		console.log("150 coborrower mailing address:", coborrowerMailingAddress);

		// mailingObjects[0]["mailingAddressSameAsPresentIndicator"] =
		// 	borrowerMailingAddress;
		// mailingObjects[1]["mailingAddressSameAsPresentIndicator"] =
		// 	borrowerMailingAddress;

		// console.log("160 mailing:", mailingObjects);

		// var mailingArr = JSON.parse(JSON.stringify(mailingObjects));
		// mailingArr[0]["sameAddress"] = borrowerMailingAddress;
		// mailingArr[1]["sameAddress"] = coborrowerMailingAddress;

		if (borrowerPairIndex === 0 && borrowerMailingAddress) {
			// borrower at first borrower pair
			setSameAddress((prevArr) => [...prevArr, 2]);
		} else if (borrowerPairIndex === 1 && borrowerMailingAddress) {
			// borrower at second borrower pair
			setSameAddress((prevArr) => [...prevArr, 8]);
		} else if (borrowerPairIndex === 2 && borrowerMailingAddress) {
			// borrower at third borrower pair
			setSameAddress((prevArr) => [...prevArr, 14]);
		}

		if (borrowerPairIndex === 0 && coborrowerMailingAddress) {
			// co-borrower at first borrower pair
			setSameAddress((prevArr) => [...prevArr, 5]);
		} else if (borrowerPairIndex === 1 && coborrowerMailingAddress) {
			// co-borrower at second borrower pair
			setSameAddress((prevArr) => [...prevArr, 11]);
		} else if (borrowerPairIndex === 2 && coborrowerMailingAddress) {
			// co-borrower at third borrower pair
			setSameAddress((prevArr) => [...prevArr, 17]);
		}

		// console.log("160 mailing:", mailingArr);

		// const mergeArrays = (a, b) =>
		// 	(a.length > b.length ? a : b).reduce(
		// 		(acc, cur, i) => (a[i] && b[i] ? [...acc, a[i], b[i]] : [...acc, cur]),
		// 		[]
		// 	);

		// Combine the sorted residency and mailing objects into a new array
		// const reorderedArray = mergeArrays(
		// 	residencyObjects,
		// 	priorObjects,
		// 	mailingArr
		// );

		const reorderedArray = [...borrowers, ...coBorrowers];

		/* let l = Math.min(residencyObjects.length, mailingObjects.length);
		
			for (i = 0; i < l; i++) {
			reorderedArray.push(residencyObjects[i], mailingObjects[i]);
			} */

		console.log("60 reorder:", reorderedArray);

		// Assign the correct id value to each object based on its index in the new array
		let finalArray;

		// if array length is less than 6
		if (reorderedArray?.length <= 6) {
			finalArray = reorderedArray.map((obj, index) => {
				const id = `Residence/${
					obj.applicantType === "Borrower" && obj.residencyType === "Current"
						? 0
						: obj.applicantType === "Borrower" && obj.residencyType === "Prior"
						? 1
						: obj.applicantType === "Borrower" && obj.mailingAddressIndicator
						? 2
						: obj.applicantType === "CoBorrower" &&
						  obj.residencyType === "Current"
						? 3
						: obj.applicantType === "CoBorrower" &&
						  obj.residencyType === "Prior"
						? 4
						: obj.applicantType === "CoBorrower" && obj.mailingAddressIndicator
						? 5
						: index
				}`;
				return { ...obj, id };
			});
		} else {
			finalArray = reorderedArray;
		}

		console.log("70 final residence array:", finalArray);

		const sortedArr = finalArray.sort((a, b) => {
			const aIndex = parseInt(a.id.split("/")[1]);
			const bIndex = parseInt(b.id.split("/")[1]);
			return aIndex - bIndex;
		});

		console.log("70 sorted:", sortedArr);

		// const missingId = modelArr.filter((obj) => {
		// 	return !sortedArr.find((o) => {
		// 		return o.id === obj.id;
		// 	});
		// });

		// console.log("80 missing:", missingId);

		// if (missingId.length > 0) {
		// 	missingId.forEach((missingObj) => {
		// 		const missingIndex = parseInt(missingObj.id.split("/")[1]);
		// 		sortedArr.splice(missingIndex, 0, missingObj);
		// 	});
		// }

		console.log("200 sorted arr:", sortedArr);
		return sortedArr;
	};

	const getAllBorrowerPair = async () => {
		const borrowerPairData = await getBorrowerPair({
			loanGuid,
		}).unwrap();

		console.log("90 borrower data:", borrowerPairData?.response);

		let residenceArr = [];
		let totalHousingArr = [];

		for (let index = 0; index < borrowerPairData?.response?.length; index++) {
			console.log("100 res data:", borrowerPairData?.response[index]);

			residenceArr.push(
				...reorderResidencesArray(
					borrowerPairData?.response[index]?.residences,
					borrowerPairData?.response[index]?.borrower
						?.mailingAddressSameAsPresentIndicator,
					borrowerPairData?.response[index]?.coborrower
						?.mailingAddressSameAsPresentIndicator,
					index
				)
			);

			if (
				borrowerPairData?.response[index]
					?.firstMortgagePrincipalAndInterestAmount !== undefined
			) {
				totalHousingArr.push(
					borrowerPairData?.response[index]
						?.firstMortgagePrincipalAndInterestAmount
				);
			} else {
				totalHousingArr.push(0);
			}
		}

		setBorrowerResidences(residenceArr);
		setTotalHousingPayment(totalHousingArr);

		setBorrowerResponseData(borrowerPairData?.response);

		let pairArr = [];

		borrowerPairData?.response?.map((item) =>
			pairArr.push(item.borrower, item.coborrower)
		);

		console.log("135 pair arr:", pairArr);

		setBorrowerPairData(pairArr);

		try {
			const getValues = await field({
				loan_guid: loanGuid,
				fields: "CX.LC.Credit.Provided",
			}).unwrap();

			console.log("175 all fields:", getValues?.response);

			if (getValues?.response?.length > 0) {
				setCreditReissueData(getValues?.response[0]?.value || "");
			}
		} catch (err) {
			console.log("270 error:", err);
		}
	};

	useEffect(() => {
		console.log("160 loan Guid:", loanGuid);
		if (loanGuid !== null && loanGuid !== undefined && loanGuid !== "") {
			getAllBorrowerPair();
		}
	}, [loanGuid]);

	console.log("200 residence arr:", borrowerResidences);
	console.log("200 total housing arr:", totalHousingPayment);

	const [error, setError] = useState("");

	const handleUpdateOnlyPair = async () => {
		console.log("285 running");

		let index = 0;

		console.log("345 index:", index);
		console.log("345 res data:", borrowerResponseData[index]);

		console.log("700 borrower Residences:", borrowerResidences);

		let borrowerData = {
			...borrowerPairData[index],
			hmdaNoCoApplicantIndicator: false,
			mailingAddressSameAsPresentIndicator:
				index === 0
					? // borrower at first borrower pair
					  sameAddressValue?.includes(2)
					: index === 1
					? // borrower at second borrower pair
					  sameAddressValue?.includes(8)
					: index === 2
					? // borrower at third borrower pair
					  sameAddressValue?.includes(14)
					: false,
		};

		console.log("205 borrower data:", borrowerData);

		let coBorrowerData = {
			...borrowerPairData[index + 1],
			hmdaNoCoApplicantIndicator: false,
			mailingAddressSameAsPresentIndicator:
				index === 0
					? // co-borrower at first borrower pair
					  sameAddressValue?.includes(5)
					: index === 1
					? // co-borrower at second borrower pair
					  sameAddressValue?.includes(11)
					: index === 2
					? // co-borrower at third borrower pair
					  sameAddressValue?.includes(17)
					: false,
		};

		// console.log("215 coBorrower data:", coBorrowerData);

		console.log("325 borrower:", borrowerData);
		console.log("325 co-borrower:", coBorrowerData);

		console.log("325 residences:", [
			borrowerResidences[index + 0],
			borrowerResidences[index + 1],
			borrowerResidences[index + 2],
			borrowerResidences[index + 3],
			borrowerResidences[index + 4],
			borrowerResidences[index + 5],
		]);

		let applications = [
			{
				id: borrowerResponseData[0]?.id,
				borrower: borrowerData,
				coborrower: coBorrowerData,
				residences: [
					borrowerResidences[index + 0],
					borrowerResidences[index + 1],
					borrowerResidences[index + 2],
					borrowerResidences[index + 3],
					borrowerResidences[index + 4],
					borrowerResidences[index + 5],
				],
			},
		];

		console.log("1500 update borrower pair data:", {
			id: borrowerResponseData[index].borrowerPairId,
			...applications[0],
		});

		const updateLoanBroker = await updateBorrowerPair({
			loanGuid,
			loanData: {
				id: borrowerResponseData[index].borrowerPairId,
				applicationId: borrowerResponseData[index].borrowerPairId,
				applicationIndex: index,
				firstMortgagePrincipalAndInterestAmount: totalHousingPayment[index],
				...applications[0],
			},
			borrowerPairId: borrowerResponseData[index].borrowerPairId,
		}).unwrap();

		console.log("90 update broker loan:", updateLoanBroker);

		if (updateLoanBroker.message.includes("success")) {
			console.log("395 update borrower pair");
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: "smooth",
			});
			setLoanApplicationStage(currentStage + 1);
		}
	};

	const updateBorrowerPairFunc = async (index) => {
		console.log("345 index:", index);
		console.log("345 res data:", borrowerResponseData[index]);

		let borrowerDataIndex =
			index === 0 ? 0 : index === 1 ? 2 : index === 2 && 4;

		let coBorrowerDataIndex =
			index === 0 ? 1 : index === 1 ? 3 : index === 2 && 5;

		let borrowerResidencesIndex =
			index === 0 ? 0 : index === 1 ? 6 : index === 2 && 12;

		console.log("700 borrower data:", borrowerPairData[coBorrowerDataIndex]);

		console.log("700 borrower Residences:", borrowerResidences);

		let borrowerData = {
			...borrowerPairData[borrowerDataIndex],
			hmdaNoCoApplicantIndicator: false,
			mailingAddressSameAsPresentIndicator:
				index === 0
					? // borrower at first borrower pair
					  sameAddressValue?.includes(2)
					: index === 1
					? // borrower at second borrower pair
					  sameAddressValue?.includes(8)
					: index === 2
					? // borrower at third borrower pair
					  sameAddressValue?.includes(14)
					: false,
		};

		console.log("205 borrower data:", borrowerData);

		let coBorrowerData = {
			...borrowerPairData[coBorrowerDataIndex],
			hmdaNoCoApplicantIndicator: false,
			mailingAddressSameAsPresentIndicator:
				index === 0
					? // co-borrower at first borrower pair
					  sameAddressValue?.includes(5)
					: index === 1
					? // co-borrower at second borrower pair
					  sameAddressValue?.includes(11)
					: index === 2
					? // co-borrower at third borrower pair
					  sameAddressValue?.includes(17)
					: false,
		};

		console.log("215 coBorrower data:", coBorrowerData);

		console.log("1700 index:", index);

		let applications = [
			{
				borrower: borrowerData,
				coborrower: coBorrowerData,
				residences: [
					borrowerResidences[borrowerResidencesIndex + 0],
					borrowerResidences[borrowerResidencesIndex + 1],
					borrowerResidences[borrowerResidencesIndex + 2],
					borrowerResidences[borrowerResidencesIndex + 3],
					borrowerResidences[borrowerResidencesIndex + 4],
					borrowerResidences[borrowerResidencesIndex + 5],
				],
			},
		];

		console.log("1700 update borrower pair data:", {
			id: borrowerResponseData[index].borrowerPairId,
			...applications[0],
		});

		const updateLoanBroker = await updateBorrowerPair({
			loanGuid,
			loanData: {
				id: borrowerResponseData[index].borrowerPairId,
				applicationId: borrowerResponseData[index].borrowerPairId,
				applicationIndex: index,
				firstMortgagePrincipalAndInterestAmount: totalHousingPayment[index],
				...applications[0],
			},
			borrowerPairId: borrowerResponseData[index].borrowerPairId,
		}).unwrap();

		console.log("90 update broker loan:", updateLoanBroker);

		return updateLoanBroker;
	};

	const handleLoanUpdateAPI = async () => {
		try {
			console.log("700 loan id:", loanGuid);
			console.log("700 borrowerResponseData:", borrowerResponseData);
			console.log("700 borrowerPairData:", borrowerPairData);

			// if (borrowerPairData?.length <= 2) {
			// 	console.log("325 borrowerPairData:", borrowerPairData);

			// 	handleUpdateOnlyPair();
			// } else {
			for (let index = 0; index < borrowerResponseData.length; index++) {
				const element = borrowerResponseData[index];
				console.log("390 el:", element);

				let updateResponse = await updateBorrowerPairFunc(index);

				console.log("393 updateResponse:", updateResponse);

				// Update fields in Encompass
				let valuesArr = [
					{ id: "CX.LC.Credit.Provided", value: creditReissueData },
				];
				const updateFieldValues = await updateFields({
					loan_guid: loanGuid,
					field_values: valuesArr,
				}).unwrap();
				console.log("90 file res:", updateFieldValues);

				if (
					index === borrowerResponseData.length - 1 &&
					updateResponse.message.includes("success") &&
					updateFieldValues?.response === "Field values updated successfully."
				) {
					console.log("395 update borrower pair");
					window.scrollTo({
						top: 0,
						left: 0,
						behavior: "smooth",
					});

					if (creditReissueData === CREDIT_REISSUE_CONSTANTS.REISSUE) {
						setLoanApplicationStage(currentStage + 1);
					} else {
						setLoanApplicationStage(currentStage + 2);
					}
				}
			}
			// }
		} catch (err) {
			console.log("95 err:", err);
			setError(err?.data?.details);
		}
	};

	const handlePrev = () => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});
		setLoanApplicationStage(currentStage - 1);
	};

	const handleNext = async () => {
		if (
			validateContactInformation(borrowerPairData, borrowerResidences) ||
			validateCreditReissueInformation(creditReissueData)
		) {
			setError("Please provide a value for all fields highlighted above.");
			return;
		}

		console.log("450 pairData:", borrowerPairData);
		console.log("450 residencesData:", borrowerResidences);

		handleLoanUpdateAPI();
	};

	const handleNextView = () => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});

		setLoanApplicationStage(currentStage + 1);
	};

	function a11yProps(index) {
		return {
			id: `simple-tab-${index}`,
			"aria-controls": `simple-tabpanel-${index}`,
		};
	}

	const handleChangeTab = (event, newValue) => {
		setTabValue(newValue);
	};

	console.log("525 borrower pair:", borrowerPairData);

	const renderTabs = () => {
		let arr = [];
		for (let i = 1; i <= borrowerPairData.length / 2; i++) {
			arr.push(
				<Tab
					className={styles.tabBtnContainer}
					label={
						<Stack direction="row" className={styles.tabLabelContainer}>
							<Heading6
								text={`Borrower Pair ${i}`}
								fontType="semibold"
								extraClass={
									tabValue === i - 1 ? styles.activeTabText : styles.tabText
								}
							/>
						</Stack>
					}
					{...a11yProps(i - 1)}
				/>
			);
		}
		return arr;
	};

	const informationViewElements = (resienceStartIndex, i) => {
		let splicedResidences = borrowerResidences?.slice(
			resienceStartIndex,
			resienceStartIndex + 6
		);

		return (
			<Stack>
				{viewLoan && (
					<ViewLoanInfoCard
						borrower={borrowerPairData[0]}
						coBorrower={borrowerPairData[1]}
					/>
				)}

				{/* contact information */}
				{splicedResidences?.map((residence, index) =>
					residence?.residencyType === "Prior" ? (
						splicedResidences[index - 1]?.durationTermYears < 2 && (
							<ContactInformation
								key={index}
								residenceIndex={index + resienceStartIndex}
								residenceData={residence}
								data={borrowerResidences}
								setData={setBorrowerResidences}
								coBorrowerName={borrowerPairData[2 * (i - 1) + 1]?.firstName}
								sameAddress={sameAddress}
								setSameAddress={setSameAddress}
								borrowerPairIndex={i - 1}
								totalHousingPayment={totalHousingPayment}
								setTotalHousingPayment={setTotalHousingPayment}
								error={error.includes("provide a value")}
								disabled={viewLoan}
								lastResidence={splicedResidences?.length === index + 1}
								creditReissueData={creditReissueData}
								setCreditReissueData={setCreditReissueData}
							/>
						)
					) : (
						<ContactInformation
							key={index}
							residenceIndex={index + resienceStartIndex}
							residenceData={residence}
							data={borrowerResidences}
							setData={setBorrowerResidences}
							coBorrowerName={borrowerPairData[2 * (i - 1) + 1]?.firstName}
							sameAddress={sameAddress}
							setSameAddress={setSameAddress}
							borrowerPairIndex={i - 1}
							totalHousingPayment={totalHousingPayment}
							setTotalHousingPayment={setTotalHousingPayment}
							error={error.includes("provide a value")}
							disabled={viewLoan}
							lastResidence={splicedResidences?.length === index + 1}
							creditReissueData={creditReissueData}
							setCreditReissueData={setCreditReissueData}
						/>
					)
				)}
			</Stack>
		);
	};

	const renderInformation = () => {
		let arr = [];

		for (let i = 1; i <= borrowerPairData.length / 2; i++) {
			// i is initialized to 1 instead of 0 so that we can it helps in thinking about borrower pair number.
			let residenceStartIndex = 6 * (i - 1); // because each borrower pair has 6 addresses

			if (borrowerPairData.length <= 2) {
				arr.push(
					<Box className={styles.tabPanelContainer}>
						{informationViewElements(residenceStartIndex, i)}
					</Box>
				);
			} else {
				arr.push(
					<TabPanel
						value={tabValue}
						index={i - 1}
						className={tabValue === i - 1 ? styles.tabPanelContainer : ""}
					>
						{informationViewElements(residenceStartIndex, i)}
					</TabPanel>
				);
			}
		}

		return arr;
	};

	return (
		<Stack direction="column" className={styles.stackContainer}>
			<Stack className={styles.bodyContainer}>
				{/* tabs section */}
				{borrowerPairData?.length > 2 && (
					<Tabs
						value={tabValue}
						onChange={handleChangeTab}
						aria-label="basic tabs example"
						TabIndicatorProps={{
							style: {
								backgroundColor: "#76D99E",
							},
						}}
					>
						{renderTabs()}
					</Tabs>
				)}

				{renderInformation()}
			</Stack>

			{error !== "" && (
				<RequiredError
					headerText={error.includes("Loan is locked") ? "" : "Missing fields"}
					text={
						error.includes("Loan is locked")
							? "This loan is currently being edited by Logan Finance. Once Logan Finance exits the loan, you will be able to navigate the loan in the system and the data displayed will be updated as needed."
							: error.includes("Something went wrong")
							? "Updates Not Received by LOS. Please Try Again."
							: error.includes("provide a value") &&
							  "Please provide a value for all fields highlighted above."
					}
				/>
			)}

			<Stack direction="row" className={styles.footerContainer}>
				<SecondaryButton
					text="Previous"
					startIcon={<ArrowBackOutlined className={styles.prevIcon} />}
					onClick={handlePrev}
				/>
				<PrimaryButton
					text={viewLoan ? "Continue" : "Save and Continue"}
					endIcon={<ArrowForwardOutlined className={styles.nextIcon} />}
					onClick={() => (viewLoan ? handleNextView() : handleNext())}
				/>
			</Stack>
			{/* liability modal */}
			<LoaderModal
				open={
					loadingBorrowerPairData ||
					updatingBorrowerPair ||
					isFetchingFields ||
					isUpdatingFields
				}
			/>
		</Stack>
	);
};

export default LoanAddressApplication;
