export const DEFAULT_CREDIT_CREDENTIALS_FORM = {
	creditReportIdentifier: "", // creditReportIdentifier
	clientID: "", // clientID, optional
	creditVendor: "", // partner's/vendor's name
	productName: "", // partner's/vendor's product name
	providerId: "", // Credit vendor id - partner's/vendor's id
	subscriberId: "", // optional
	userName: "", // loginId
	password: "", // password
	reportOn: "",
	productCode: "", // optional
};

export const CREDIT_REISSUE_CONSTANTS = {
	REISSUE: "Reissue",
	MANUAL: "Manual",
};

export const CLIENT_ID_ENABLED_PARTNERS = ["Informative Research"];
