import React from "react";
import { useDispatch, useSelector } from "react-redux";

// mui components
import { Box, InputLabel, Stack } from "@mui/material";

// custom components
import OutlinedTextInput from "../../custom-input/outlined-text-input";
import MaskedInput from "../../custom-input/masked-input";
import SecondarySelect from "../../custom-select/secondary-select";
import CustomCheckbox from "../../custom-checkbox";
import CustomRadio from "../../custom-radio";
import CustomDivider from "../../custom-divider";
import Heading5 from "../../typography/heading-05";
import Heading6 from "../../typography/heading-06";
import Body2 from "../../typography/body-02";
import Body3 from "../../typography/body-03";

// mui icons
import { ErrorOutlined } from "@mui/icons-material";

// helper utils
import {
	countryOptions,
	creditReissueOptions,
	housingExpenseOptions,
	stateOptions,
} from "../../../utils/select-options";
import { isEmptyOrUndefined } from "../../../utils/filter-value";

// reducer slice
import {
	selectSameAsCurrentAddress,
	setSameAsCurrentAddressReducer,
} from "../../../features/same-as-current-address/sameAsCurrentAddressSlice";

// custom styles
import styles from "./index.module.scss";

const ContactInformation = ({
	residenceIndex,
	residenceData,
	data,
	setData,
	coBorrowerName,
	sameAddress,
	setSameAddress,
	borrowerPairIndex,
	totalHousingPayment,
	setTotalHousingPayment,
	disabled,
	error,
	lastResidence,
	creditReissueData,
	setCreditReissueData,
}) => {
	const dispatch = useDispatch();
	const sameAddressValue = useSelector(selectSameAsCurrentAddress);

	const addressType =
		residenceData?.residencyType === "Current"
			? "current"
			: residenceData?.residencyType === "Prior"
			? "prior"
			: residenceData?.mailingAddressIndicator && "mail";

	const coBorrower = residenceData?.applicantType === "CoBorrower";

	const handleChangeTermMonths = (event) => {
		const min = 0;
		const max = 11;

		const value = Math.max(min, Math.min(max, Number(event)));

		setData(
			data.map((data, index) => {
				if (residenceIndex === index) {
					return { ...data, durationTermMonths: value };
				} else {
					return data;
				}
			})
		);
	};

	const handleRentAmount = (value) => {
		setData(
			data.map((data, index) => {
				if (residenceIndex === index) {
					return { ...data, rent: value };
				} else {
					return data;
				}
			})
		);
	};

	const handleSameSwitch = (status) => {
		console.log("55 res index:", residenceIndex);

		if (status === true) {
			setSameAddress((prevArr) => [...prevArr, residenceIndex]);

			let sameAddressArr = [];

			console.log("70 sameAddressValue:", sameAddressValue);

			if (
				sameAddressValue !== null &&
				sameAddressValue !== undefined &&
				sameAddressValue?.length > 0
			) {
				sameAddressArr.push(...sameAddressValue, residenceIndex);
			} else {
				sameAddressArr.push(residenceIndex);
			}

			console.log("65 same address arr add:", sameAddressArr);

			dispatch(setSameAsCurrentAddressReducer(sameAddressArr));

			setData(
				data.map((item, index) => {
					if (residenceIndex === index) {
						let updatedItem = {
							id: item.id,
							uRLA2020StreetAddress: data[index - 2]?.uRLA2020StreetAddress,
							addressUnitIdentifier: data[index - 2]?.addressUnitIdentifier,
							addressCity: data[index - 2]?.addressCity,
							addressState: data[index - 2]?.addressState,
							countryCode: data[index - 2]?.countryCode,
							addressPostalCode: data[index - 2]?.addressPostalCode,
							foreignAddressIndicator: data[index - 2]?.foreignAddressIndicator,
							country: data[index - 2]?.country,
							applicantType: data[index - 2]?.applicantType,
							mailingAddressIndicator: true,
							sameAddress: true,
							mailingAddressSameAsPresentIndicator: true,
						};

						return updatedItem;
					} else {
						return item;
					}
				})
			);
		} else {
			setSameAddress(sameAddress?.filter((item) => item !== residenceIndex));

			let sameAddressArr = sameAddressValue?.filter(
				(value) => value !== residenceIndex
			);
			console.log("115 same address arr remove:", sameAddressArr);

			dispatch(setSameAsCurrentAddressReducer(sameAddressArr));

			setData(
				data.map((item, index) => {
					if (residenceIndex === index) {
						let updatedItem = {
							id: item.id,
							uRLA2020StreetAddress: "",
							addressUnitIdentifier: "",
							addressCity: "",
							addressState: "",
							countryCode: "",
							addressPostalCode: "",
							foreignAddressIndicator: "",
							country: "",
							applicantType: data[index - 2]?.applicantType,
							mailingAddressIndicator: true,
							sameAddress: false,
							mailingAddressSameAsPresentIndicator: false,
						};

						return updatedItem;
					} else {
						return item;
					}
				})
			);
		}
	};

	const handleUpdateMail = (fieldKey, fieldValue) => {
		setData(
			data.map((data, index) => {
				if (residenceIndex === index) {
					return { ...data, fieldKey: fieldValue };
				} else {
					return data;
				}
			})
		);
	};

	const handleHousingPayment = (value) => {
		setTotalHousingPayment((prevAmounts) => {
			// Create a new array with the updated value at the specified index
			const newArray = [...prevAmounts];
			newArray[borrowerPairIndex] = Number(value);
			return newArray;
		});
	};

	const headingText = `
  ${
		addressType === "current"
			? "Current "
			: addressType === "prior"
			? "Former "
			: addressType === "mail" && "Mailing "
	}address -${coBorrower ? " Co Borrower" : " Borrower"}`;

	let labelText = `${coBorrower ? "co-borrower-" : "borrower-"}${
		addressType === "current"
			? "current-"
			: addressType === "prior"
			? "former-"
			: addressType === "mail" && "mailing-"
	}`;

	let showOnSubmitError =
		(!coBorrower || (coBorrower && !isEmptyOrUndefined(coBorrowerName))) &&
		error;

	return (
		<Box className={styles.cardContainer}>
			<Stack direction="column" className={styles.cardHeaderContainer}>
				<Heading5 text={headingText} fontType="semibold" />
				<Body3
					text={`Provide details about the ${
						coBorrower ? " Co Borrower" : " Borrower"
					}`}
				/>
			</Stack>
			<Stack direction="column" className={styles.formSectionContainer}>
				{/* basic information */}
				<Stack direction="row" className={styles.inputSectionContainer}>
					{/* same as current address */}
					{addressType === "mail" && (
						<Box
							className={
								styles.inputContainer + " " + styles.fullWidthContainer
							}
						>
							<CustomCheckbox
								checked={
									residenceData?.sameAddress ||
									sameAddress?.includes(residenceIndex) ||
									false
								}
								handleChange={() =>
									handleSameSwitch(!sameAddress?.includes(residenceIndex))
								}
								label="Same as current information"
								disabled={disabled}
							/>
						</Box>
					)}

					<Box
						className={styles.inputContainer + " " + styles.fullWidthContainer}
					>
						<CustomCheckbox
							checked={residenceData?.foreignAddressIndicator || false}
							handleChange={() =>
								setData(
									data.map((data, index) => {
										if (residenceIndex === index) {
											handleUpdateMail(
												"foreignAddressIndicator",
												!residenceData?.foreignAddressIndicator
											);
											return {
												...data,
												foreignAddressIndicator:
													!residenceData?.foreignAddressIndicator,
											};
										} else {
											return data;
										}
									})
								)
							}
							label="Foreign Address"
							disabled={disabled || sameAddress?.includes(residenceIndex)}
						/>
					</Box>

					<Stack direction="row" className={styles.inputSectionContainer}>
						{/* Address Line 1 */}
						<Stack
							direction="column"
							className={
								styles.inputContainer + " " + styles.fullWidthContainer
							}
						>
							<InputLabel htmlFor={`${labelText}outlined-input-address-line`}>
								<Heading6
									text="Address Line 1"
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>

							<OutlinedTextInput
								id={`${labelText}outlined-input-address-line`}
								type="text"
								placeholder="Street Line 1"
								fullWidth={true}
								value={residenceData?.uRLA2020StreetAddress || ""}
								handleChange={(event) =>
									setData(
										data.map((data, index) => {
											if (residenceIndex === index) {
												handleUpdateMail(
													"uRLA2020StreetAddress",
													event.target.value
												);
												return {
													...data,
													uRLA2020StreetAddress: event.target.value,
												};
											} else {
												return data;
											}
										})
									)
								}
								disabled={disabled || sameAddress?.includes(residenceIndex)}
								error={
									showOnSubmitError &&
									isEmptyOrUndefined(residenceData?.uRLA2020StreetAddress)
								}
								showEndIcon={
									showOnSubmitError &&
									isEmptyOrUndefined(residenceData?.uRLA2020StreetAddress)
								}
								endIcon={<ErrorOutlined className={styles.errorIcon} />}
							/>
						</Stack>

						{/* Address Line 2 */}
						<Stack
							direction="column"
							className={
								styles.inputContainer + " " + styles.fullWidthContainer
							}
						>
							<InputLabel htmlFor={`${labelText}outlined-input-address-line-2`}>
								<Heading6
									text="Address Line 2 / Unit Number"
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>

							<OutlinedTextInput
								id={`${labelText}outlined-input-address-line-2`}
								type="text"
								placeholder="Unit Number"
								fullWidth={true}
								value={residenceData?.addressUnitIdentifier || ""}
								handleChange={(event) =>
									setData(
										data.map((data, index) => {
											if (residenceIndex === index) {
												handleUpdateMail(
													"addressUnitIdentifier",
													event.target.value
												);
												return {
													...data,
													addressUnitIdentifier: event.target.value,
												};
											} else {
												return data;
											}
										})
									)
								}
								disabled={disabled || sameAddress?.includes(residenceIndex)}
							/>
						</Stack>

						{/* city */}
						<Stack direction="column" className={styles.inputContainer}>
							<InputLabel htmlFor={`${labelText}outlined-input-city`}>
								<Heading6
									text="City"
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>

							<OutlinedTextInput
								id={`${labelText}outlined-input-city`}
								type="text"
								placeholder="City"
								value={residenceData?.addressCity || ""}
								handleChange={(event) =>
									setData(
										data.map((data, index) => {
											if (residenceIndex === index) {
												handleUpdateMail("addressCity", event.target.value);
												return {
													...data,
													addressCity: event.target.value,
												};
											} else {
												return data;
											}
										})
									)
								}
								disabled={disabled || sameAddress?.includes(residenceIndex)}
								error={
									showOnSubmitError &&
									isEmptyOrUndefined(residenceData?.addressCity)
								}
								showEndIcon={
									showOnSubmitError &&
									isEmptyOrUndefined(residenceData?.addressCity)
								}
								endIcon={<ErrorOutlined className={styles.errorIcon} />}
							/>
						</Stack>

						{/* state */}
						<Stack direction="column" className={styles.inputContainer}>
							<InputLabel htmlFor={`${labelText}select-state`}>
								<Heading6
									text={
										residenceData?.foreignAddressIndicator
											? "Province"
											: "State"
									}
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>

							{residenceData?.foreignAddressIndicator ? (
								<OutlinedTextInput
									id={`${labelText}select-state`}
									type="text"
									placeholder="Province"
									value={residenceData?.addressState || ""}
									handleChange={(event) =>
										setData(
											data.map((data, index) => {
												if (residenceIndex === index) {
													handleUpdateMail("addressState", event.target.value);
													return {
														...data,
														addressState: event.target.value,
													};
												} else {
													return data;
												}
											})
										)
									}
									disabled={disabled || sameAddress?.includes(residenceIndex)}
									error={
										showOnSubmitError &&
										isEmptyOrUndefined(residenceData?.addressState)
									}
									showEndIcon={
										showOnSubmitError &&
										isEmptyOrUndefined(residenceData?.addressState)
									}
									endIcon={<ErrorOutlined className={styles.errorIcon} />}
								/>
							) : (
								<SecondarySelect
									id="select-state"
									displayEmpty={true}
									value={residenceData?.addressState || ""}
									handleChange={(event) =>
										setData(
											data.map((data, index) => {
												if (residenceIndex === index) {
													handleUpdateMail("addressState", event.target.value);
													return {
														...data,
														addressState: event.target.value,
													};
												} else {
													return data;
												}
											})
										)
									}
									options={stateOptions}
									disabled={disabled || sameAddress?.includes(residenceIndex)}
									error={
										showOnSubmitError &&
										isEmptyOrUndefined(residenceData?.addressState)
									}
								/>
							)}
						</Stack>

						{/* postal code */}
						<Stack direction="column" className={styles.inputContainer}>
							<InputLabel htmlFor={`${labelText}outlined-input-postal-code`}>
								<Heading6
									text={
										residenceData?.foreignAddressIndicator
											? "Postal Code"
											: "Zip Code"
									}
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>

							<OutlinedTextInput
								id={`${labelText}outlined-input-postal-code`}
								type="text"
								placeholder={
									residenceData?.foreignAddressIndicator
										? "Postal Code"
										: "Zip Code"
								}
								value={residenceData?.addressPostalCode || ""}
								handleChange={(event) =>
									setData(
										data.map((data, index) => {
											if (residenceIndex === index) {
												handleUpdateMail(
													"addressPostalCode",
													event.target.value
												);
												return {
													...data,
													addressPostalCode: event.target.value,
												};
											} else {
												return data;
											}
										})
									)
								}
								disabled={disabled || sameAddress?.includes(residenceIndex)}
								error={
									showOnSubmitError &&
									isEmptyOrUndefined(residenceData?.addressPostalCode)
								}
								showEndIcon={
									showOnSubmitError &&
									isEmptyOrUndefined(residenceData?.addressPostalCode)
								}
								endIcon={<ErrorOutlined className={styles.errorIcon} />}
							/>
						</Stack>
					</Stack>

					{residenceData?.foreignAddressIndicator && (
						<Stack direction="column" className={styles.inputContainer}>
							<InputLabel htmlFor={`${labelText}select-citizenship-country`}>
								<Heading6
									text="Country"
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>

							<SecondarySelect
								id={`${labelText}select-citizenship-country`}
								displayEmpty={true}
								value={residenceData?.country || ""}
								handleChange={(event) =>
									setData(
										data.map((data, index) => {
											if (residenceIndex === index) {
												handleUpdateMail("country", event.target.value);
												return {
													...data,
													country: event.target.value,
												};
											} else {
												return data;
											}
										})
									)
								}
								options={countryOptions}
								disabled={disabled || sameAddress?.includes(residenceIndex)}
								error={
									showOnSubmitError &&
									(isEmptyOrUndefined(residenceData?.country) ||
										residenceData?.country === "US")
								}
							/>
						</Stack>
					)}
				</Stack>

				<Stack direction="row" className={styles.inputSectionContainer}>
					{addressType !== "mail" && (
						<Stack direction="column" className={styles.inputContainer}>
							<CustomRadio
								id="radio-housing-expense"
								value={residenceData?.residencyBasisType || false}
								handleChange={(event) =>
									setData(
										data.map((data, index) => {
											if (residenceIndex === index) {
												// set total housing to 0 when housing expense type is changed
												handleHousingPayment(0);
												if (
													residenceData?.residencyBasisType ===
													event.target.value
												) {
													return {
														...data,
														residencyBasisType: "",
														// set rent to 0 when housing expense type is changed
														rent: "",
													};
												} else {
													return {
														...data,
														residencyBasisType: event.target.value,
														// set rent to 0 when housing expense type is changed
														rent: "",
													};
												}
											} else {
												return data;
											}
										})
									)
								}
								label="Housing Expenses:"
								disabled={disabled}
								data={housingExpenseOptions}
								error={
									showOnSubmitError &&
									isEmptyOrUndefined(residenceData?.residencyBasisType)
								}
							/>
						</Stack>
					)}

					{addressType !== "mail" &&
					residenceData?.residencyBasisType === "Own" ? (
						<Stack
							direction="row"
							alignItems="center"
							className={styles.inputContainer}
						>
							<Body2
								text="Current Total Housing Payment:"
								extraClass={styles.monthlyRentText}
							/>

							<MaskedInput
								id="outlined-input-housing-payment"
								type="currency"
								placeholder="$"
								value={
									totalHousingPayment !== undefined &&
									totalHousingPayment?.length > 0
										? totalHousingPayment[borrowerPairIndex]?.toString()
										: ""
								}
								onInputChange={handleHousingPayment}
								disabled={disabled}
							/>
						</Stack>
					) : (
						residenceData?.residencyBasisType === "Rent" && (
							<Stack
								direction="row"
								alignItems="center"
								className={styles.inputContainer}
							>
								<Body2
									text="Monthly Rent:"
									extraClass={styles.monthlyRentText}
								/>

								<MaskedInput
									id="outlined-input-rental-income"
									type="currency"
									placeholder="Rent ($ / mo.)"
									value={residenceData?.rent?.toString() || ""}
									onInputChange={handleRentAmount}
									disabled={disabled}
									error={
										showOnSubmitError && isEmptyOrUndefined(residenceData?.rent)
									}
									showEndIcon={
										showOnSubmitError && isEmptyOrUndefined(residenceData?.rent)
									}
									endIcon={<ErrorOutlined className={styles.errorIcon} />}
								/>
							</Stack>
						)
					)}
				</Stack>

				{addressType !== "mail" && (
					<Stack direction="row" className={styles.durationContainer}>
						<Body2
							text="Living here for:"
							extraClass={styles.livingDurationHeader}
						/>

						<Stack direction="row" className={styles.durationContainer}>
							{/* duration years */}
							<Stack direction="column" className={styles.inputContainer}>
								<InputLabel
									htmlFor={`${labelText}outlined-input-duration-years`}
								>
									<Heading6
										text="Years"
										fontType="semibold"
										extraClass={styles.inputHeader}
									/>
								</InputLabel>

								<OutlinedTextInput
									id={`${labelText}outlined-input-duration-years`}
									placeholder="Years"
									type="number"
									inputProps={{ min: 0 }}
									fullWidth={true}
									value={residenceData?.durationTermYears || ""}
									handleChange={(event) =>
										setData(
											data.map((data, index) => {
												if (residenceIndex === index) {
													return {
														...data,
														durationTermYears: Math.max(
															0,
															Math.min(100, Number(event.target.value))
														),
													};
												} else {
													return data;
												}
											})
										)
									}
									disabled={disabled}
									error={
										showOnSubmitError &&
										(isEmptyOrUndefined(residenceData?.durationTermYears) ||
											(Number(residenceData?.durationTermYears) === 0 &&
												Number(residenceData?.durationTermMonths) === 0))
									}
									showEndIcon={
										showOnSubmitError &&
										(isEmptyOrUndefined(residenceData?.durationTermYears) ||
											(Number(residenceData?.durationTermYears) === 0 &&
												Number(residenceData?.durationTermMonths) === 0))
									}
									endIcon={<ErrorOutlined className={styles.errorIcon} />}
								/>
							</Stack>

							{/* duration months */}
							<Stack direction="column" className={styles.inputContainer}>
								<InputLabel
									htmlFor={`${labelText}outlined-input-duration-months`}
								>
									<Heading6
										text="Months"
										fontType="semibold"
										extraClass={styles.inputHeader}
									/>
								</InputLabel>

								<OutlinedTextInput
									id={`${labelText}outlined-input-duration-months`}
									placeholder="Months"
									type="number"
									min={0}
									max={11}
									maxLength={2}
									fullWidth={true}
									value={residenceData?.durationTermMonths || ""}
									handleChange={(event) =>
										handleChangeTermMonths(event.target.value)
									}
									disabled={disabled}
									error={
										showOnSubmitError &&
										Number(residenceData?.durationTermYears) === 0 &&
										(isEmptyOrUndefined(residenceData?.durationTermMonths) ||
											Number(residenceData?.durationTermMonths) === 0)
									}
									showEndIcon={
										showOnSubmitError &&
										Number(residenceData?.durationTermYears) === 0 &&
										(isEmptyOrUndefined(residenceData?.durationTermMonths) ||
											Number(residenceData?.durationTermMonths) === 0)
									}
									endIcon={<ErrorOutlined className={styles.errorIcon} />}
								/>
							</Stack>
						</Stack>
					</Stack>
				)}

				{/* Show credit reissue when last contact information component renders */}
				{(residenceIndex === 5 ||
					residenceIndex === 11 ||
					residenceIndex === 17) && (
					<>
						{/* Credit Reissue selection */}
						<Stack direction="column" className={styles.inputContainer}>
							<CustomDivider />
							<InputLabel htmlFor={`outlined-input-credit-reissue`}>
								<Heading6
									text="How will Credit be Provided?"
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>

							<SecondarySelect
								id={`outlined-input-credit-reissue`}
								placeholder="Select"
								displayEmpty={true}
								value={creditReissueData || ""}
								handleChange={(event) =>
									setCreditReissueData(event.target.value)
								}
								options={creditReissueOptions}
								disabled={disabled}
								error={
									error &&
									isEmptyOrUndefined(creditReissueData)
								}
							/>
						</Stack>
					</>
				)}
			</Stack>

			{!lastResidence && <CustomDivider />}
		</Box>
	);
};

export default ContactInformation;
