import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DateTime } from "luxon";

// mui components
import { Box, IconButton, Stack, Tab, Tabs } from "@mui/material";

// custom components
import { TabPanel } from "../../tab-panel";
import RequiredError from "../../required-error";
import BorrowerInformation from "../../loan-form-card/borrower-information";
import PropertyDetails from "../../loan-form-card/property-details";
import LoanOriginator from "../../loan-form-card/loan-originator";
import LoanProcessor from "../../loan-form-card/loan-processor";
import PrimaryButton from "../../buttons/primary-button";
import Heading6 from "../../typography/heading-06";

// mui icons
import {
	AddOutlined,
	ArrowForwardOutlined,
	CloseOutlined,
} from "@mui/icons-material";

// api slice
import { useCreateLoanMutation } from "../../../features/create-loan/createLoanApiSlice";
import { useCreateAdminLoanMutation } from "../../../features/admin/create-loan/createAdminLoanApiSlice";
import { useUpdateFieldsMutation } from "../../../features/update-fields/updateFieldsApiSlice";
import { useGetBrokerListByTitleMutation } from "../../../features/broker-search-title/brokerSearchTitleApiSlice";
import { useGetOrganizationDetailsMutation } from "../../../features/organization-detail/organizationDetailApiSlice";
import { useUpdateLoanMutation } from "../../../features/update-loan/updateLoanApiSlice";
import { useCreateBorrowerPairMutation } from "../../../features/create-borrower-pair/createBorrowerPairApiSlice";
import { useUpdateBorrowerPairMutation } from "../../../features/update-borrower-pair/updateBorrowerPairApiSlice";

// reducer slice
import {
	selectUserDetails,
	setLoEmail,
} from "../../../features/auth/authSlice";
import { setBrokerListReducer } from "../../../features/broker-list/brokerListSlice";

// custom modal
import LoaderModal from "../../modal/loader-modal";
import HelpTextModal from "../../modal/help-text-modal";

// helper utils
import { requiredFields } from "../../../utils/requiredFields";
import { handleSetPropertyDetails } from "../../../utils/loan-submission/set-property-details";

// custom styles
import styles from "./index.module.scss";

export const CreateLoanApplication = ({
	setLoanApplicationStage,
	applicationData,
	setApplicationData,
	setLoanGuid,
	organizationName,
	currentStage,
}) => {
	console.log("24 applicationData create:", applicationData);

	const dispatch = useDispatch();
	const userDetails = useSelector(selectUserDetails);

	const [createLoan, { isLoading: creatingLoan }] = useCreateLoanMutation();

	const [createAdminLoan, { isLoading: creatingAdminLoan }] =
		useCreateAdminLoanMutation();

	const [updateFields, { isLoading: isUpdatingFields }] =
		useUpdateFieldsMutation();

	const [updateLoan, { isLoading: updatingLoan }] = useUpdateLoanMutation();

	const [createBorrowerPair, { isLoading: creatingBorrowerPair }] =
		useCreateBorrowerPairMutation();

	const [updateBorrowerPair, { isLoading: updatingBorrowerPair }] =
		useUpdateBorrowerPairMutation();

	const [loanApplicationData, setLoanApplicationData] = useState([
		{
			mailingAddressSameAsPresentIndicator: false,
		},
		{
			mailingAddressSameAsPresentIndicator: false,
		},
	]);

	const [borrowerDataExist, setBorrowerDataExist] = useState(false);

	const [foreignNationalIndex, setForeignNationalIndex] = useState([]);

	const [tabValue, setTabValue] = useState(0);

	const [propertyInfo, setPropertyInfo] = useState({});
	const [grossRent, setGrossRent] = useState("");

	const [homeownersInsurance, setHomeownersInsurance] = useState("");
	const [floodInsurance, setFloodInsurance] = useState("");
	const [propertyTaxes, setPropertyTaxes] = useState("");
	const [realEstateTaxes, setRealEstateTaxes] = useState("");
	const [hoaDues, setHoaDues] = useState("");

	const [getBrokerListByTitle, { isLoading: brokerListLoading }] =
		useGetBrokerListByTitleMutation();

	const [getOrganizationDetails, { isLoading: organizationDetailsLoading }] =
		useGetOrganizationDetailsMutation();
	const [organizationDetails, setOrganizationDetails] = useState({});

	const [loanOriginatorEmail, setLoanOriginatorEmail] = useState("");
	const [organizationUsers, setOrganizationUsers] = useState([]);
	const [loanOriginator, setLoanOriginator] = useState({});

	const [organizationLicense, setOrganizationLicense] = useState("");

	const [loanProcessorEmail, setLoanProcessorEmail] = useState("");
	const [organizationProcessor, setOrganizationProcessor] = useState([]);
	const [loanProcessor, setLoanProcessor] = useState({});

	const [helpText, setHelpText] = useState("");
	const [helpTextOpen, setHelpTextOpen] = useState(false);

	const handleOpenHelpText = () => {
		setHelpTextOpen(true);
	};

	const handleCloseHelpText = () => {
		setHelpTextOpen(false);
	};

	useEffect(() => {
		console.log("125 application fee data:", applicationData);

		if (applicationData !== undefined) {
			setGrossRent(applicationData?.subjectPropertyGrossRentalIncomeAmount);

			handleSetPropertyDetails(
				applicationData,
				setHomeownersInsurance,
				setFloodInsurance,
				setPropertyTaxes,
				setRealEstateTaxes,
				setHoaDues
			);
		}
	}, [applicationData]);

	useEffect(() => {
		const getBrokerListFunc = async () => {
			const searchResults = await getBrokerListByTitle({
				orgName: organizationName,
				title: "Loan Officer",
			}).unwrap();
			dispatch(setBrokerListReducer(searchResults));

			console.log("150 loan origin:", searchResults);

			let updatedArr = searchResults.map((item) => ({
				...item,
				label: item?.user?.email || "",
				value: item?.user?.email || "",
			}));

			console.log("150 loan originator updated:", updatedArr);
			setOrganizationUsers(updatedArr);
		};

		const getProcessorListFunc = async () => {
			const searchResults = await getBrokerListByTitle({
				orgName: organizationName,
				title: "Loan Processor",
			}).unwrap();

			let updatedArr = searchResults.map((item) => ({
				...item,
				label: item?.user?.email || "",
				value: item?.user?.email || "",
			}));

			console.log("150 loan processor updated:", updatedArr);
			setOrganizationProcessor(updatedArr);
		};

		const getOrganizationDetailsFunc = async () => {
			const searchResults = await getOrganizationDetails({
				organizationName,
			}).unwrap();
			setOrganizationDetails(searchResults);
		};

		if (
			organizationName !== null &&
			organizationName !== undefined &&
			organizationName !== ""
		) {
			getBrokerListFunc();
			getProcessorListFunc();
			getOrganizationDetailsFunc();
		}
	}, [organizationName]);

	useEffect(() => {
		if (
			Object.keys(loanOriginatorEmail)?.length !== 0 ||
			loanOriginatorEmail !== ""
		) {
			const originatorDetails = organizationUsers?.find(
				(item) => item?.user?.email === loanOriginatorEmail
			);

			setLoanOriginator(originatorDetails);
		}
	}, [loanOriginatorEmail]);

	useEffect(() => {
		if (
			Object.keys(loanProcessorEmail)?.length !== 0 ||
			loanProcessorEmail !== ""
		) {
			const processorDetails = organizationProcessor?.find(
				(item) => item?.user?.email === loanProcessorEmail
			);
			setLoanProcessor(processorDetails);
		}
	}, [loanProcessorEmail]);

	const [error, setError] = useState("");

	// useEffect(() => {
	// 	// when user comes back from next screen
	// 	console.log("210 loan guid:", loanGuid);
	// 	console.log("210 applicationData:", applicationData);
	// 	if (
	// 		loanGuid !== undefined &&
	// 		loanGuid !== "" &&
	// 		applicationData !== undefined &&
	// 		applicationData?.applications !== undefined &&
	// 		applicationData?.applications?.length >= 0
	// 	) {
	// 		console.log(
	// 			"66 applicationData?.applications:",
	// 			applicationData?.applications
	// 		);

	// 		const borrowerPairs = applicationData?.applications
	// 			?.flat()
	// 			?.reduce((acc, curr) => {
	// 				acc.push(curr?.borrower);
	// 				acc.push(curr?.coborrower || curr?.coBorrower);
	// 				return acc;
	// 			}, []);

	// 		console.log("225 borrower pair:", borrowerPairs);

	// 		setLoanApplicationData(borrowerPairs);
	// 		setBorrowerDataExist(true);

	// 		setLoanOriginatorEmail(applicationData?.tPO?.lOEmail || "");
	// 		setLoanProcessorEmail(applicationData?.tPO?.lPEmail || "");

	// 		let data = applicationData?.applications[0];
	// 		console.log("68 data:", data);

	// 		setPropertyInfo(applicationData?.property);
	// 	} else {
	// 		setBorrowerDataExist(false);
	// 	}
	// }, [applicationData]);

	const updateField = async (loanGuid, apiObj) => {
		let valuesArr = [
			{ id: "TPO.X3", value: DateTime.now().toFormat("MM-dd-yyyy") },
			{ id: "3629", value: organizationLicense },
			{
				id: "CX.PROPERTY.COMMERCIAL.UNITS",
				value: apiObj?.property?.financedNumberOfUnits || "",
			},
		];

		console.log("300 foreign national:", foreignNationalIndex);

		// Loop through the range of indices (0 to 5) for 3 borrower pairs
		for (let index = 0; index < 6; index++) {
			// Create the id based on the index
			const id = `CX.FOREIGNNATLBORR${index + 1}`;

			// Check if the current index is in arrayIndex
			const value = foreignNationalIndex.includes(index) ? "Yes" : "No";

			// Push the object to valuesArr
			valuesArr.push({ id, value });
		}

		if (loanOriginatorEmail !== undefined && loanOriginatorEmail !== "") {
			valuesArr.push({
				id: "TPO.X61",
				value:
					loanOriginator?.user?.first_name +
					" " +
					loanOriginator?.user?.last_name,
			});

			valuesArr.push({
				id: "TPO.X63",
				value: loanOriginator?.user?.email,
			});

			valuesArr.push({
				id: "TPO.X65",
				value: loanOriginator?.phone,
			});

			valuesArr.push({
				id: "TPO.X67",
				value: loanOriginator?.cell_phone,
			});

			valuesArr.push({
				id: "2306",
				value: loanOriginator?.state_license || "",
			});
		}

		console.log("360 loanProcessor email:", loanProcessorEmail);
		console.log("360 loanProcessor:", loanProcessor);

		if (loanProcessorEmail !== undefined && loanProcessorEmail !== "") {
			valuesArr.push({
				id: "TPO.X74",
				value:
					loanProcessor?.user?.first_name +
					" " +
					loanProcessor?.user?.last_name,
			});

			valuesArr.push({
				id: "TPO.X76",
				value: loanProcessor?.user?.email,
			});

			valuesArr.push({
				id: "TPO.X78",
				value: loanProcessor?.phone,
			});

			valuesArr.push({
				id: "TPO.X80",
				value: loanProcessor?.cell_phone,
			});
		}

		console.log("340 loan update values:", valuesArr);

		const updateFieldValues = await updateFields({
			loan_guid: loanGuid,
			field_values: valuesArr,
		}).unwrap();
		console.log("90 file res:", updateFieldValues);

		if (updateFieldValues?.response === "Field values updated successfully.") {
			setError("");
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: "smooth",
			});
			setLoanApplicationStage(currentStage + 1);
		} else {
			setError(updateFieldValues?.response);
		}
	};

	const handleNext = async () => {
		setError("");

		if (
			!requiredFields(
				loanApplicationData,
				propertyInfo,
				grossRent,
				homeownersInsurance,
				propertyTaxes,
				loanOriginatorEmail,
				loanProcessorEmail,
				setError
			)
		) {
			return;
		}

		let borrowerPairData = [];

		console.log("445 loan applicationData:", loanApplicationData);

		// for (let index = 2; index < loanApplicationData.length; index++) {
		// 	if (index % 2 === 0) {
		// 		if (
		// 			loanApplicationData[index]?.urla2020CitizenshipResidencyType ===
		// 			"Foreign National"
		// 		) {
		// 			loanApplicationData[index].urla2020CitizenshipResidencyType =
		// 				"NonPermanentResidentAlien";
		// 		}

		// 		if (
		// 			loanApplicationData[index + 1]?.urla2020CitizenshipResidencyType ===
		// 			"Foreign National"
		// 		) {
		// 			loanApplicationData[index + 1].urla2020CitizenshipResidencyType =
		// 				"NonPermanentResidentAlien";
		// 		}

		// 	}
		// }

		let applications = [];

		for (let index = 0; index < loanApplicationData.length; index += 2) {
			if (
				loanApplicationData[index]?.urla2020CitizenshipResidencyType ===
				"Foreign National"
			) {
				loanApplicationData[index].urla2020CitizenshipResidencyType =
					"NonPermanentResidentAlien";
			}

			if (
				loanApplicationData[index + 1]?.urla2020CitizenshipResidencyType ===
				"Foreign National"
			) {
				loanApplicationData[index + 1].urla2020CitizenshipResidencyType =
					"NonPermanentResidentAlien";
			}

			applications.push([
				{
					borrower: loanApplicationData[index],
					coBorrower: loanApplicationData[index + 1],
				},
			]);

			borrowerPairData.push([
				{
					borrower: loanApplicationData[index],
					coborrower: loanApplicationData[index + 1],
				},
			]);
		}

		console.log("430 applicationData:", applicationData);
		console.log("430 borrower pair data;", borrowerPairData);

		// Update the monthly payment for the specified fee type
		let updatedFees = [];

		if (
			applicationData?.fees === undefined ||
			applicationData?.fees?.length === 0
		) {
			updatedFees.push({
				feeType: "FloodInsurance",
				monthlyPayment: floodInsurance,
			});
			updatedFees.push({
				feeType: "PropertyTaxes",
				monthlyPayment: propertyTaxes,
			});
		} else {
			applicationData?.fees?.map((fee) => {
				if (fee.feeType === "FloodInsurance") {
					updatedFees.push({ ...fee, monthlyPayment: floodInsurance });
				}

				if (fee.feeType === "PropertyTaxes") {
					updatedFees.push({ ...fee, monthlyPayment: propertyTaxes });
				}

				return fee;
			});
		}

		console.log("450 updated fees:", updatedFees);

		setApplicationData({
			...applicationData,
			subjectPropertyGrossRentalIncomeAmount: grossRent,
			proposedHazardInsuranceAmount: homeownersInsurance,
			proposedDuesAmount: hoaDues,
			fees: updatedFees,
			proposedRealEstateTaxesAmount:
				updatedFees?.find((item) => item?.feeType === "PropertyTaxes")
					?.monthlyPayment || "0",
			applications,
			property: propertyInfo,
			tPO: {
				lOEmail: loanOriginatorEmail,
				lPEmail: loanProcessorEmail,
			},
		});

		// console.log("305 loan guid:", loanGuid);

		// if (loanGuid === undefined || loanGuid === "") {
		// let apiObj;
		// if (loanOriginatorEmail !== undefined && loanOriginatorEmail !== "") {
		let apiObj = {
			user_email: loanOriginatorEmail,
			loan_data: {
				...applicationData,
				subjectPropertyGrossRentalIncomeAmount: grossRent,
				proposedHazardInsuranceAmount: homeownersInsurance,
				proposedDuesAmount: hoaDues,
				fees: updatedFees,
				proposedRealEstateTaxesAmount:
					updatedFees?.find((item) => item?.feeType === "PropertyTaxes")
						?.monthlyPayment || "0",
				applications: applications[0],
				property: propertyInfo,
			},
		};
		// } else {
		// 	if (
		// 		impersonatedUser !== null &&
		// 		impersonatedUser !== undefined &&
		// 		impersonatedUser !== ""
		// 	) {
		// 		apiObj = {
		// 			user_email: impersonatedUser,
		// 			loan_data: {
		// 				...applicationData,
		// 				subjectPropertyGrossRentalIncomeAmount: grossRent,
		// 				proposedHazardInsuranceAmount: homeownersInsurance,
		// 				proposedDuesAmount: hoaDues,
		// 				fees: updatedFees,
		// 				supplementalPropertyInsuranceAmount:
		// 					updatedFees?.find((item) => item?.feeType === "FloodInsurance")
		// 						?.monthlyPayment || "0",
		// 				proposedRealEstateTaxesAmount:
		// 					updatedFees?.find((item) => item?.feeType === "PropertyTaxes")
		// 						?.monthlyPayment || "0",
		// 				applications: applications[0],
		// 				property: propertyInfo,
		// 			},
		// 		};
		// 	} else {
		// 		apiObj = {
		// 			...applicationData,
		// 			subjectPropertyGrossRentalIncomeAmount: grossRent,
		// 			proposedHazardInsuranceAmount: homeownersInsurance,
		// 			proposedDuesAmount: hoaDues,
		// 			fees: updatedFees,
		// 			supplementalPropertyInsuranceAmount:
		// 				updatedFees?.find((item) => item?.feeType === "FloodInsurance")
		// 					?.monthlyPayment || "0",
		// 			proposedRealEstateTaxesAmount:
		// 				updatedFees?.find((item) => item?.feeType === "PropertyTaxes")
		// 					?.monthlyPayment || "0",
		// 			applications: applications[0],
		// 			property: propertyInfo,
		// 		};
		// 	}
		// }

		console.log("265 api obj:", apiObj);

		// dispatch API and open modal if successful
		try {
			console.log("550 is user details:", userDetails);
			const isAdmin = userDetails?.user_roles?.toLowerCase() === "admin";
			console.log("550 is admin:", isAdmin);

			// if (loanOriginatorEmail !== undefined && loanOriginatorEmail !== "") {
			let fileUpload;

			if (isAdmin) {
				fileUpload = await createAdminLoan({ ...apiObj }).unwrap();
			} else {
				fileUpload = await createLoan({ ...apiObj?.loan_data }).unwrap();
			}

			console.log("90 create impersonated loan:", fileUpload);
			console.log("385 loan originator email:", loanOriginatorEmail);
			dispatch(setLoEmail(loanOriginatorEmail));
			// dispatch(createAdminLoanReducer(fileUpload));
			setLoanGuid(fileUpload?.GUID);

			// create additional borrower pair
			if (loanApplicationData?.length >= 3) {
				let borrowerPair;

				console.log("575 borrower pair data:", borrowerPairData);

				// start index at 1 to skip the first pair which was used to create the loan
				for (let index = 1; index < borrowerPairData.length; index++) {
					borrowerPair = await createBorrowerPair({
						loanGuid: fileUpload?.GUID,
						data: { ...borrowerPairData[index][0] },
					}).unwrap();
				}
				console.log("550 impersonated borrower pair data:", borrowerPair);
			}

			updateField(fileUpload?.GUID, apiObj?.loan_data);

			// }
			//  else {
			// 	if (
			// 		impersonatedUser !== null &&
			// 		impersonatedUser !== undefined &&
			// 		impersonatedUser !== ""
			// 	) {
			// 		const fileUpload = await createAdminLoan({ ...apiObj }).unwrap();
			// 		console.log("90 create admin loan:", fileUpload);
			// 		dispatch(setLoEmail(impersonatedUser));
			// 		dispatch(createAdminLoanReducer(fileUpload));
			// 		setLoanGuid(fileUpload?.GUID);
			// 		// create borrower pair
			// 		if (loanApplicationData?.length >= 3) {
			// 			let borrowerPair;
			// 			for (let index = 0; index < borrowerPairData.length; index++) {
			// 				borrowerPair = await createBorrowerPair({
			// 					loanGuid: fileUpload?.GUID,
			// 					data: { ...borrowerPairData[index][0] },
			// 				}).unwrap();
			// 			}
			// 			console.log("550 admin borrower pair data:", borrowerPair);
			// 		}

			// 		updateField(fileUpload?.GUID, apiObj?.loan_data);
			// 	} else {
			// 		const fileUpload = await createLoan({ ...apiObj }).unwrap();
			// 		console.log("90 create broker loan:", fileUpload);
			// 		dispatch(setLoEmail(""));
			// 		dispatch(createLoanReducer(fileUpload));
			// 		setLoanGuid(fileUpload?.GUID);

			// 		// create borrower pair
			// 		if (loanApplicationData?.length >= 3) {
			// 			console.log(
			// 				"400 loanApplicationData length:",
			// 				loanApplicationData?.length
			// 			);
			// 			let borrowerPair;
			// 			for (let index = 0; index < borrowerPairData.length; index++) {
			// 				borrowerPair = await createBorrowerPair({
			// 					loanGuid: fileUpload?.GUID,
			// 					data: { ...borrowerPairData[index][0] },
			// 				}).unwrap();
			// 			}
			// 			console.log("550 broker borrower pair data:", borrowerPair);
			// 		}

			// 		updateField(fileUpload?.GUID, apiObj);
			// 	}
			// }
		} catch (err) {
			console.log("550 err:", err);
			setError(err?.data?.details);
		}
		// } else {
		// 	// update loan api
		// 	console.log("510 loan id:", loanGuid);

		// 	console.log("600 application data:", applicationData);
		// 	console.log("600 applications:", applications);

		// 	let apiObj = {
		// 		...applicationData,
		// 		subjectPropertyGrossRentalIncomeAmount: grossRent,
		// 		proposedHazardInsuranceAmount: homeownersInsurance,
		// 		proposedDuesAmount: hoaDues,
		// 		fees: updatedFees,
		// 		supplementalPropertyInsuranceAmount:
		// 			updatedFees?.find((item) => item?.feeType === "FloodInsurance")
		// 				?.monthlyPayment || "0",
		// 		proposedRealEstateTaxesAmount:
		// 			updatedFees?.find((item) => item?.feeType === "PropertyTaxes")
		// 				?.monthlyPayment || "0",
		// 		applications,
		// 		property: propertyInfo,
		// 	};

		// 	console.log("630 api obj:", apiObj);

		// 	const fileUpload = await updateLoan({
		// 		loanGuid,
		// 		loanData: apiObj,
		// 	}).unwrap();

		// 	console.log("450 loan update:", fileUpload);

		// 	// create borrower pair
		// 	if (loanApplicationData?.length >= 3) {
		// 		let borrowerPair;
		// 		for (let index = 0; index < borrowerPairData.length; index++) {
		// 			borrowerPair = await updateBorrowerPair({
		// 				loanGuid: fileUpload?.GUID,
		// 				data: { ...borrowerPairData[index][0] },
		// 			}).unwrap();
		// 		}
		// 		console.log("550 update borrower pair data:", borrowerPair);
		// 	}

		// 	if (
		// 		fileUpload?.response?.errorCode === undefined ||
		// 		fileUpload?.response?.errorCode === ""
		// 	) {
		// 		window.scrollTo({
		// 			top: 0,
		// 			left: 0,
		// 			behavior: "smooth",
		// 		});
		// 		setLoanApplicationStage(currentStage + 1);
		// 	} else {
		// 		setError(fileUpload?.response?.details);
		// 	}
		// }
	};

	// const handleNextSectionClick = () => {
	// 	window.scrollTo({
	// 		top: 0,
	// 		left: 0,
	// 		behavior: "smooth",
	// 	});
	// 	setLoanApplicationStage(currentStage + 1);
	// };

	const handleAddBorrowerPair = () => {
		setLoanApplicationData([
			...loanApplicationData,
			{ mailingAddressSameAsPresentIndicator: false },
			{ mailingAddressSameAsPresentIndicator: false },
		]);
	};

	const handleRemoveBorrowerPair = () => {
		console.log("650 tab value:", tabValue);
		console.log("650 pair count:", loanApplicationData?.length / 2 - 1);

		if (tabValue === loanApplicationData?.length / 2 - 1) {
			setTabValue(tabValue - 1);
		}

		let newArr = loanApplicationData;

		// remove current selected borrower pair with following logic
		// tabvalue ~ loanApplicationData
		// 0 ~ 0, 1
		// 1 ~ 2, 3
		// 2 ~ 4, 5

		if (tabValue === 0) {
			newArr?.splice(tabValue, 2);
		} else {
			newArr?.splice(tabValue * 2, 2);
		}

		setLoanApplicationData([...newArr]);
	};

	function a11yProps(index) {
		return {
			id: `simple-tab-${index}`,
			"aria-controls": `simple-tabpanel-${index}`,
		};
	}

	const handleChangeTab = (event, newValue) => {
		setTabValue(newValue);
	};

	return (
		<Stack direction="column" className={styles.stackContainer}>
			<Stack className={styles.bodyContainer}>
				{/* tabs section */}
				<Tabs
					value={tabValue}
					onChange={handleChangeTab}
					aria-label="basic tabs example"
					TabIndicatorProps={{
						style: {
							backgroundColor: "#76D99E",
						},
					}}
				>
					<Tab
						className={styles.tabBtnContainer}
						label={
							<Stack direction="row" className={styles.tabLabelContainer}>
								<Heading6
									text="Borrower Pair 1"
									fontType="semibold"
									extraClass={
										tabValue === 0 ? styles.activeTabText : styles.tabText
									}
								/>

								{tabValue === 0 && (
									<Stack
										className={styles.closeIconContainer}
										onClick={() =>
											loanApplicationData?.length > 2 &&
											// borrowerDataExist === false &&
											handleRemoveBorrowerPair()
										}
									>
										<CloseOutlined className={styles.closeIcon} />
									</Stack>
								)}
							</Stack>
						}
						{...a11yProps(0)}
					/>
					{loanApplicationData?.length > 2 && (
						<Tab
							className={styles.tabBtnContainer}
							label={
								<Stack direction="row" className={styles.tabLabelContainer}>
									<Heading6
										text="Borrower Pair 2"
										fontType="semibold"
										extraClass={
											tabValue === 1 ? styles.activeTabText : styles.tabText
										}
									/>

									{tabValue === 1 && (
										<Stack
											className={styles.closeIconContainer}
											onClick={() =>
												// borrowerDataExist === false &&
												handleRemoveBorrowerPair()
											}
										>
											<CloseOutlined className={styles.closeIcon} />
										</Stack>
									)}
								</Stack>
							}
							{...a11yProps(1)}
						/>
					)}
					{loanApplicationData?.length > 4 && (
						<Tab
							className={styles.tabBtnContainer}
							label={
								<Stack direction="row" className={styles.tabLabelContainer}>
									<Heading6
										text="Borrower Pair 3"
										fontType="semibold"
										extraClass={
											tabValue === 2 ? styles.activeTabText : styles.tabText
										}
									/>

									{tabValue === 2 && (
										<Stack
											className={styles.closeIconContainer}
											onClick={() =>
												// borrowerDataExist === false &&
												handleRemoveBorrowerPair()
											}
										>
											<CloseOutlined className={styles.closeIcon} />
										</Stack>
									)}
								</Stack>
							}
							{...a11yProps(2)}
						/>
					)}

					<Box>
						<IconButton
							className={styles.addPairContainer}
							onClick={() =>
								loanApplicationData?.length < 6 &&
								// borrowerDataExist === false &&
								handleAddBorrowerPair()
							}
							disabled={
								loanApplicationData?.length > 4
								// && borrowerDataExist === false
							}
						>
							<AddOutlined className={styles.addPairIcon} />
						</IconButton>
					</Box>
				</Tabs>

				{/* borrower pair 1 */}
				{loanApplicationData?.length > 0 && (
					<TabPanel
						value={tabValue}
						index={0}
						className={tabValue === 0 ? styles.tabPanelContainer : ""}
					>
						{loanApplicationData?.slice(0, 2)?.map((borrower, index) => (
							<BorrowerInformation
								key={index}
								borrowerIndex={index}
								coBorrower={index % 2 !== 0}
								borrowerData={borrower}
								foreignNationalIndex={foreignNationalIndex}
								setForeignNationalIndex={setForeignNationalIndex}
								data={loanApplicationData}
								setData={setLoanApplicationData}
								error={error.includes("provide a value")}
								// disabled={borrowerDataExist}
							/>
						))}

						<PropertyDetails
							data={propertyInfo}
							setData={setPropertyInfo}
							loanData={applicationData}
							setLoanData={setApplicationData}
							error={error.includes("provide a value")}
							// disabled={borrowerDataExist}
							grossRent={grossRent}
							setGrossRent={setGrossRent}
							homeownersInsurance={homeownersInsurance}
							setHomeownersInsurance={setHomeownersInsurance}
							floodInsurance={floodInsurance}
							setFloodInsurance={setFloodInsurance}
							propertyTaxes={propertyTaxes}
							setPropertyTaxes={setPropertyTaxes}
							realEstateTaxes={realEstateTaxes}
							setRealEstateTaxes={setRealEstateTaxes}
							hoaDues={hoaDues}
							setHoaDues={setHoaDues}
							setHelpText={setHelpText}
							handleOpenHelpText={handleOpenHelpText}
						/>

						<LoanOriginator
							organizationName={organizationName}
							organizationDetails={organizationDetails}
							loanOriginatorEmail={loanOriginatorEmail}
							setLoanOriginatorEmail={setLoanOriginatorEmail}
							organizationUsers={organizationUsers}
							loanOriginator={loanOriginator}
							setLoanOriginator={setLoanOriginator}
							setOrganizationLicense={setOrganizationLicense}
							propertyState={propertyInfo?.state}
							error={error.includes("provide a value")}
							// disabled={borrowerDataExist}
						/>

						<LoanProcessor
							organizationName={organizationName}
							organizationDetails={organizationDetails}
							loanProcessorEmail={loanProcessorEmail}
							setLoanProcessorEmail={setLoanProcessorEmail}
							organizationProcessor={organizationProcessor}
							loanProcessor={loanProcessor}
							propertyState={propertyInfo?.state}
							error={error.includes("provide a value")}
							// disabled={borrowerDataExist}
						/>
					</TabPanel>
				)}

				{/* borrower pair 2 */}
				{loanApplicationData?.length > 2 && (
					<TabPanel
						value={tabValue}
						index={1}
						className={tabValue === 1 ? styles.tabPanelContainer : ""}
					>
						{loanApplicationData?.slice(2, 4)?.map((borrower, index) => (
							<BorrowerInformation
								key={index}
								borrowerIndex={index + 2}
								coBorrower={index % 2 !== 0}
								borrowerData={borrower}
								foreignNationalIndex={foreignNationalIndex}
								setForeignNationalIndex={setForeignNationalIndex}
								data={loanApplicationData}
								setData={setLoanApplicationData}
								error={error.includes("provide a value")}
								// disabled={borrowerDataExist}
							/>
						))}

						<PropertyDetails
							data={propertyInfo}
							setData={setPropertyInfo}
							loanData={applicationData}
							setLoanData={setApplicationData}
							error={error.includes("provide a value")}
							// disabled={borrowerDataExist}
							grossRent={grossRent}
							setGrossRent={setGrossRent}
							homeownersInsurance={homeownersInsurance}
							setHomeownersInsurance={setHomeownersInsurance}
							floodInsurance={floodInsurance}
							setFloodInsurance={setFloodInsurance}
							propertyTaxes={propertyTaxes}
							setPropertyTaxes={setPropertyTaxes}
							realEstateTaxes={realEstateTaxes}
							setRealEstateTaxes={setRealEstateTaxes}
							hoaDues={hoaDues}
							setHoaDues={setHoaDues}
							setHelpText={setHelpText}
							handleOpenHelpText={handleOpenHelpText}
						/>

						<LoanOriginator
							organizationName={organizationName}
							organizationDetails={organizationDetails}
							loanOriginatorEmail={loanOriginatorEmail}
							setLoanOriginatorEmail={setLoanOriginatorEmail}
							organizationUsers={organizationUsers}
							loanOriginator={loanOriginator}
							setLoanOriginator={setLoanOriginator}
							setOrganizationLicense={setOrganizationLicense}
							propertyState={propertyInfo?.state}
							error={error.includes("provide a value")}
							// disabled={borrowerDataExist}
						/>

						<LoanProcessor
							organizationName={organizationName}
							organizationDetails={organizationDetails}
							loanProcessorEmail={loanProcessorEmail}
							setLoanProcessorEmail={setLoanProcessorEmail}
							organizationProcessor={organizationProcessor}
							loanProcessor={loanProcessor}
							propertyState={propertyInfo?.state}
							error={error.includes("provide a value")}
							// disabled={borrowerDataExist}
						/>
					</TabPanel>
				)}

				{/* borrower pair 3 */}
				{loanApplicationData?.length > 4 && (
					<TabPanel
						value={tabValue}
						index={2}
						className={tabValue === 2 ? styles.tabPanelContainer : ""}
					>
						{loanApplicationData?.slice(4, 6)?.map((borrower, index = 4) => (
							<BorrowerInformation
								key={index}
								borrowerIndex={index + 4}
								coBorrower={index % 2 !== 0}
								borrowerData={borrower}
								foreignNationalIndex={foreignNationalIndex}
								setForeignNationalIndex={setForeignNationalIndex}
								data={loanApplicationData}
								setData={setLoanApplicationData}
								error={error.includes("provide a value")}
								// disabled={borrowerDataExist}
							/>
						))}

						<PropertyDetails
							data={propertyInfo}
							setData={setPropertyInfo}
							loanData={applicationData}
							setLoanData={setApplicationData}
							error={error.includes("provide a value")}
							// disabled={borrowerDataExist}
							grossRent={grossRent}
							setGrossRent={setGrossRent}
							homeownersInsurance={homeownersInsurance}
							setHomeownersInsurance={setHomeownersInsurance}
							floodInsurance={floodInsurance}
							setFloodInsurance={setFloodInsurance}
							propertyTaxes={propertyTaxes}
							setPropertyTaxes={setPropertyTaxes}
							realEstateTaxes={realEstateTaxes}
							setRealEstateTaxes={setRealEstateTaxes}
							hoaDues={hoaDues}
							setHoaDues={setHoaDues}
							setHelpText={setHelpText}
							handleOpenHelpText={handleOpenHelpText}
						/>

						<LoanOriginator
							organizationName={organizationName}
							organizationDetails={organizationDetails}
							loanOriginatorEmail={loanOriginatorEmail}
							setLoanOriginatorEmail={setLoanOriginatorEmail}
							organizationUsers={organizationUsers}
							loanOriginator={loanOriginator}
							setLoanOriginator={setLoanOriginator}
							setOrganizationLicense={setOrganizationLicense}
							propertyState={propertyInfo?.state}
							error={error.includes("provide a value")}
							// disabled={borrowerDataExist}
						/>

						<LoanProcessor
							organizationName={organizationName}
							organizationDetails={organizationDetails}
							loanProcessorEmail={loanProcessorEmail}
							setLoanProcessorEmail={setLoanProcessorEmail}
							organizationProcessor={organizationProcessor}
							loanProcessor={loanProcessor}
							propertyState={propertyInfo?.state}
							error={error.includes("provide a value")}
							// disabled={borrowerDataExist}
						/>
					</TabPanel>
				)}
			</Stack>

			{/* loan flow error */}
			{error !== "" && (
				<RequiredError
					headerText="Missing fields"
					text={
						error.includes("Something went wrong")
							? "Updates Not Received by LOS. Please Try Again."
							: error.includes("provide a value")
							? "Please provide a value for all fields highlighted below before you continue."
							: error.includes("borrower.dependentCount") &&
							  "Please check your dependent count."
					}
				/>
			)}

			<Stack direction="row" className={styles.footerContainer}>
				<PrimaryButton
					text="Save and Continue"
					endIcon={<ArrowForwardOutlined className={styles.nextIcon} />}
					onClick={() =>
						// borrowerDataExist ? handleNextSectionClick() :
						handleNext()
					}
				/>
			</Stack>

			{/* modal */}
			<LoaderModal
				open={
					creatingLoan ||
					creatingAdminLoan ||
					isUpdatingFields ||
					updatingLoan ||
					creatingBorrowerPair ||
					updatingBorrowerPair
				}
			/>
			<HelpTextModal
				open={helpTextOpen}
				modalText={helpText}
				handleClose={handleCloseHelpText}
			/>
		</Stack>
	);
};

export default CreateLoanApplication;
