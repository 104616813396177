import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

// mui components
import { InputLabel, Stack } from "@mui/material";

// custom components
import ViewLoanInfoCard from "../info-card";
import OutlinedTextInput from "../../../../components/custom-input/outlined-text-input";
import MaskedInput from "../../../../components/custom-input/masked-input";
import PrimaryButton from "../../../../components/buttons/primary-button";
import SecondaryButton from "../../../../components/buttons/secondary-button";
import SecondarySelect from "../../../../components/custom-select/secondary-select";
import CustomDivider from "../../../../components/custom-divider";
import Heading5 from "../../../../components/typography/heading-05";
import Heading6 from "../../../../components/typography/heading-06";
import Body3 from "../../../../components/typography/body-03";

// mui icons
import { ArrowBackOutlined, ArrowForwardOutlined } from "@mui/icons-material";

// api slices
import { useFieldMutation } from "../../../../features/field-values/fieldValueApiSlice";
import { useCountyListMutation } from "../../../../features/county-list/countyListApiSlice";

// helper utils
import {
	YNOptions,
	amortizationOptions,
	armFixedTermOptions,
	bankruptcyOutcomeOptions,
	bankruptcySeasoningOptions,
	bankruptcyTypeOptions,
	housingSeasoningOptions,
	housingTypeOptions,
	interestTermOptions,
	loanPurposeOptions,
	loanTermOptions,
	lockTermOptions,
	mortgageLienTypeOptions,
	occupancyOptions,
	prepaymentPenaltyOptions,
	propertyDesignCondoOptions,
	propertyDesignSingleOptions,
	propertyInfoTypeOptions,
	reservesOptions,
	stateOptions,
	unitFiveOptions,
	unitSingleOptions,
	unitTwoOptions,
} from "../../../../utils/select-options";

// custom styles
import styles from "./index.module.scss";

const ViewLoanTerms = ({
	setLoanApplicationStage,
	loanGuid,
	disabled,
	handleOpenLoaderModal,
	handleCloseLoaderModal,
	currentStage,
}) => {
	const params = useParams();
	const loanNumber = params?.loanId;

	const [field] = useFieldMutation();

	const [countyList] = useCountyListMutation();

	const productFamilyOptions = [
		{
			value: "DSCR: 5-8 Unit",
			label: "DSCR: 5-8 Unit",
		},
		{
			value: "Alternative Documentation",
			label: "Alternative Documentation",
		},
		{
			value: "Full Documentation",
			label: "Full Documentation",
		},
		{
			value: "DSCR",
			label: "DSCR",
		},
		{
			value: "DSCR: Elite",
			label: "DSCR: Elite",
		},
		{
			value: "DSCR: Mixed Use",
			label: "DSCR: Mixed Use",
		},
	];

	const loanProductOptions = {
		"DSCR: 5-8 Unit": [
			{
				value: "LFC Non-QM 5-8 Investor DSCR >=120.00 30 Yr Fixed - EG",
				label: "LFC Non-QM 5-8 Investor DSCR >=120.00 30 Yr Fixed - EG",
			},
			{
				value: "LFC Non-QM 5-8 Investor DSCR >=120.00 30 Yr Fixed I/O - EG",
				label: "LFC Non-QM 5-8 Investor DSCR >=120.00 30 Yr Fixed I/O - EG",
			},
			{
				value: "LFC Non-QM 5-8 Investor DSCR >=120.00 5/6 SOFR ARM - EG",
				label: "LFC Non-QM 5-8 Investor DSCR >=120.00 5/6 SOFR ARM - EG",
			},
			{
				value: "LFC Non-QM 5-8 Investor DSCR >=120.00 5/6 SOFR ARM I/O - EG",
				label: "LFC Non-QM 5-8 Investor DSCR >=120.00 5/6 SOFR ARM I/O - EG",
			},
			{
				value: "LFC Non-QM 5-8 Investor DSCR 110.00 - 119.99 5/6 SOFR ARM - EG",
				label: "LFC Non-QM 5-8 Investor DSCR 110.00 - 119.99 5/6 SOFR ARM - EG",
			},
			{
				value:
					"LFC Non-QM 5-8 Investor DSCR 110.00 - 119.99 5/6 SOFR ARM I/O - EG",
				label:
					"LFC Non-QM 5-8 Investor DSCR 110.00 - 119.99 5/6 SOFR ARM I/O - EG",
			},
			{
				value: "LFC Non-QM 5-8 Investor DSCR 30 Yr Fixed - EG",
				label: "LFC Non-QM 5-8 Investor DSCR 30 Yr Fixed - EG",
			},
			{
				value: "LFC Non-QM 5-8 Investor DSCR 30 Yr Fixed I/O - EG",
				label: "LFC Non-QM 5-8 Investor DSCR 30 Yr Fixed I/O - EG",
			},
			{
				value: "LFC Non-QM 5-8 Investor DSCR 5/6 SOFR AM - EG",
				label: "LFC Non-QM 5-8 Investor DSCR 5/6 SOFR AM - EG",
			},
			{
				value: "LFC Non-QM 5-8 Investor DSCR 5/6 SOFR AM I/O - EG",
				label: "LFC Non-QM 5-8 Investor DSCR 5/6 SOFR AM I/O - EG",
			},
		],
		"Alternative Documentation": [
			{
				value: "LFC Non-QM Alt Doc 30 Yr Fixed - EG",
				label: "LFC Non-QM Alt Doc 30 Yr Fixed - EG",
			},
			{
				value: "LFC Non-QM Alt Doc 30 Yr Fixed I/O - EG",
				label: "LFC Non-QM Alt Doc 30 Yr Fixed I/O - EG",
			},
			{
				value: "LFC Non-QM Alt Doc 40 Yr Fixed I/O - EG",
				label: "LFC Non-QM Alt Doc 40 Yr Fixed I/O - EG",
			},
			{
				value: "LFC Non-QM Alt Doc 5/6-mo ARM SOFR - EG",
				label: "LFC Non-QM Alt Doc 5/6-mo ARM SOFR - EG",
			},
			{
				value: "LFC Non-QM Alt Doc 5/6-mo ARM SOFR 30 Yr I/O - EG",
				label: "LFC Non-QM Alt Doc 5/6-mo ARM SOFR 30 Yr I/O - EG",
			},
			{
				value: "LFC Non-QM Alt Doc 5/6-mo ARM SOFR 40 Yr I/O - EG",
				label: "LFC Non-QM Alt Doc 5/6-mo ARM SOFR 40 Yr I/O - EG",
			},
		],
		"Full Documentation": [
			{
				value: "LFC Non-QM Full Doc 30 Yr Fixed - EG",
				label: "LFC Non-QM Full Doc 30 Yr Fixed - EG",
			},
			{
				value: "LFC Non-QM Full Doc 30 Yr Fixed I/O - EG",
				label: "LFC Non-QM Full Doc 30 Yr Fixed I/O - EG",
			},
			{
				value: "LFC Non-QM Full Doc 40 Yr Fixed I/O - EG",
				label: "LFC Non-QM Full Doc 40 Yr Fixed I/O - EG",
			},
			{
				value: "LFC Non-QM Full Doc 5/6-mo ARM SOFR - EG",
				label: "LFC Non-QM Full Doc 5/6-mo ARM SOFR - EG",
			},
			{
				value: "LFC Non-QM Full Doc 5/6-mo ARM SOFR 30 Yr I/O - EG",
				label: "LFC Non-QM Full Doc 5/6-mo ARM SOFR 30 Yr I/O - EG",
			},
			{
				value: "LFC Non-QM Full Doc 5/6-mo ARM SOFR 40 Yr I/O - EG",
				label: "LFC Non-QM Full Doc 5/6-mo ARM SOFR 40 Yr I/O - EG",
			},
		],
		DSCR: [
			{
				value: "LFC Non-QM Investor DSCR 30 Yr Fixed - EG",
				label: "LFC Non-QM Investor DSCR 30 Yr Fixed - EG",
			},
			{
				value: "LFC Non-QM Investor DSCR 30 Yr Fixed I/O - EG",
				label: "LFC Non-QM Investor DSCR 30 Yr Fixed I/O - EG",
			},
			{
				value: "LFC Non-QM Investor DSCR 40 Yr Fixed I/O - EG",
				label: "LFC Non-QM Investor DSCR 40 Yr Fixed I/O - EG",
			},
			{
				value: "LFC Non-QM Investor DSCR 5/6-mo ARM SOFR - EG",
				label: "LFC Non-QM Investor DSCR 5/6-mo ARM SOFR - EG",
			},
			{
				value: "LFC Non-QM Investor DSCR 5/6-mo ARM SOFR 30 Yr I/O - EG",
				label: "LFC Non-QM Investor DSCR 5/6-mo ARM SOFR 30 Yr I/O - EG",
			},
			{
				value: "LFC Non-QM Investor DSCR 5/6-mo ARM SOFR 40 Yr I/O - EG",
				label: "LFC Non-QM Investor DSCR 5/6-mo ARM SOFR 40 Yr I/O - EG",
			},
			{
				value: "LFC Non-QM Investor DSCR No Ratio 30 Yr Fixed - EG",
				label: "LFC Non-QM Investor DSCR No Ratio 30 Yr Fixed - EG",
			},
			{
				value: "LFC Non-QM Investor DSCR No Ratio 5/6-mo ARM SOFR - EG",
				label: "LFC Non-QM Investor DSCR No Ratio 5/6-mo ARM SOFR - EG",
			},
		],
		"DSCR: Elite": [
			{
				value: "LFC Non-QM Investor Elite DSCR 30 Yr Fixed - EG",
				label: "LFC Non-QM Investor Elite DSCR 30 Yr Fixed - EG",
			},
			{
				value: "LFC Non-QM Investor Elite DSCR 30 Yr Fixed I/O - EG",
				label: "LFC Non-QM Investor Elite DSCR 30 Yr Fixed I/O - EG",
			},
			{
				value: "LFC Non-QM Investor Elite DSCR 5/6-mo ARM SOFR - EG",
				label: "LFC Non-QM Investor Elite DSCR 5/6-mo ARM SOFR - EG",
			},
			{
				value: "LFC Non-QM Investor Elite DSCR 5/6-mo ARM SOFR 30 Yr I/O - EG",
				label: "LFC Non-QM Investor Elite DSCR 5/6-mo ARM SOFR 30 Yr I/O - EG",
			},
		],
		"DSCR: Mixed Use": [
			{
				value: "LFC Non-QM Mixed Use Investor DSCR 30 Yr Fixed - EG",
				label: "LFC Non-QM Mixed Use Investor DSCR 30 Yr Fixed - EG",
			},
			{
				value: "LFC Non-QM Mixed Use Investor DSCR 30 Yr Fixed I/O - EG",
				label: "LFC Non-QM Mixed Use Investor DSCR 30 Yr Fixed I/O - EG",
			},
			{
				value: "LFC Non-QM Mixed Use Investor DSCR 5/6 SOFR ARM - EG",
				label: "LFC Non-QM Mixed Use Investor DSCR 5/6 SOFR ARM - EG",
			},
			{
				value: "LFC Non-QM Mixed Use Investor DSCR 5/6 SOFR ARM I/O - EG",
				label: "LFC Non-QM Mixed Use Investor DSCR 5/6 SOFR ARM I/O - EG",
			},
		],
	};

	const incomeDocumentOldOptions = {
		"Full Documentation": [
			{
				label: "Full Documentation",
				value: "Full Doc",
			},
		],
		"Alternative Documentation": [
			{
				label: "12 Mo. Business Bank Statement",
				value: "Business Bank Stmt: 12 Mos",
			},
			{
				label: "24 Mo. Business Bank Statement",
				value: "Business Bank Stmt: 24 Mos",
			},
			{
				label: "12 Mo. Personal Bank Statement",
				value: "Personal Bank Stmt: 12 Mos",
			},
			{
				label: "24 Mo. Personal Bank Statement",
				value: "Personal Bank Stmt: 24 Mos",
			},
			{
				label: "Asset Related",
				value: "Asset Related",
			},
			{
				label: "Profit and Loss: 1 Year",
				value: "P&L: 1 Year",
			},
			{
				label: "Profit and Loss: 2 Year",
				value: "P&L: 2 Year",
			},
			{
				label: "1099: 1 Year",
				value: "1099: 1 Year",
			},
			{
				label: "1099: 2 Year",
				value: "1099: 2 Year",
			},
		],
		"DSCR: 5-8 Unit": [
			{
				label: "DSCR",
				value: "Investor - DSCR",
			},
		],
		"DSCR: Mixed Use": [
			{
				label: "DSCR",
				value: "Investor - DSCR",
			},
		],
		"DSCR: Elite": [
			{
				label: "DSCR",
				value: "Investor - DSCR",
			},
		],
		DSCR: [
			{
				label: "DSCR",
				value: "Investor - DSCR",
			},
		],
		"DSCR: No Ratio": [
			{
				label: "Investor - No Ratio",
				value: "Investor - No Ratio",
			},
		],
	};

	const incomeDocumentOptions = [
		{
			label: "Full Documentation",
			value: "Full Doc",
		},
		{
			label: "12 Mo. Personal Bank Statement",
			value: "Personal Bank Stmt: 12 Mos",
		},
		{
			label: "24 Mo. Personal Bank Statement",
			value: "Personal Bank Stmt: 24 Mos",
		},
		{
			label: "12 Mo. Business Bank Statement",
			value: "Business Bank Stmt: 12 Mos",
		},
		{
			label: "24 Mo. Business Bank Statement",
			value: "Business Bank Stmt: 24 Mos",
		},
		{
			label: "Asset Related",
			value: "Asset Related",
		},
		{
			label: "DSCR",
			value: "Investor - DSCR",
		},
		{
			label: "Investor - No Ratio",
			value: "Investor - No Ratio",
		},

		{
			label: "Profit and Loss: 1 Year",
			value: "P&L: 1 Year",
		},
		{
			label: "Profit and Loss: 2 Year",
			value: "P&L: 2 Year",
		},
		{
			label: "One Year 1099",
			value: "One Year 1099",
		},
		{
			label: "Two Year 1099",
			value: "Two Year 1099",
		},
	];

	// const [loanProductFamily, setLoanProductFamily] = useState("");
	// const [loanProduct, setLoanProduct] = useState("");
	const [documentType, setDocumentType] = useState("");
	const [loanPurpose, setLoanPurpose] = useState("");
	const [lockTerm, setLockTerm] = useState("");
	const [mortgageLienType, setMortgageLienType] = useState("");
	const [purchasePrice, setPurchasePrice] = useState("");
	const [estimatedValue, setEstimatedValue] = useState("");
	const [loanAmount, setLoanAmount] = useState("");
	const [employmentStatus, setEmploymentStatus] = useState("");
	const [occupancy, setOccupancy] = useState("Investor");
	const [loanValue, setLoanValue] = useState(0);

	const [reservesDuration, setReservesDuration] = useState(0);

	const [paymentPenalty, setPaymentPenalty] = useState("");
	const [interestOnly, setInterestOnly] = useState("");
	const [cashOut, setCashOut] = useState("");

	const [creditScore, setCreditScore] = useState("");
	const [housingType, setHousingType] = useState("");
	const [housingSeasoning, setHousingSeasoning] = useState("");
	const [bankruptcyType, setBankruptcyType] = useState("");
	const [bankruptcyOutcome, setBankruptcyOutcome] = useState("");
	const [bankruptcySeasoning, setBankruptcySeasoning] = useState("");

	const [mortgageLates, setMortgageLates] = useState(null);
	const [mortgageLates1, setMortgageLates1] = useState("");
	const [mortgageLates2, setMortgageLates2] = useState("");
	const [mortgageLates3, setMortgageLates3] = useState("");
	const [mortgageLates4, setMortgageLates4] = useState("");
	const [mortgageLates5, setMortgageLates5] = useState("");
	const [mortgageLates6, setMortgageLates6] = useState("");
	const [mortgageLates7, setMortgageLates7] = useState("");
	const [mortgageLates8, setMortgageLates8] = useState("");
	const [mortgageLates9, setMortgageLates9] = useState("");

	const [propertyType, setPropertyType] = useState("");
	const [propertyDesign, setPropertyDesign] = useState("");

	const [unitCount, setUnitCount] = useState("");
	const [occupancyRate, setOccupancyRate] = useState("");
	const [grossRent, setGrossRent] = useState("");

	const [amortization, setAmortization] = useState("");
	const [loanTerm, setLoanTerm] = useState("");
	const [armFixedTerm, setArmFixedTerm] = useState("");
	const [interestOnlyTerm, setInterestOnlyTerm] = useState("");
	const [borrowerMI, setBorrowerMI] = useState("");

	const [state, setState] = useState("");
	const [zipcode, setZipcode] = useState("");
	const [county, setCounty] = useState("");
	const [countyListData, setCountyListData] = useState("");

	const [borrowerFirstName, setBorrowerFirstName] = useState("");
	const [borrowerMiddleName, setBorrowerMiddleName] = useState("");
	const [borrowerLastName, setBorrowerLastName] = useState("");

	const [coBorrowerFirstName, setCoBorrowerFirstName] = useState("");
	const [coBorrowerMiddleName, setCoBorrowerMiddleName] = useState("");
	const [coBorrowerLastName, setCoBorrowerLastName] = useState("");

	const [street, setStreet] = useState("");
	const [city, setCity] = useState("");
	const [postalCode, setPostalCode] = useState("");

	const [requestedInterestRate, setRequestedInterestRate] = useState("");
	const [mortgageTotal, setMortgageTotal] = useState("");
	const [drawAmount, setDrawAmount] = useState("");
	const [creditLimit, setCreditLimit] = useState("");

	const [cltv, setCltv] = useState("");
	const [hcltv, setHcltv] = useState("");

	const [escrow, setEscrow] = useState("");

	const [dscrValue, setDscrValue] = useState("");
	const [dtiFrontendRatio, setDtiFrontendRatio] = useState("");
	const [dtiBackendRatio, setDtiBackendRatio] = useState("");

	useEffect(() => {
		async function getCountyList() {
			const countyListResponse = await countyList({
				q: zipcode,
			}).unwrap();
			console.log("70 county list response:", countyListResponse?.response);
			let facetGroups = countyListResponse?.response?.facet_groups;
			console.log("79 coty name:", facetGroups);

			let cotyValues = facetGroups?.find(
				(item) => item.name === "coty_name"
			).facets;

			console.log("82 coty value:", cotyValues);

			let valueArr = [];

			if (cotyValues !== undefined && cotyValues?.length > 0) {
				setCounty(cotyValues[0]?.name);

				valueArr = cotyValues?.map((item) => ({
					...item,
					label: item.name,
					value: item.name,
				}));
			}

			console.log("400 value arr:", valueArr);

			setCountyListData(valueArr);
		}

		getCountyList();
	}, [zipcode]);

	const handleIncomeDocUpdate = (loanProductValue) => {
		console.log("1390 loan product value:", loanProductValue);

		if (loanProductValue === "Full Documentation") {
			setDocumentType("Full Doc");
		} else if (loanProductValue?.includes("DSCR")) {
			setDocumentType("Investor - DSCR");
		} else {
			setDocumentType("");
		}
	};

	const updateField = (fieldValues) => {
		console.log("88 field values:", fieldValues);

		setBorrowerFirstName(fieldValues?.find((o) => o.fieldId === "4000")?.value);
		setBorrowerMiddleName(
			fieldValues?.find((o) => o.fieldId === "4001")?.value
		);
		setBorrowerLastName(fieldValues?.find((o) => o.fieldId === "4002")?.value);

		setCoBorrowerFirstName(
			fieldValues?.find((o) => o.fieldId === "4004")?.value
		);
		setCoBorrowerMiddleName(
			fieldValues?.find((o) => o.fieldId === "4005")?.value
		);
		setCoBorrowerLastName(
			fieldValues?.find((o) => o.fieldId === "4006")?.value
		);

		setStreet(fieldValues?.find((o) => o.fieldId === "URLA.X73")?.value);
		setCity(fieldValues?.find((o) => o.fieldId === "12")?.value);
		setState(fieldValues?.find((o) => o.fieldId === "14")?.value);
		setPostalCode(fieldValues?.find((o) => o.fieldId === "15")?.value);

		setCreditScore(fieldValues?.find((o) => o.fieldId === "VASUMM.X23")?.value);
		setRequestedInterestRate(
			fieldValues?.find((o) => o.fieldId === "3")?.value
		);
		setMortgageTotal(fieldValues?.find((o) => o.fieldId === "428")?.value);
		setDrawAmount(
			fieldValues?.find((o) => o.fieldId === "CASASRN.X167")?.value
		);
		setCreditLimit(
			fieldValues?.find((o) => o.fieldId === "CASASRN.X168")?.value
		);
		setCltv(fieldValues?.find((o) => o.fieldId === "976")?.value);
		setHcltv(fieldValues?.find((o) => o.fieldId === "1540")?.value);
		setEscrow(fieldValues?.find((o) => o.fieldId === "MORNET.X15")?.value);

		// let loanProductValue = fieldValues?.find(
		// 	(o) => o.fieldId === "1401"
		// )?.value;
		// console.log("168 loan product:", loanProductValue);
		// console.log("168 loan product family:", loanProductOptions);

		// handleIncomeDocUpdate(loanProductValue);

		// for (const key in loanProductOptions) {
		// 	if (loanProductOptions.hasOwnProperty(key)) {
		// 		if (
		// 			loanProductOptions[key]?.findIndex(
		// 				(item) => item.value === loanProductValue
		// 			) >= 0
		// 		) {
		// 			setLoanProductFamily(key);
		// 			setLoanProduct(loanProductValue);
		// 		}
		// 	}
		// }

		setLoanPurpose(fieldValues?.find((o) => o.fieldId === "19")?.value);
		setPurchasePrice(fieldValues?.find((o) => o.fieldId === "136")?.value);

		if (fieldValues?.find((o) => o.fieldId === "356")?.value !== "") {
			setEstimatedValue(fieldValues?.find((o) => o.fieldId === "356")?.value);
		} else {
			setEstimatedValue(fieldValues?.find((o) => o.fieldId === "1821")?.value);
		}

		setLoanAmount(fieldValues?.find((o) => o.fieldId === "1109")?.value);
		setEmploymentStatus(
			fieldValues?.find((o) => o.fieldId === "FE0115")?.value
		);
		let occupancyValue = fieldValues?.find((o) => o.fieldId === "1811")?.value;
		if (occupancyValue === "") {
			setOccupancy("Investor");
		} else {
			setOccupancy(occupancyValue);
		}
		setLoanValue(fieldValues?.find((o) => o.fieldId === "353")?.value);

		setReservesDuration(fieldValues?.find((o) => o.fieldId === "1548")?.value);

		console.log(
			"480 pre-payment value:",
			fieldValues?.find((o) => o.fieldId === "1947")?.value
		);

		if (fieldValues?.find((o) => o.fieldId === "1947")?.value === "0") {
			setPaymentPenalty(0);
		} else {
			setPaymentPenalty(
				fieldValues?.find((o) => o.fieldId === "1947")?.value / 12
			);
		}

		setInterestOnly(fieldValues?.find((o) => o.fieldId === "2982")?.value);
		setCashOut(fieldValues?.find((o) => o.fieldId === "CASASRN.X79")?.value);
		setHousingType(
			fieldValues?.find((o) => o.fieldId === "CX.HOUSINGEVENTTYPE")?.value
		);

		setHousingSeasoning(
			fieldValues?.find((o) => o.fieldId === "CX.HOUSINGEVENTSEASONING")?.value
		);
		setBankruptcyType(
			fieldValues?.find((o) => o.fieldId === "CX.BANKRUPTCYTYPE")?.value
		);
		setBankruptcyOutcome(
			fieldValues?.find((o) => o.fieldId === "CX.BANKRUPTCYOUTCOME")?.value
		);
		setBankruptcySeasoning(
			fieldValues?.find((o) => o.fieldId === "CX.BANKRUPTCYSEASONING")?.value
		);

		setMortgageLates(
			fieldValues?.find((o) => o.fieldId === "CX.MTGLATE.ANY")?.value
		);

		let mortgageLatesValue1 = fieldValues?.find(
			(o) => o.fieldId === "CX.MTGLATEX30MOS6"
		)?.value;

		if (mortgageLatesValue1 !== "" && Number(mortgageLatesValue1) > 0) {
			setMortgageLates1(
				Number(mortgageLatesValue1) > 0 ? mortgageLatesValue1 : ""
			);
			setMortgageLates("Y");
		}

		let mortgageLatesValue2 = fieldValues?.find(
			(o) => o.fieldId === "CX.MTGLATEX30MOS12"
		)?.value;

		if (mortgageLatesValue2 !== "" && Number(mortgageLatesValue2) > 0) {
			setMortgageLates2(
				Number(mortgageLatesValue2) > 0 ? mortgageLatesValue2 : ""
			);
			setMortgageLates("Y");
		}
		let mortgageLatesValue3 = fieldValues?.find(
			(o) => o.fieldId === "CX.MTGLATEX60MOS12"
		)?.value;
		if (mortgageLatesValue3 !== "" && Number(mortgageLatesValue3) > 0) {
			setMortgageLates3(
				Number(mortgageLatesValue3) > 0 ? mortgageLatesValue3 : ""
			);
			setMortgageLates("Y");
		}
		let mortgageLatesValue4 = fieldValues?.find(
			(o) => o.fieldId === "CX.MTGLATEX90MOS12"
		)?.value;
		if (mortgageLatesValue4 !== "" && Number(mortgageLatesValue4) > 0) {
			setMortgageLates4(
				Number(mortgageLatesValue4) > 0 ? mortgageLatesValue4 : ""
			);
			setMortgageLates("Y");
		}
		let mortgageLatesValue5 = fieldValues?.find(
			(o) => o.fieldId === "CX.MTGLATEX120MOS12"
		)?.value;
		if (mortgageLatesValue5 !== "" && Number(mortgageLatesValue5) > 0) {
			setMortgageLates5(
				Number(mortgageLatesValue5) > 0 ? mortgageLatesValue5 : ""
			);
			setMortgageLates("Y");
		}
		let mortgageLatesValue6 = fieldValues?.find(
			(o) => o.fieldId === "CX.MTGLATEX30MOS13TO24"
		)?.value;
		if (mortgageLatesValue6 !== "" && Number(mortgageLatesValue6) > 0) {
			setMortgageLates6(
				Number(mortgageLatesValue6) > 0 ? mortgageLatesValue6 : ""
			);
			setMortgageLates("Y");
		}
		let mortgageLatesValue7 = fieldValues?.find(
			(o) => o.fieldId === "CX.MTGLATEX60MOS13TO24"
		)?.value;
		if (mortgageLatesValue7 !== "" && Number(mortgageLatesValue7) > 0) {
			setMortgageLates7(
				Number(mortgageLatesValue7) > 0 ? mortgageLatesValue7 : ""
			);
			setMortgageLates("Y");
		}
		let mortgageLatesValue8 = fieldValues?.find(
			(o) => o.fieldId === "CX.MTGLATEX90MOS13TO24"
		)?.value;
		if (mortgageLatesValue8 !== "" && Number(mortgageLatesValue8) > 0) {
			setMortgageLates8(
				Number(mortgageLatesValue8) > 0 ? mortgageLatesValue8 : ""
			);
			setMortgageLates("Y");
		}
		let mortgageLatesValue9 = fieldValues?.find(
			(o) => o.fieldId === "CX.MTGLATEX120MOS13TO24"
		)?.value;
		if (mortgageLatesValue9 !== "" && Number(mortgageLatesValue9) > 0) {
			setMortgageLates9(
				Number(mortgageLatesValue9) > 0 ? mortgageLatesValue9 : ""
			);
			setMortgageLates("Y");
		}

		let unitCountValue;

		let fieldUnitCount = fieldValues?.find((o) => o.fieldId === "16")?.value;
		if (fieldUnitCount !== "") {
			unitCountValue = fieldUnitCount;
		}

		let customFieldUnitCount = fieldValues?.find(
			(o) => o.fieldId === "CX.PROPERTY.COMMERCIAL.UNITS"
		)?.value;
		if (customFieldUnitCount !== "") {
			unitCountValue = customFieldUnitCount;
		}

		setUnitCount(unitCountValue);
		setState(fieldValues?.find((o) => o.fieldId === "14")?.value);
		setZipcode(fieldValues?.find((o) => o.fieldId === "15")?.value);
		setCounty(fieldValues?.find((o) => o.fieldId === "13")?.value);
		setOccupancyRate(fieldValues?.find((o) => o.fieldId === "1487")?.value);
		setGrossRent(fieldValues?.find((o) => o.fieldId === "1005")?.value);

		setDocumentType(
			fieldValues?.find((o) => o.fieldId === "CX.INCOMEVERIFYTYPE")?.value
		);

		let propertyTypeValue = fieldValues?.find(
			(o) => o.fieldId === "1041"
		)?.value;

		let attachmentTypeValue = fieldValues?.find(
			(o) => o.fieldId === "ULDD.X177"
		)?.value;

		let projectDesignType = fieldValues?.find(
			(o) => o.fieldId === "ULDD.X140"
		)?.value;

		let propertyCommercialTypeValue = fieldValues?.find(
			(o) => o.fieldId === "CX.PROPERTY.COMMERCIAL.TYPE"
		)?.value;

		let isCondominium =
			fieldValues?.find((o) => o.fieldId === "URLA.X205")?.value === "Y";
		let isPUD =
			fieldValues?.find((o) => o.fieldId === "URLA.X207")?.value === "Y";
		let isPropertyNotLocatedInProject =
			fieldValues?.find((o) => o.fieldId === "URLA.X208")?.value === "Y";

		let isCondotel =
			fieldValues?.find((o) => o.fieldId === "3315")?.value === "Y";
		let isNonWarrantableProperty =
			fieldValues?.find((o) => o.fieldId === "3316")?.value === "Y";

		if (isCondotel) {
			setPropertyType("Condotel");
			setPropertyDesign(projectDesignType || attachmentTypeValue);
			setUnitCount(fieldUnitCount);
		} else if (isCondominium && isNonWarrantableProperty) {
			setPropertyType("Non-Warrantable Condo");
			setPropertyDesign(projectDesignType || attachmentTypeValue);
			setUnitCount(fieldUnitCount);
		} else if (isPUD && propertyTypeValue === "PUD") {
			setPropertyType("Planned Unit Development");
			setPropertyDesign(
				projectDesignType || attachmentTypeValue === "Attached"
					? "Townhouse Rowhouse"
					: attachmentTypeValue
			);
			setUnitCount(fieldUnitCount);
		} else if (isCondominium && !isCondotel && !isNonWarrantableProperty) {
			setPropertyType("Warrantable Condo");
			setPropertyDesign(projectDesignType || attachmentTypeValue);
			setUnitCount(fieldUnitCount);
		} else if (
			isPropertyNotLocatedInProject &&
			Number(fieldUnitCount) === 4 &&
			propertyCommercialTypeValue === "Residential 5 - 8"
		) {
			setPropertyType("5-8 Unit Residential");
			setPropertyDesign(
				projectDesignType || attachmentTypeValue === "Attached"
					? "Townhouse Rowhouse"
					: attachmentTypeValue
			);
			setUnitCount(customFieldUnitCount);
		} else if (
			isPropertyNotLocatedInProject &&
			Number(fieldUnitCount) === 4 &&
			propertyCommercialTypeValue === "Mixed Use Residential"
		) {
			setPropertyType("5-8 Unit Mixed Use");
			setPropertyDesign(
				projectDesignType || attachmentTypeValue === "Attached"
					? "Townhouse Rowhouse"
					: attachmentTypeValue
			);
			setUnitCount(customFieldUnitCount);
		} else if (isPropertyNotLocatedInProject && Number(fieldUnitCount) > 1) {
			setPropertyType("2-4 Unit Property");
			setPropertyDesign(
				projectDesignType || attachmentTypeValue === "Attached"
					? "Townhouse Rowhouse"
					: attachmentTypeValue
			);
			setUnitCount(fieldUnitCount);
		} else if (isPropertyNotLocatedInProject && Number(fieldUnitCount) === 1) {
			setPropertyType("Single Family");
			setPropertyDesign(
				projectDesignType || attachmentTypeValue === "Attached"
					? "Townhouse Rowhouse"
					: attachmentTypeValue
			);
			setUnitCount(fieldUnitCount);
		}

		setLockTerm(fieldValues?.find((o) => o.fieldId === "2150")?.value || 30);
		setMortgageLienType(
			fieldValues?.find((o) => o.fieldId === "420")?.value || "FirstLien"
		);
		setAmortization(fieldValues?.find((o) => o.fieldId === "608")?.value);
		setLoanTerm(fieldValues?.find((o) => o.fieldId === "4")?.value);
		setArmFixedTerm(fieldValues?.find((o) => o.fieldId === "696")?.value);
		setInterestOnlyTerm(fieldValues?.find((o) => o.fieldId === "1177")?.value);
		setBorrowerMI(
			fieldValues?.find((o) => o.fieldId === "CD1.X42")?.value || "N"
		);

		setDscrValue(
			fieldValues?.find((o) => o.fieldId === "CX.DEBTSERVICECOVERAGERATIO")
				?.value
		);

		// setDtiFrontendRatio(fieldValues?.find((o) => o.fieldId === "740")?.value);

		// setDtiBackendRatio(fieldValues?.find((o) => o.fieldId === "742")?.value);

		handleCloseLoaderModal();
	};

	const getFieldValues = async (loanId) => {
		console.log("175 loan id:", loanId);
		handleOpenLoaderModal();
		try {
			const getValues = await field({
				loan_guid: loanId,
				fields:
					"4000,4001,4002,4004,4005,4006,URLA.X73,1401,1487,1005,MORNET.X67,19,2150,420,608,4,696,1177,CD1.X42,19,URLA.X165,136,356,1821,1109,FE0115,1811,URLA.X205,URLA.X207,353,CX.DSCR.CALC,HMDA.X36,1548,1947,CX.PREPAYMENTPENALTY,2964,2217,2982,MORNET.X15,CASASRN.X79,VASUMM.X23,CX.PROPERTY.COMMERCIAL.UNITS,CX.HOUSINGEVENTTYPE,CX.HOUSINGEVENTSEASONING,CX.BANKRUPTCYTYPE,CX.BANKRUPTCYOUTCOME,CX.BANKRUPTCYSEASONING,1041,16,14,15,13,URLA.X205,URLA.X206,URLA.X207,URLA.X208,ULDD.X177,ULDD.X140,3315,3316,CX.IncomeVerifyType,CX.Property.Commercial,CX.PROPERTY.COMMERCIAL.TYPE,3,428,CASASRN.X167,CASASRN.X168,976,1540,CX.MTGLATE.ANY,CX.MTGLATEX30MOS6,CX.MTGLATEX30MOS12,CX.MTGLATEX60MOS12,CX.MTGLATEX90MOS12,CX.MTGLATEX120MOS12,CX.MTGLATEX30MOS13TO24,CX.MTGLATEX60MOS13TO24,CX.MTGLATEX90MOS13TO24,CX.MTGLATEX120MOS13TO24,CX.DEBTSERVICECOVERAGERATIO,740,742",
			}).unwrap();
			console.log("175 all fields:", getValues?.response);
			if (getValues?.response?.length > 0) {
				updateField(getValues?.response);
			}
		} catch (err) {
			console.log("270 error:", err);
		}
	};

	useEffect(() => {
		console.log("160 loan guid:", loanGuid);
		if (loanGuid !== undefined && loanGuid !== "") {
			getFieldValues(loanGuid);
		}
	}, [loanGuid]);

	const handlePurchasePrice = (value) => {
		setPurchasePrice(value);

		// If Loan Purpose = “Purchase” then us lower of the “Purchase Price” or “Estimated Value”
		if (loanPurpose?.toLowerCase() === "purchase") {
			let calculationDenominator;

			if (Number(value) === 0 || Number(estimatedValue) === 0) {
				calculationDenominator =
					Number(value) > Number(estimatedValue)
						? Number(value)
						: Number(estimatedValue);

				console.log(
					"At least one value is zero, selecting higher value.",
					calculationDenominator
				);
			} else {
				calculationDenominator =
					Number(value) < Number(estimatedValue)
						? Number(value)
						: Number(estimatedValue);

				console.log(
					"Comparing values and selecting lesser value.",
					calculationDenominator
				);
			}

			setLoanValue(((loanAmount / calculationDenominator) * 100).toFixed(3));
			setCltv(
				(
					((Number(loanAmount || 0) +
						Number(mortgageTotal || 0) +
						Number(drawAmount || 0)) /
						calculationDenominator) *
					100
				).toFixed(3)
			);
			setHcltv(
				(
					((Number(loanAmount || 0) +
						Number(mortgageTotal || 0) +
						Number(creditLimit || 0)) /
						calculationDenominator) *
					100
				).toFixed(3)
			);
		}
	};

	const handleEstimatedValue = (value) => {
		setEstimatedValue(value);

		// If Loan Purpose = “Purchase” then us lower of the “Purchase Price” or “Estimated Value”
		if (loanPurpose?.toLowerCase() === "purchase") {
			let calculationDenominator;

			if (Number(purchasePrice) === 0 || Number(value) === 0) {
				calculationDenominator =
					Number(purchasePrice) > Number(value)
						? Number(purchasePrice)
						: Number(value);

				console.log(
					"565 At least one value is zero, selecting higher value.",
					calculationDenominator
				);
			} else {
				calculationDenominator =
					Number(purchasePrice) < Number(value)
						? Number(purchasePrice)
						: Number(value);

				console.log(
					"575 Comparing values and selecting lesser value.",
					calculationDenominator
				);
			}

			setLoanValue(((loanAmount / calculationDenominator) * 100).toFixed(3));
			setCltv(
				(
					((Number(loanAmount || 0) +
						Number(mortgageTotal || 0) +
						Number(drawAmount || 0)) /
						calculationDenominator) *
					100
				).toFixed(3)
			);
			setHcltv(
				(
					((Number(loanAmount || 0) +
						Number(mortgageTotal || 0) +
						Number(creditLimit || 0)) /
						calculationDenominator) *
					100
				).toFixed(3)
			);
		} else {
			let calculationDenominator = Number(value);

			setLoanValue(((loanAmount / calculationDenominator) * 100).toFixed(3));
			setCltv(
				(
					((Number(loanAmount || 0) +
						Number(mortgageTotal || 0) +
						Number(drawAmount || 0)) /
						calculationDenominator) *
					100
				).toFixed(3)
			);
			setHcltv(
				(
					((Number(loanAmount || 0) +
						Number(mortgageTotal || 0) +
						Number(creditLimit || 0)) /
						calculationDenominator) *
					100
				).toFixed(3)
			);
		}
	};

	const handleLoanAmount = (value) => {
		setLoanAmount(value);

		console.log("625");

		// If Loan Purpose = “Purchase” then us lower of the “Purchase Price” or “Estimated Value”
		if (loanPurpose?.toLowerCase() === "purchase") {
			let calculationDenominator;

			console.log("650 purchase:", purchasePrice);
			console.log("650 estimated:", estimatedValue);

			if (Number(purchasePrice) === 0 || Number(estimatedValue) === 0) {
				calculationDenominator =
					Number(purchasePrice) > Number(estimatedValue)
						? Number(purchasePrice)
						: Number(estimatedValue);

				console.log(
					"565 At least one value is zero, selecting higher value.",
					calculationDenominator
				);
			} else {
				calculationDenominator =
					Number(purchasePrice) < Number(estimatedValue)
						? Number(purchasePrice)
						: Number(estimatedValue);

				console.log(
					"575 Comparing values and selecting lesser value.",
					calculationDenominator
				);
			}

			setLoanValue(((value / calculationDenominator) * 100).toFixed(3));
			setCltv(
				(
					((Number(value) +
						Number(mortgageTotal || 0) +
						Number(drawAmount || 0)) /
						calculationDenominator) *
					100
				).toFixed(3)
			);
			setHcltv(
				(
					((Number(value) +
						Number(mortgageTotal || 0) +
						Number(creditLimit || 0)) /
						calculationDenominator) *
					100
				).toFixed(3)
			);
		} else {
			let calculationDenominator = Number(estimatedValue);

			console.log("600 loan amount:", value);
			console.log("600 calc denom:", calculationDenominator);
			console.log("600 calc math:", (value / calculationDenominator) * 100);

			setLoanValue(((value / calculationDenominator) * 100).toFixed(3));
			setCltv(
				(
					((Number(value) +
						Number(mortgageTotal || 0) +
						Number(drawAmount || 0)) /
						calculationDenominator) *
					100
				).toFixed(3)
			);
			setHcltv(
				(
					((Number(value) +
						Number(mortgageTotal || 0) +
						Number(creditLimit || 0)) /
						calculationDenominator) *
					100
				).toFixed(3)
			);
		}
	};

	const handleMortgageTotal = (value) => {
		setMortgageTotal(value);

		// If Loan Purpose = “Purchase” then us lower of the “Purchase Price” or “Estimated Value”
		if (loanPurpose?.toLowerCase() === "purchase") {
			let calculationDenominator;

			if (Number(purchasePrice) === 0 || Number(estimatedValue) === 0) {
				calculationDenominator =
					Number(purchasePrice) > Number(estimatedValue)
						? Number(purchasePrice)
						: Number(estimatedValue);

				console.log(
					"565 At least one value is zero, selecting higher value.",
					calculationDenominator
				);
			} else {
				calculationDenominator =
					Number(purchasePrice) < Number(estimatedValue)
						? Number(purchasePrice)
						: Number(estimatedValue);

				console.log(
					"575 Comparing values and selecting lesser value.",
					calculationDenominator
				);
			}

			setCltv(
				(
					((Number(loanAmount || 0) +
						Number(value || 0) +
						Number(drawAmount || 0)) /
						calculationDenominator) *
					100
				).toFixed(3)
			);
			setHcltv(
				(
					((Number(loanAmount || 0) +
						Number(value || 0) +
						Number(creditLimit || 0)) /
						calculationDenominator) *
					100
				).toFixed(3)
			);
		} else {
			let calculationDenominator = Number(estimatedValue);

			setCltv(
				(
					((Number(loanAmount || 0) +
						Number(value || 0) +
						Number(drawAmount || 0)) /
						calculationDenominator) *
					100
				).toFixed(3)
			);
			setHcltv(
				(
					((Number(loanAmount || 0) +
						Number(value || 0) +
						Number(creditLimit || 0)) /
						calculationDenominator) *
					100
				).toFixed(3)
			);
		}
	};

	const handleDrawAmount = (value) => {
		setDrawAmount(value);

		// If Loan Purpose = “Purchase” then us lower of the “Purchase Price” or “Estimated Value”
		if (loanPurpose?.toLowerCase() === "purchase") {
			let calculationDenominator;

			if (Number(purchasePrice) === 0 || Number(estimatedValue) === 0) {
				calculationDenominator =
					Number(purchasePrice) > Number(estimatedValue)
						? Number(purchasePrice)
						: Number(estimatedValue);

				console.log(
					"565 At least one value is zero, selecting higher value.",
					calculationDenominator
				);
			} else {
				calculationDenominator =
					Number(purchasePrice) < Number(estimatedValue)
						? Number(purchasePrice)
						: Number(estimatedValue);

				console.log(
					"575 Comparing values and selecting lesser value.",
					calculationDenominator
				);
			}

			setCltv(
				(
					((Number(loanAmount || 0) +
						Number(mortgageTotal || 0) +
						Number(value || 0)) /
						calculationDenominator) *
					100
				).toFixed(3)
			);
		} else {
			let calculationDenominator = Number(estimatedValue);

			setCltv(
				(
					((Number(loanAmount || 0) +
						Number(mortgageTotal || 0) +
						Number(value || 0)) /
						calculationDenominator) *
					100
				).toFixed(3)
			);
		}
	};

	const handleCreditLimit = (value) => {
		setCreditLimit(value);

		// If Loan Purpose = “Purchase” then us lower of the “Purchase Price” or “Estimated Value”
		if (loanPurpose?.toLowerCase() === "purchase") {
			let calculationDenominator;

			if (Number(purchasePrice) === 0 || Number(estimatedValue) === 0) {
				calculationDenominator =
					Number(purchasePrice) > Number(estimatedValue)
						? Number(purchasePrice)
						: Number(estimatedValue);

				console.log(
					"565 At least one value is zero, selecting higher value.",
					calculationDenominator
				);
			} else {
				calculationDenominator =
					Number(purchasePrice) < Number(estimatedValue)
						? Number(purchasePrice)
						: Number(estimatedValue);

				console.log(
					"575 Comparing values and selecting lesser value.",
					calculationDenominator
				);
			}

			setHcltv(
				(
					((Number(loanAmount || 0) +
						Number(mortgageTotal || 0) +
						Number(value || 0)) /
						calculationDenominator) *
					100
				).toFixed(3)
			);
		} else {
			let calculationDenominator = Number(estimatedValue);

			setHcltv(
				(
					((Number(loanAmount || 0) +
						Number(mortgageTotal || 0) +
						Number(value || 0)) /
						calculationDenominator) *
					100
				).toFixed(3)
			);
		}
	};

	const handleChangeCreditScore = (event) => {
		const min = 0;
		const max = 850;

		const value = Math.max(min, Math.min(max, Number(event.target.value)));
		setCreditScore(value);
	};

	const handleChangeUnitCount = (event) => {
		const min = 0;
		const max = 999;

		const value = Math.max(min, Math.min(max, Number(event.target.value)));
		console.log("830 value:", value);
		setUnitCount(value.toFixed(0));
	};

	const handleLoanPurpose = (loanPurposeValue) => {
		// current value
		console.log("1300 loan purpose:", loanPurpose);
		// new value
		console.log("1300 loan purpose value:", loanPurposeValue);

		setLoanPurpose(loanPurposeValue);
	};

	useEffect(() => {
		handleLoanAmount(loanAmount);
		handleMortgageTotal(mortgageTotal);
		handleDrawAmount(drawAmount);
		handleCreditLimit(creditLimit);
	}, [loanPurpose]);

	const handlePrev = () => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});
		setLoanApplicationStage(currentStage - 1);
	};

	const handleNext = async () => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});

		setLoanApplicationStage(currentStage + 1);
	};

	return (
		<Stack direction="column" className={styles.stackContainer}>
			<Stack className={styles.bodyContainer}>
				<ViewLoanInfoCard
					loanNumber={loanNumber}
					borrower={{
						firstName: borrowerFirstName,
						middleName: borrowerMiddleName,
						lastName: borrowerLastName,
					}}
					coBorrower={{
						firstName: coBorrowerFirstName,
						middleName: coBorrowerMiddleName,
						lastName: coBorrowerLastName,
					}}
					propertyInfo={{
						addressLineText: street,
						city,
						state,
						postalCode,
					}}
				/>
				<Stack direction="column" className={styles.cardHeaderContainer}>
					<Heading5 text="Loan Type" fontType="semibold" />
					<Body3 text="Provide details about the loan" />
				</Stack>
				<Stack direction="row" className={styles.formContainer}>
					{/* <Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="select-loan-product-family">
							<Heading6
								text="Loan Product Family"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<SecondarySelect
							id="select-loan-product-family"
							displayEmpty={true}
							value={loanProductFamily}
							handleChange={(event) => setLoanProductFamily(event.target.value)}
							options={productFamilyOptions}
							disabled={disabled}
						/>
					</Stack>

					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="select-loan-product">
							<Heading6
								text="Loan Product"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<SecondarySelect
							id="select-loan-product"
							displayEmpty={true}
							value={loanProduct}
							handleChange={(event) => setLoanProduct(event.target.value)}
							options={loanProductOptions[loanProductFamily]}
							disabled={disabled}
						/>
					</Stack> */}

					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="select-income-type">
							<Heading6
								text="Income Document Type"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<SecondarySelect
							id="select-income-type"
							displayEmpty={true}
							value={documentType}
							handleChange={(event) => setDocumentType(event.target.value)}
							options={incomeDocumentOptions}
							disabled={disabled}
						/>
					</Stack>

					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="masked-interest-rate">
							<Heading6
								text="Requested Interest Rate"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<MaskedInput
							id="masked-interest-rate"
							placeholder="%"
							type="percentage"
							value={requestedInterestRate}
							onInputChange={setRequestedInterestRate}
							disabled={disabled}
						/>
					</Stack>

					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="lock-term">
							<Heading6
								text="Lock Term"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<SecondarySelect
							id="lock-term"
							displayEmpty={true}
							fullWidth={true}
							value={lockTerm}
							handleChange={(event) => console.log(event.target.value)}
							options={lockTermOptions}
							disabled={disabled}
						/>
					</Stack>

					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="mortgage-lien-type">
							<Heading6
								text="Mortgage Lien Type"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<SecondarySelect
							id="mortgage-lien-type"
							displayEmpty={true}
							fullWidth={true}
							value={mortgageLienType}
							handleChange={(event) => setMortgageLienType(event.target.value)}
							options={mortgageLienTypeOptions}
							disabled={disabled}
						/>
					</Stack>
				</Stack>
				<CustomDivider />
				<Stack direction="column" className={styles.cardHeaderContainer}>
					<Heading5 text="Loan Criteria" fontType="semibold" />
					<Body3 text="Provide details about the loan" />
				</Stack>
				<Stack direction="row" className={styles.formContainer}>
					{/* loan purpose */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.inlineContainer}
					>
						<InputLabel htmlFor="select-loan-purpose">
							<Heading6
								text="Loan Purpose"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<SecondarySelect
							id="select-loan-purpose"
							displayEmpty={true}
							fullWidth={true}
							value={loanPurpose}
							handleChange={(event) => handleLoanPurpose(event.target.value)}
							options={loanPurposeOptions}
							disabled={disabled}
						/>
					</Stack>

					{/* escrow */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.inlineContainer}
					>
						<InputLabel htmlFor="select-escrow">
							<Heading6
								text="Escrow Waiver"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<SecondarySelect
							id="select-escrow"
							displayEmpty={true}
							fullWidth={true}
							value={escrow}
							handleChange={(event) => setEscrow(event.target.value)}
							options={YNOptions}
							disabled={disabled}
						/>
					</Stack>

					{/* purchase price */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.inlineContainer}
					>
						<InputLabel htmlFor="masked-purchase-price">
							<Heading6
								text="Purchase Price"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<MaskedInput
							id="masked-purchase-price"
							placeholder="$"
							type="currency"
							fullWidth={true}
							value={purchasePrice}
							onInputChange={handlePurchasePrice}
							disabled={disabled}
						/>
					</Stack>

					{/* reserves */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.inlineContainer}
					>
						<InputLabel htmlFor="select-reserves">
							<Heading6
								text="Reserves (in months)"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<SecondarySelect
							id="select-reserves"
							displayEmpty={true}
							fullWidth={true}
							value={reservesDuration}
							handleChange={(event) => setReservesDuration(event.target.value)}
							options={reservesOptions}
							disabled={disabled}
						/>
					</Stack>

					{/* Estimated Value */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.inlineContainer}
					>
						<InputLabel htmlFor="masked-estimated-value">
							<Heading6
								text="Estimated/Appraised Value"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<MaskedInput
							id="masked-estimated-value"
							placeholder="$"
							type="currency"
							fullWidth={true}
							value={estimatedValue}
							onInputChange={handleEstimatedValue}
							disabled={disabled}
						/>
					</Stack>

					{/* prepayment penalty */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.inlineContainer}
					>
						<InputLabel htmlFor="select-prepayment-penalty">
							<Heading6
								text="Pre-payment Penalty"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<SecondarySelect
							id="select-prepayment-penalty"
							displayEmpty={true}
							fullWidth={true}
							value={paymentPenalty ?? ""}
							handleChange={(event) => setPaymentPenalty(event.target.value)}
							options={prepaymentPenaltyOptions}
							disabled={disabled}
						/>
					</Stack>

					{/* Loan Amount */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.inlineContainer}
					>
						<InputLabel htmlFor="masked-loan-amount">
							<Heading6
								text="Loan Amount"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<MaskedInput
							id="masked-loan-amount"
							placeholder="$"
							type="currency"
							fullWidth={true}
							value={loanAmount}
							onInputChange={handleLoanAmount}
							disabled={disabled}
						/>
					</Stack>

					{/* interest */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.inlineContainer}
					>
						<InputLabel htmlFor="select-interest-only">
							<Heading6
								text="Interest Only"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<SecondarySelect
							id="select-interest-only"
							displayEmpty={true}
							fullWidth={true}
							value={interestOnly}
							handleChange={(event) => setInterestOnly(event.target.value)}
							options={YNOptions}
							disabled={disabled}
						/>
					</Stack>

					{/* Closed Subordinate */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.inlineContainer}
					>
						<InputLabel htmlFor="masked-mortgage-total">
							<Heading6
								text="Closed End Subordinate Mortgage Total"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<MaskedInput
							id="masked-mortgage-total"
							placeholder="$"
							type="currency"
							fullWidth={true}
							value={mortgageTotal}
							onInputChange={setMortgageTotal}
							disabled={disabled}
						/>
					</Stack>

					{/* cashout amount */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.inlineContainer}
					>
						<InputLabel htmlFor="masked-cashout-amount">
							<Heading6
								text="Cashout Amount"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<MaskedInput
							id="masked-cashout-amount"
							placeholder="$"
							type="currency"
							fullWidth={true}
							value={cashOut}
							onInputChange={setCashOut}
							disabled={disabled}
						/>
					</Stack>

					{/* heloc amount */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.inlineContainer}
					>
						<InputLabel htmlFor="masked-heloc-amount">
							<Heading6
								text="HELOC draw amounts"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<MaskedInput
							id="masked-heloc-amount"
							placeholder="$"
							type="currency"
							fullWidth={true}
							value={drawAmount}
							onInputChange={setDrawAmount}
							disabled={disabled}
						/>
					</Stack>

					{/* self employed */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.inlineContainer}
					>
						<InputLabel htmlFor="select-self-employed">
							<Heading6
								text="Self-employed"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<SecondarySelect
							id="select-interest-only"
							displayEmpty={true}
							fullWidth={true}
							value={employmentStatus}
							handleChange={(event) => setEmploymentStatus(event.target.value)}
							options={YNOptions}
							disabled={disabled}
						/>
					</Stack>

					{/* heloc limit */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.inlineContainer}
					>
						<InputLabel htmlFor="masked-heloc-limit">
							<Heading6
								text="HELOC credit limit"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<MaskedInput
							id="masked-heloc-limit"
							placeholder="$"
							type="currency"
							fullWidth={true}
							value={creditLimit}
							onInputChange={setCreditLimit}
							disabled={disabled}
						/>
					</Stack>

					{/* occupancy */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.inlineContainer}
					>
						<InputLabel htmlFor="select-occupancy">
							<Heading6
								text="Occupancy"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<SecondarySelect
							id="select-occupancy"
							displayEmpty={true}
							fullWidth={true}
							value={occupancy}
							handleChange={(event) => setOccupancy(event.target.value)}
							options={occupancyOptions}
							disabled={disabled}
						/>
					</Stack>

					{/* add space to break up the section */}
					<Stack className={styles.emptyContainer}></Stack>

					{/* loan to value */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.inlineContainer}
					>
						<InputLabel htmlFor="masked-loan-value">
							<Heading6
								text="LTV (Loan to Value)"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							id="masked-loan-value"
							placeholder="%"
							fullWidth={true}
							value={`${loanValue}%`}
							handleChange={() => console.log("1050 loan value:", loanValue)}
							disabled={true}
						/>
					</Stack>
					{/* Debt Service Coverage Ratio - DSCR */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.inlineContainer}
					>
						<InputLabel htmlFor="debt-service-coverage-ratio">
							<Heading6
								text="Debt Service Coverage Ratio (DSCR)"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<MaskedInput
							id="debt-service-coverage-ratio"
							placeholder="%"
							type="ratio"
							fullWidth={true}
							value={dscrValue}
							onInputChange={setDscrValue}
							disabled={disabled}
						/>
					</Stack>

					{/* cltv */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.inlineContainer}
					>
						<InputLabel htmlFor="masked-cltv">
							<Heading6
								text="CLTV"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							id="masked-cltv"
							placeholder="%"
							fullWidth={true}
							value={`${cltv}%`}
							handleChange={() => console.log("1050 cltv:", cltv)}
							disabled={true}
						/>
					</Stack>
					{/* DTI - Front End Ratio */}
					{/* <Stack
						direction="column"
						className={styles.inputContainer + " " + styles.inlineContainer}
					>
						<InputLabel htmlFor="dti-front-end-ratio">
							<Heading6
								text="DTI – Front End Ratio"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<MaskedInput
							id="dti-front-end-ratio"
							placeholder="0.000"
							type="ratio"
							fullWidth={true}
							value={dtiFrontendRatio}
							onInputChange={setDtiFrontendRatio}
							disabled={disabled}
						/>
					</Stack> */}

					{/* empty stack to move hcltv below cltv */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.inlineContainer}
					></Stack>

					{/* hcltv */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.inlineContainer}
					>
						<InputLabel htmlFor="masked-hcltv">
							<Heading6
								text="HCLTV"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							id="masked-hcltv"
							placeholder="%"
							fullWidth={true}
							value={`${hcltv}%`}
							handleChange={() => console.log("1050 hcltv:", hcltv)}
							disabled={true}
						/>
					</Stack>
					{/* DTI - Back End Ratio */}
					{/* <Stack
						direction="column"
						className={styles.inputContainer + " " + styles.inlineContainer}
					>
						<InputLabel htmlFor="dti-back-end-ratio">
							<Heading6
								text="DTI – Back End Ratio"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<MaskedInput
							id="dti-back-end-ratio"
							placeholder="0.000"
							type="ratio"
							fullWidth={true}
							value={dtiBackendRatio}
							onInputChange={setDtiBackendRatio}
							disabled={disabled}
						/>
					</Stack> */}
				</Stack>
				<CustomDivider />
				<Stack direction="column" className={styles.cardHeaderContainer}>
					<Heading5 text="Credit Profile" fontType="semibold" />
					<Body3 text="Provide details about the loan" />
				</Stack>

				<Stack direction="column" className={styles.formContainer}>
					{/* credit score */}
					<Stack
						direction="row"
						className={styles.formContainer + " " + styles.formStartContainer}
					>
						<Stack direction="column" className={styles.inputContainer}>
							<InputLabel htmlFor="credit-score">
								<Heading6
									text="Credit Score (Qualifying)"
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>

							<OutlinedTextInput
								id="credit-score"
								placeholder="Credit Score"
								type="number"
								min={0}
								max={999}
								maxLength={3}
								value={creditScore}
								handleChange={handleChangeCreditScore}
								disabled={disabled}
							/>
						</Stack>
					</Stack>

					<Stack
						direction="row"
						className={styles.formContainer + " " + styles.formStartContainer}
					>
						{/* housing event type */}
						<Stack direction="column" className={styles.inputContainer}>
							<InputLabel htmlFor="select-housing-type">
								<Heading6
									text="Housing Event Type"
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>

							<SecondarySelect
								id="select-housing-type"
								displayEmpty={true}
								value={housingType}
								handleChange={(event) => setHousingType(event.target.value)}
								options={housingTypeOptions}
								disabled={disabled}
							/>
						</Stack>

						{/* housing event seasoning */}
						<Stack direction="column" className={styles.inputContainer}>
							<InputLabel htmlFor="select-housing-seasoning">
								<Heading6
									text="Housing Event Seasoning"
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>

							<SecondarySelect
								id="select-housing-seasoning"
								displayEmpty={true}
								value={housingSeasoning}
								handleChange={(event) =>
									setHousingSeasoning(event.target.value)
								}
								options={housingSeasoningOptions}
								disabled={disabled}
							/>
						</Stack>
					</Stack>

					<Stack
						direction="row"
						className={styles.formContainer + " " + styles.formStartContainer}
					>
						{/* bankruptcy Type */}
						<Stack direction="column" className={styles.inputContainer}>
							<InputLabel htmlFor="select-bankruptcy-type">
								<Heading6
									text="Bankruptcy Type"
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>

							<SecondarySelect
								id="select-bankruptcy-type"
								displayEmpty={true}
								value={bankruptcyType}
								handleChange={(event) => setBankruptcyType(event.target.value)}
								options={bankruptcyTypeOptions}
								disabled={disabled}
							/>
						</Stack>

						{/* bankruptcy outcome */}
						<Stack direction="column" className={styles.inputContainer}>
							<InputLabel htmlFor="select-bankruptcy-outcome">
								<Heading6
									text="Bankruptcy Outcome"
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>

							<SecondarySelect
								id="select-bankruptcy-outcome"
								displayEmpty={true}
								value={bankruptcyOutcome}
								handleChange={(event) =>
									setBankruptcyOutcome(event.target.value)
								}
								options={bankruptcyOutcomeOptions}
								disabled={disabled}
							/>
						</Stack>

						{/* Bankruptcy Seasoning */}
						<Stack direction="column" className={styles.inputContainer}>
							<InputLabel htmlFor="select-bankruptcy-seasoning">
								<Heading6
									text="Bankruptcy Seasoning"
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>

							<SecondarySelect
								id="select-bankruptcy-seasoning"
								displayEmpty={true}
								value={bankruptcySeasoning}
								handleChange={(event) =>
									setBankruptcySeasoning(event.target.value)
								}
								options={bankruptcySeasoningOptions}
								disabled={disabled}
							/>
						</Stack>
					</Stack>
				</Stack>
				<CustomDivider />

				<Stack direction="row" className={styles.mortgageHeaderContainer}>
					<Stack direction="column" gap={"4px"}>
						<Heading5 text="Mortgage Lates" fontType="semibold" />
						<Body3 text="Provide details about the loan" />
					</Stack>
					{/* mortgage lates */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="select-mortgage-lates">
							<Heading6
								text="Mortgage Lates"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<SecondarySelect
							id="select-mortgage-lates"
							displayEmpty={true}
							value={mortgageLates}
							handleChange={(event) => setMortgageLates(event.target.value)}
							options={YNOptions}
							disabled={disabled}
						/>
					</Stack>
				</Stack>

				<Stack direction="column" className={styles.formContainer}>
					{mortgageLates === "Y" && (
						<>
							<Stack direction="row" className={styles.formContainer}>
								{/* mtg Lates x30 (0-6 Mos) */}
								<Stack direction="column" className={styles.inputContainer}>
									<InputLabel htmlFor="select-mtg-lates-30-6">
										<Heading6
											text="Mtg Lates x30 (0-6 Mos)"
											fontType="semibold"
											extraClass={styles.inputHeader}
										/>
									</InputLabel>

									<OutlinedTextInput
										id="select-mtg-lates-30-6"
										type="number"
										min={0}
										max={120}
										maxLength={2}
										extraClass={styles.mortgageInput}
										value={mortgageLates1}
										handleChange={(event) =>
											setMortgageLates1(event.target.value)
										}
										disabled={disabled}
									/>
								</Stack>
							</Stack>

							<Stack direction="row" className={styles.formContainer}>
								{/* mtg Lates x30 (12 Mos) */}
								<Stack direction="column" className={styles.inputContainer}>
									<InputLabel htmlFor="select-mtg-lates-30-12">
										<Heading6
											text="Mtg Lates x30 (12 Mos)"
											fontType="semibold"
											extraClass={styles.inputHeader}
										/>
									</InputLabel>

									<OutlinedTextInput
										id="select-mtg-lates-30-12"
										type="number"
										min={0}
										max={120}
										maxLength={2}
										extraClass={styles.mortgageInput}
										value={mortgageLates2}
										handleChange={(event) =>
											setMortgageLates2(event.target.value)
										}
										disabled={disabled}
									/>
								</Stack>

								{/* mtg Lates x60 (12 Mos) */}
								<Stack direction="column" className={styles.inputContainer}>
									<InputLabel htmlFor="select-mtg-lates-60-12">
										<Heading6
											text="Mtg Lates x60 (12 Mos)"
											fontType="semibold"
											extraClass={styles.inputHeader}
										/>
									</InputLabel>

									<OutlinedTextInput
										id="select-mtg-lates-60-12"
										type="number"
										min={0}
										max={120}
										maxLength={2}
										extraClass={styles.mortgageInput}
										value={mortgageLates3}
										handleChange={(event) =>
											setMortgageLates3(event.target.value)
										}
										disabled={disabled}
									/>
								</Stack>

								{/* mtg Lates x90 (12 Mos) */}
								<Stack direction="column" className={styles.inputContainer}>
									<InputLabel htmlFor="select-mtg-lates-90-12">
										<Heading6
											text="Mtg Lates x90 (12 Mos)"
											fontType="semibold"
											extraClass={styles.inputHeader}
										/>
									</InputLabel>

									<OutlinedTextInput
										id="select-mtg-lates-90-12"
										type="number"
										min={0}
										max={120}
										maxLength={2}
										extraClass={styles.mortgageInput}
										value={mortgageLates4}
										handleChange={(event) =>
											setMortgageLates4(event.target.value)
										}
										disabled={disabled}
									/>
								</Stack>

								{/* mtg Lates x120 (12 Mos) */}
								<Stack direction="column" className={styles.inputContainer}>
									<InputLabel htmlFor="select-mtg-lates-120-12">
										<Heading6
											text="Mtg Lates x120 (12 Mos)"
											fontType="semibold"
											extraClass={styles.inputHeader}
										/>
									</InputLabel>

									<OutlinedTextInput
										id="select-mtg-lates-120-12"
										type="number"
										min={0}
										max={120}
										maxLength={2}
										extraClass={styles.mortgageInput}
										value={mortgageLates5}
										handleChange={(event) =>
											setMortgageLates5(event.target.value)
										}
										disabled={disabled}
									/>
								</Stack>
							</Stack>

							<Stack direction="row" className={styles.formContainer}>
								{/* mtg Lates x30 (13-24 Mos) */}
								<Stack direction="column" className={styles.inputContainer}>
									<InputLabel htmlFor="select-mtg-lates-30-24">
										<Heading6
											text="Mtg Lates x30 (13-24 Mos)"
											fontType="semibold"
											extraClass={styles.inputHeader}
										/>
									</InputLabel>

									<OutlinedTextInput
										id="select-mtg-lates-30-24"
										type="number"
										min={0}
										max={120}
										maxLength={2}
										extraClass={styles.mortgageInput}
										value={mortgageLates6}
										handleChange={(event) =>
											setMortgageLates6(event.target.value)
										}
										disabled={disabled}
									/>
								</Stack>

								{/* mtg Lates x60 (13-24 Mos) */}
								<Stack direction="column" className={styles.inputContainer}>
									<InputLabel htmlFor="select-mtg-lates-60-24">
										<Heading6
											text="Mtg Lates x60 (13-24 Mos)"
											fontType="semibold"
											extraClass={styles.inputHeader}
										/>
									</InputLabel>

									<OutlinedTextInput
										id="select-mtg-lates-60-24"
										type="number"
										min={0}
										max={120}
										maxLength={2}
										extraClass={styles.mortgageInput}
										value={mortgageLates7}
										handleChange={(event) =>
											setMortgageLates7(event.target.value)
										}
										disabled={disabled}
									/>
								</Stack>

								{/* mtg Lates x90 (13-24 Mos) */}
								<Stack direction="column" className={styles.inputContainer}>
									<InputLabel htmlFor="select-mtg-lates-90-24">
										<Heading6
											text="Mtg Lates x90 (13-24 Mos)"
											fontType="semibold"
											extraClass={styles.inputHeader}
										/>
									</InputLabel>

									<OutlinedTextInput
										id="select-mtg-lates-90-24"
										type="number"
										min={0}
										max={120}
										maxLength={2}
										extraClass={styles.mortgageInput}
										value={mortgageLates8}
										handleChange={(event) =>
											setMortgageLates8(event.target.value)
										}
										disabled={disabled}
									/>
								</Stack>

								{/* mtg Lates x120 (13-24 Mos) */}
								<Stack direction="column" className={styles.inputContainer}>
									<InputLabel htmlFor="select-mtg-lates-120-24">
										<Heading6
											text="Mtg Lates x120 (13-24 Mos)"
											fontType="semibold"
											extraClass={styles.inputHeader}
										/>
									</InputLabel>

									<OutlinedTextInput
										id="select-mtg-lates-120-24"
										type="number"
										min={0}
										max={120}
										maxLength={2}
										extraClass={styles.mortgageInput}
										value={mortgageLates9}
										handleChange={(event) =>
											setMortgageLates9(event.target.value)
										}
										disabled={disabled}
									/>
								</Stack>
							</Stack>
						</>
					)}
				</Stack>
				<CustomDivider />
				<Stack direction="column" className={styles.cardHeaderContainer}>
					<Heading5 text="Property Information" fontType="semibold" />
					<Body3 text="Provide details about the loan" />
				</Stack>
				{/* Property Type */}
				<Stack direction="row" className={styles.formContainer}>
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="select-property-type">
							<Heading6
								text="Property Type"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<SecondarySelect
							id="select-property-type"
							displayEmpty={true}
							value={propertyType}
							handleChange={(event) => setPropertyType(event.target.value)}
							options={propertyInfoTypeOptions}
							disabled={disabled}
						/>
					</Stack>

					{/* Property Design */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="select-property-design">
							<Heading6
								text="Property Design"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<SecondarySelect
							id="select-property-design"
							displayEmpty={true}
							value={propertyDesign}
							handleChange={(event) => setPropertyDesign(event.target.value)}
							options={
								propertyType === "Warrantable Condo" ||
								propertyType === "Non-Warrantable Condo" ||
								propertyType === "Condotel"
									? propertyDesignCondoOptions
									: propertyDesignSingleOptions
							}
							disabled={disabled || propertyType === ""}
						/>
					</Stack>

					{/* number of units */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="select-unit-count">
							<Heading6
								text="Number of units"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<SecondarySelect
							id="select-unit-count"
							displayEmpty={true}
							value={unitCount}
							handleChange={(event) => setUnitCount(event.target.value)}
							options={
								propertyType?.includes("5-8")
									? unitFiveOptions
									: propertyType?.includes("2-4")
									? unitTwoOptions
									: unitSingleOptions
							}
							disabled={disabled || propertyType === ""}
						/>
					</Stack>

					{/* occupancy Rate */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="input-occupancy-rate">
							<Heading6
								text="Occupancy Rate"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							id="input-occupancy-rate"
							placeholder="%"
							type="number"
							min={0}
							value={occupancyRate}
							handleChange={(event) => setOccupancy(event.target.value)}
							disabled={disabled}
						/>
					</Stack>

					{/* state */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="select-state">
							<Heading6
								text="State"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<SecondarySelect
							id="select-state"
							displayEmpty={true}
							value={state}
							handleChange={(event) => setState(event.target.value)}
							options={stateOptions}
							disabled={disabled}
						/>
					</Stack>

					{/* county */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="select-county">
							<Heading6
								text="County"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<SecondarySelect
							id="select-county"
							displayEmpty={true}
							value={county}
							handleChange={(event) => setCounty(event.target.value)}
							options={countyListData || []}
							disabled={disabled}
						/>
					</Stack>

					{/* zipcode */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="input-zipcode">
							<Heading6
								text="Zipcode"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							id="input-zipcode"
							placeholder="Zipcode"
							type="number"
							min={0}
							value={zipcode}
							handleChange={(event) => setZipcode(event.target.value)}
							disabled={disabled}
						/>
					</Stack>

					{/* rental income */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="outlined-input-rental-income">
							<Heading6
								text="Expected Gross Rental Income"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<MaskedInput
							id="outlined-input-rental-income"
							type="currency"
							placeholder="Rent ($ / mo.)"
							fullWidth={true}
							value={grossRent?.toString() || ""}
							onInputChange={setGrossRent}
							disabled={disabled}
						/>
					</Stack>
				</Stack>

				<CustomDivider />

				<Stack direction="column" className={styles.cardHeaderContainer}>
					<Heading5 text="Loan Terms" fontType="semibold" />
					<Body3 text="Provide details about the loan" />
				</Stack>

				<Stack direction="row" className={styles.formContainer}>
					{/* Amortization */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="select-amortization">
							<Heading6
								text="Amortization"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<SecondarySelect
							id="select-amortization"
							displayEmpty={true}
							value={amortization}
							handleChange={(event) => setAmortization(event.target.value)}
							options={amortizationOptions}
							disabled={disabled}
						/>
					</Stack>

					{/* Term of Loan */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="select-loan-term">
							<Heading6
								text="Term of Loan (Years)"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<SecondarySelect
							id="select-loan-term"
							displayEmpty={true}
							value={loanTerm}
							handleChange={(event) => setLoanTerm(event.target.value)}
							options={loanTermOptions}
							disabled={disabled}
						/>
					</Stack>

					{/* Arm Fixed Term */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="select-arm-fixed-term">
							<Heading6
								text="Arm Fixed Term"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<SecondarySelect
							id="select-arm-fixed-term"
							displayEmpty={true}
							value={armFixedTerm}
							handleChange={(event) => setArmFixedTerm(event.target.value)}
							options={armFixedTermOptions}
							disabled={disabled}
						/>
					</Stack>

					{/* interest only term */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="input-interest-only-term">
							<Heading6
								text="Interest Only Term"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<SecondarySelect
							id="input-interest-only-term"
							displayEmpty={true}
							value={interestOnlyTerm}
							handleChange={(event) => setInterestOnlyTerm(event.target.value)}
							options={interestTermOptions}
							disabled={disabled}
						/>
					</Stack>

					{/* Borrower Paid MI */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="select-borrower-mi">
							<Heading6
								text="Borrower Paid MI"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<SecondarySelect
							id="select-borrower-mi"
							displayEmpty={true}
							value={borrowerMI}
							handleChange={(event) => setBorrowerMI(event.target.value)}
							options={YNOptions}
							disabled={disabled}
						/>
					</Stack>
				</Stack>
			</Stack>

			{/* footer buttons */}
			<Stack direction="row" className={styles.footerContainer}>
				<SecondaryButton
					text="Previous"
					startIcon={<ArrowBackOutlined className={styles.prevIcon} />}
					onClick={handlePrev}
				/>
				<PrimaryButton
					text="Continue"
					endIcon={<ArrowForwardOutlined className={styles.nextIcon} />}
					onClick={handleNext}
				/>
			</Stack>
		</Stack>
	);
};

export default ViewLoanTerms;
