import React from "react";
import { useNavigate } from "react-router-dom";

// mui components
import { TableCell, TableRow } from "@mui/material";

// custom components
import Body2 from "../../typography/body-02";

const RateLockRow = ({ row }) => {
	const navigate = useNavigate();

	const loanStatus =
		row?.fields["Loan.LoanFolder"] === "Portal - Not Submitted"
			? "Started - Not Submitted"
			: row?.fields["Fields.Log.MS.CurrentMilestone"] === "Started"
			? "Loan Submitted"
			: row?.fields["Fields.Log.MS.CurrentMilestone"] === "Loan Submitted"
			? "Loan Submitted"
			: row?.fields["Fields.Log.MS.CurrentMilestone"] === "Review Submission"
			? "Review Submission"
			: row?.fields["Fields.Log.MS.CurrentMilestone"] ===
			  "Incomplete Credit Pkg"
			? "Incomplete Credit Pkg"
			: row?.fields["Fields.Log.MS.CurrentMilestone"] === "Recd Cred Pkg"
			? "Recd Cred Pkg"
			: row?.fields["Fields.Log.MS.CurrentMilestone"] === "Review Cred Pkg"
			? "Recd Cred Pkg"
			: row?.fields["Fields.Log.MS.CurrentMilestone"] === "File Setup"
			? "File Setup"
			: row?.fields["Fields.Log.MS.CurrentMilestone"] === "Submit to UW"
			? "In Underwriting"
			: row?.fields["Fields.Log.MS.CurrentMilestone"] === "In UWing Review"
			? "In Underwriting"
			: row?.fields["Fields.Log.MS.CurrentMilestone"] === "Suspended"
			? "Suspended"
			: row?.fields["Fields.Log.MS.CurrentMilestone"] === "Cond'l Approval"
			? "Conditional Approval"
			: row?.fields["Fields.Log.MS.CurrentMilestone"] === "Recd Conditions"
			? "Recd Conditions"
			: row?.fields["Fields.Log.MS.CurrentMilestone"] === "UW Cond Review"
			? "UW Cond Review"
			: row?.fields["Fields.Log.MS.CurrentMilestone"] === "QC Requested"
			? "In Underwriting"
			: row?.fields["Fields.Log.MS.CurrentMilestone"] === "QC Complete"
			? "In Underwriting"
			: row?.fields["Fields.Log.MS.CurrentMilestone"] === "CTC"
			? "Clear to Close"
			: row?.fields["Fields.Log.MS.CurrentMilestone"] === "Closing Prep"
			? "Closing Docs"
			: row?.fields["Fields.Log.MS.CurrentMilestone"] === "Closing"
			? "Closing"
			: row?.fields["Fields.Log.MS.CurrentMilestone"] === "Funding Prep"
			? "Wire Sent"
			: row?.fields["Fields.Log.MS.CurrentMilestone"] === "Funding" && "Funded";

	return (
		<TableRow
			hover
			tabIndex={-1}
			key={row.id}
			onClick={() => navigate(`/loan-summary/${row?.loanGuid || row?.loanId}`)}
		>
			{/* loan number */}
			<TableCell key={row.id}>
				<Body2
					text={row?.fields["Loan.LoanNumber"]}
					error={row?.fields["Loan.LoanFolder"] === "Portal - Not Submitted"}
					isLink={true}
				/>
			</TableCell>

			{/* expiration date */}
			<TableCell key={row.id}>
				<Body2
					text={
						row?.fields["Fields.762"] !== ""
							? row?.fields["Fields.762"]?.split(" ")[0]
							: ""
					}
				/>
			</TableCell>

			{/* days to expiry */}
			<TableCell key={row.id}>
				<Body2
					text={
						row?.fields["Fields.432"] !== ""
							? row?.fields["Fields.432"]?.split(".")[0]
							: ""
					}
				/>
			</TableCell>

			{/* loan program */}
			<TableCell key={row.id}>
				<Body2 text={row?.fields["Fields.1401"]} />
			</TableCell>

			{/* borrower name */}
			<TableCell key={row.id}>
				<Body2 text={row?.fields["Loan.BorrowerName"]} />
			</TableCell>

			{/* loan folder */}
			<TableCell key={row.id}>
				<Body2 text={row?.fields["Loan.LoanFolder"]} />
			</TableCell>

			{/* loan status */}
			<TableCell key={row.id}>
				<Body2 text={loanStatus || ""} />
			</TableCell>
		</TableRow>
	);
};

export default RateLockRow;
