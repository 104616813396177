import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// redux slice
import { selectUserDetails } from "../../features/auth/authSlice";
import { selectImpersonatedUser } from "../../features/admin/impersonated-user/impersonatedUserSlice";

// mui components
import {
	Box,
	CssBaseline,
	AppBar,
	Stack,
	Toolbar,
	IconButton,
} from "@mui/material";

// mui icons
import MenuIcon from "@mui/icons-material/Menu";
import { ArrowForwardIosOutlined } from "@mui/icons-material";

// custom components
import CustomDrawer from "../custom-drawer";
import Heading3 from "../typography/heading-03";
import Heading4 from "../typography/heading-04";

// custom modal
import ConfirmationModal from "../modal/confirmation-modal";

// custom styles
import styles from "./index.module.scss";

const drawerWidth = 240;

const CustomLayout = ({ breadcrumb, children, disabledOverflow }) => {
	const navigate = useNavigate();
	const [mobileOpen, setMobileOpen] = useState(false);

	const [confirmModalText, setConfirmModalText] = useState("");
	const [confirmModalVisible, setConfirmModalVisible] = useState(false);
	const handleOpenConfirmModal = () => {
		setConfirmModalVisible(true);
	};
	const handleCloseConfirmModal = () => {
		setConfirmModalVisible(false);
	};

	const handleSubmitConfirmModal = () => {
		navigate("/admin");
	};

	const userDetails = useSelector(selectUserDetails);
	const impersonatedUser = useSelector(selectImpersonatedUser);

	console.log("45 user details:", userDetails);
	console.log("45 impersonated user:", impersonatedUser);

	const isAdmin = userDetails?.user_roles?.includes("admin");
	const isImpersonatedUser =
		impersonatedUser !== undefined &&
		impersonatedUser !== null &&
		impersonatedUser !== "";

	console.log("55 is admin:", isAdmin);
	console.log("55 is impersonated user:", isImpersonatedUser);

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	const handleDrawerItemClick = (title, url) => {
		handleDrawerToggle();
		console.log("75 title:", title);
		console.log("75 url:", url);

		// TODO remove if condition when price my loan is not external link
		if (title === "Price my Loan") {
			window.open("https://www.lfclift.com/", "_blank");
		} else if (title === "Order Appraisal") {
			window.open("https://lender.reggora.com/auth/login", "_blank");
		} else if (title === "Schedule Training") {
			window.open(
				"https://appt.link/train-with-logan-finance/submission-portal-training",
				"_blank"
			);
		} else {
			if (url === "/new-loan" && isAdmin) {
				if (isImpersonatedUser) {
					navigate(url);
				} else {
					setConfirmModalText("Sign in as a broker to gain access.");
					handleOpenConfirmModal();
				}
			} else {
				navigate(url);
			}
		}
	};

	return (
		<Box className={styles.rootContainer}>
			<CssBaseline />
			<CustomDrawer
				drawerWidth={drawerWidth}
				handleDrawerItemClick={handleDrawerItemClick}
				mobileOpen={mobileOpen}
				handleDrawerToggle={handleDrawerToggle}
			/>

			{/* content right */}
			<Box
				component="main"
				sx={{
					flexGrow: 1,
					p: 0,
					width: { sm: `calc(100% - ${drawerWidth}px)` },
					height: disabledOverflow && "100vh",
				}}
				className={styles.mainContainer}
			>
				<AppBar position="relative" className={styles.appBar}>
					<Toolbar>
						<IconButton
							color="inherit"
							aria-label="open drawer"
							edge="start"
							onClick={handleDrawerToggle}
							sx={{ mr: 2, display: { sm: "none" } }}
							className={styles.menuIcon}
						>
							<MenuIcon />
						</IconButton>

						<Stack
							direction="row"
							alignItems="center"
							justifyContent="space-between"
							className={styles.toolbarStack}
						>
							{/* breadcrumbs */}
							{breadcrumb?.length > 0 && (
								<Stack direction="row" className={styles.breadcrumbContainer}>
									{breadcrumb?.map((item, index) => (
										<Stack direction="row" alignItems="center" key={index}>
											<Heading3
												key={index}
												text={item.label}
												fontType="semibold"
												// do not click last item
												handleClick={() =>
													breadcrumb?.length !== index + 1 &&
													navigate(item?.path)
												}
												extraClass={
													breadcrumb?.length !== index + 1
														? styles.breadcrumb
														: ""
												}
											/>

											{breadcrumb?.length !== index + 1 && (
												<Box className={styles.breadcrumbIconContainer}>
													<ArrowForwardIosOutlined
														className={styles.breadcrumbIcon}
													/>
												</Box>
											)}
										</Stack>
									))}
								</Stack>
							)}

							{/* user role */}
							<Heading4
								text={
									userDetails?.user_roles?.includes("manager") ||
									userDetails?.user_roles?.includes("AM")
										? "Account Manager"
										: userDetails?.user_roles?.includes("executive") ||
										  userDetails?.user_roles?.includes("AE")
										? "Account Executive"
										: userDetails?.user_roles?.includes("admin")
										? impersonatedUser !== null &&
										  impersonatedUser !== undefined &&
										  impersonatedUser !== ""
											? `Admin logged in as ${impersonatedUser}`
											: "Admin"
										: userDetails?.access_level?.includes("admin")
										? "Broker Admin"
										: "Broker"
								}
								extraClass={styles.userRoleText}
							/>
						</Stack>
					</Toolbar>
				</AppBar>

				{children}
			</Box>
			{/* modal */}
			<ConfirmationModal
				open={confirmModalVisible}
				text={confirmModalText}
				confirmText="Sign In"
				handleConfirm={handleSubmitConfirmModal}
				closeText="Close"
				handleClose={handleCloseConfirmModal}
			/>
		</Box>
	);
};

export default CustomLayout;
