import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

// mui components
import {
	Box,
	Checkbox,
	FormControlLabel,
	FormGroup,
	Typography,
} from "@mui/material";

// custom components
import CustomLoader from "../../components/loader";
import LoginSkeleton from "../../assets/icons/login-skeleton";

// custom icons
import Logo from "../../assets/icons/logo";
import DotsBg from "../../assets/icons/dots-bg";

// api slice
import { useLoginMutation } from "../../features/auth/authApiSlice";

// reducer slice
import { setCredentials } from "../../features/auth/authSlice";

// custom styles
import styles from "./index.module.scss";

const Login = () => {
	const [login, { isLoading }] = useLoginMutation();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	const [error, setError] = useState("");

	const handleLoginClick = async (e) => {
		e.preventDefault();

		if (email === "" || password === "") {
			setError("Email/Password can not be empty.");
			return;
		}

		try {
			const userData = await login({
				username: email,
				password: password,
			}).unwrap();

			if (
				userData?.login_enabled ||
				userData?.user_roles?.includes("admin") ||
				userData?.user_roles?.includes("manager") ||
				userData?.user_roles?.includes("executive") ||
				userData?.user_roles?.includes("AM") ||
				userData?.user_roles?.includes("AE")
			) {
				console.log("65 login user data:", userData);
				dispatch(setCredentials({ ...userData, username: email }));

				navigate("/home");
			} else {
				setError("Login has been disabled. Please contact your admin.");
			}
		} catch (err) {
			setError(err.data.detail);
		}
	};

	return (
		<Box className={styles.pageContainer}>
			<Box className={styles.bodyContainer}>
				<Box
					className={styles.bodyLeftContainer}
					sx={{
						display: { xs: "none", md: "flex" },
					}}
				>
					<Box className={styles.imageContainer}>
						<Logo extraStyles={{ width: "100%", height: "100%" }} />
					</Box>

					<Box className={styles.skeletonContainer}>
						<LoginSkeleton
							extraStyles={{
								position: "relative",
								transform: "translate(31px, 0px)",
								width: "300px",
								height: "250px",
							}}
						/>
					</Box>
				</Box>

				<Box className={styles.bodyRightContainer}>
					<Box
						className={styles.rightTopContainer}
						sx={{
							display: { xs: "none", md: "flex" },
						}}
					>
						<DotsBg extraStyles={{ width: "85px", height: "80px" }} />
					</Box>

					<form onSubmit={handleLoginClick} className={styles.formContainer}>
						<Box className={styles.formBodyContainer}>
							<Typography
								component="p"
								sx={{
									fontSize: "1.75rem",
									lineHeight: "2.25rem",
									fontWeight: 600,
									color: "#000",
								}}
								className={styles.loginText}
							>
								Log
								<Typography
									component="span"
									sx={{
										fontSize: "1.75rem",
										lineHeight: "2.25rem",
										fontWeight: 600,
										color: "#000",
										borderBottom: "4px solid #C0CEBA",
									}}
								>
									in
								</Typography>
							</Typography>

							<Box className={styles.formFieldsContainer}>
								<Box className={styles.inputFieldContainer}>
									<label htmlFor="email" className={styles.inputLabel}>
										Email
									</label>
									<input
										type="email"
										className={styles.inputField}
										id="email"
										aria-describedby="emailHelp"
										placeholder="Enter your email"
										value={email}
										onChange={(event) => {
											setEmail(event.target.value);
											setError("");
										}}
									/>
								</Box>

								<Box className={styles.inputFieldContainer}>
									<label htmlFor="password" className={styles.inputLabel}>
										Password
									</label>

									<Box className={styles.passwordContainer}>
										<input
											type="password"
											className={styles.passwordField}
											id="password"
											placeholder="Enter your password"
											value={password}
											onChange={(event) => {
												setPassword(event.target.value);
												setError("");
											}}
										/>

										<Link to="/reset-password" className={styles.resetText}>
											Reset password
										</Link>
									</Box>
								</Box>

								<Box className={styles.rememberContainer}>
									<FormGroup>
										<FormControlLabel
											control={
												<Checkbox
													sx={{
														color: "rgba(156, 177, 147, 0.8)",
														"&.Mui-checked": {
															color: "rgba(156, 177, 147, 0.8)",
														},
													}}
												/>
											}
											label={
												<Typography className={styles.rememberText}>
													Remember Password
												</Typography>
											}
										/>
									</FormGroup>
								</Box>
							</Box>

							{error !== "" && <p style={{ color: "#f00" }}>{error}</p>}
							<button
								type="submit"
								onClick={handleLoginClick}
								className={styles.submitBtn}
							>
								{isLoading ? (
									<CustomLoader sx={{ color: "#fff" }} size={20} />
								) : (
									"Login"
								)}
							</button>
						</Box>
					</form>
				</Box>
			</Box>
		</Box>
	);
};

export default Login;
