import { apiSlice } from "../../app/api/apiSlice";

export const getEligibleProductsSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getEligibleProducts: builder.mutation({
			query: (credentials) => ({
				url: `api/v1/loan/get_ob_eligible_products?encompass_loan_guid=${credentials?.loan_id}&encompass_loan_number=${credentials?.loan_number}&propertyType=${credentials?.property_type}`,
				method: "GET",
			}),
		}),
	}),
});

export const { useGetEligibleProductsMutation } = getEligibleProductsSlice;
